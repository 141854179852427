// import React, { useState, useEffect } from "react";
// import { useDispatch } from "react-redux";
// import { requestOtp, verifyOtp } from "../../features/products/otpActions";
// import "./FormPage.css";
// import Navbar from "../navbar/Navbar";

// const FormPage = () => {
//   const [isFormDisabled, setIsFormDisabled] = useState(false); // To disable form fields
//   const [showOtpPopup, setShowOtpPopup] = useState(false);
//   const [otp, setOtp] = useState("");

//   const [formData, setFormData] = useState({
//     name: "",
//     contact: "",
//     email: "",
//     school_name: "",
//     class_field: "",
//     gender: "",
//     address: "",
//     state: "",
//     district: "",
//     zipcode: "",
//   });

//   const [errors, setErrors] = useState({});
//   const dispatch = useDispatch();

//   // Fetch participant details on load
//   useEffect(() => {
//     const fetchParticipantDetails = async () => {
//       try {
//         const token = localStorage.getItem("token"); // Replace with your token logic
//         if (!token) {
//           console.log("No token found");
//           return;
//         }

//         const response = await fetch(`${BASE_URL}ispl/details/", {
//           method: "GET",
//           headers: {
//             Authorization: token,
//             "Content-Type": "application/json",
//           },
//         });

//         if (!response.ok) {
//           throw new Error("Failed to fetch participant details");
//         }

//         const data = await response.json();
//         console.log("Participant data:", data);

//         setFormData(data); // Prefill form with data
//         setIsFormDisabled(true); // Disable the form
//       } catch (error) {
//         console.error("Error fetching participant details:", error.message || error);
//       }
//     };

//     fetchParticipantDetails();
//   }, []);

//   // Validation function
//   const validateForm = () => {
//     const formErrors = {};
//     if (!formData.name) formErrors.name = "Name is required";
//     if (!formData.contact || formData.contact.length !== 10) formErrors.contact = "Contact must be 10 digits";
//     if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) formErrors.email = "Valid email is required";
//     if (!formData.school_name) formErrors.school_name = "School name is required";
//     if (!formData.class_field) formErrors.class_field = "Class is required";
//     if (!formData.gender) formErrors.gender = "Gender is required";
//     if (!formData.address) formErrors.address = "Address is required";
//     if (!formData.state) formErrors.state = "State is required";
//     if (!formData.district) formErrors.district = "District is required";
//     if (!formData.zipcode || !/^\d{6}$/.test(formData.zipcode)) formErrors.zipcode = "Valid ZIP Code is required";
//     return formErrors;
//   };

//   // Handle form submission
//   const handleSubmit = (e) => {
//     e.preventDefault();
//     const formErrors = validateForm();
//     setErrors(formErrors);

//     if (Object.keys(formErrors).length === 0) {
//       dispatch(requestOtp(formData.contact)); // Dispatch action to request OTP
//       setShowOtpPopup(true);
//     }
//   };

//   const handleOtpSubmit = () => {
//     if (otp.length === 6) {
//       dispatch(verifyOtp({ ...formData, otp })); // Dispatch full form data
//       setShowOtpPopup(false);
//       setOtp("");
//     } else {
//       setErrors((prevErrors) => ({ ...prevErrors, otp: "OTP must be 6 digits" }));
//     }
//   };

//   return (
//     <>
//       <Navbar />
//       <div className="form-container">
//         <form className="form" onSubmit={handleSubmit}>
//           <p className="form-note">Fields marked with * are compulsory</p>

//           {Object.keys(formData).map((key) => (
//             <div className="form-row" key={key}>
//               <label>{key.replace("_", " ").toUpperCase()}*</label>
//               <input
//                 type="text"
//                 placeholder={key}
//                 value={formData[key]}
//                 onChange={(e) => setFormData({ ...formData, [key]: e.target.value })}
//                 disabled={isFormDisabled}
//                 required
//               />
//               {errors[key] && <span className="error">{errors[key]}</span>}
//             </div>
//           ))}

//           <button type="submit" className="submit-btn" disabled={isFormDisabled}>
//             Submit
//           </button>
//         </form>
//       </div>

//       {showOtpPopup && (
//         <div className="otp-popup">
//           <div className="otp-popup-content">
//             <h3>Enter OTP</h3>
//             <input
//               type="text"
//               placeholder="Enter OTP"
//               value={otp}
//               onChange={(e) => setOtp(e.target.value)}
//               maxLength="6"
//               required
//             />
//             {errors.otp && <span className="error">{errors.otp}</span>}
//             <div className="otp-popup-buttons">
//               <button onClick={handleOtpSubmit} className="submit-btn">
//                 Submit
//               </button>
//               <button onClick={() => setShowOtpPopup(false)} className="close-btn">
//                 Cancel
//               </button>
//             </div>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default FormPage;



// import React, { useState, useEffect } from "react";
// import { useDispatch } from "react-redux";
// import { requestOtp, verifyOtp } from "../../features/products/otpActions";
// import "./FormPage.css";
// import Navbar from "../navbar/Navbar";

// const FormPage = () => {
//   const [isFormDisabled, setIsFormDisabled] = useState(false);
//   const [showOtpPopup, setShowOtpPopup] = useState(false);
//   const [otp, setOtp] = useState("");

//   const [formData, setFormData] = useState({
//     name: "",
//     contact: "",
//     email: "",
//     school_name: "",
//     class_field: "",
//     gender: "",
//     address: "",
//     state: "",
//     district: "",
//     zipcode: "",
//   });

//   const [errors, setErrors] = useState({});
//   const dispatch = useDispatch();

//   // Fetch participant details on load
//   useEffect(() => {
//     const fetchParticipantDetails = async () => {
//       try {
//         const token = localStorage.getItem("token");
//         if (!token) {
//           console.log("No token found");
//           return;
//         }

//         const response = await fetch(`${BASE_URL}ispl/details/", {
//           method: "GET",
//           headers: {
//             Authorization: token,
//             "Content-Type": "application/json",
//           },
//         });

//         if (!response.ok) {
//           throw new Error("Failed to fetch participant details");
//         }

//         const data = await response.json();
//         setFormData(data);
//         setIsFormDisabled(true); 
//       } catch (error) {
//         console.error("Error fetching participant details:", error.message || error);
//       }
//     };

//     fetchParticipantDetails();
//   }, []);

//   const validateForm = () => {
//     const formErrors = {};
//     if (!formData.name) formErrors.name = "Name is required";
//     if (!formData.contact || formData.contact.length !== 10) formErrors.contact = "Contact must be 10 digits";
//     if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) formErrors.email = "Valid email is required";
//     if (!formData.school_name) formErrors.school_name = "School name is required";
//     if (!formData.class_field) formErrors.class_field = "Class is required";
//     if (!formData.gender) formErrors.gender = "Gender is required";
//     if (!formData.address) formErrors.address = "Address is required";
//     if (!formData.state) formErrors.state = "State is required";
//     if (!formData.district) formErrors.district = "District is required";
//     if (!formData.zipcode || !/^\d{6}$/.test(formData.zipcode)) formErrors.zipcode = "Valid ZIP Code is required";
//     return formErrors;
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     const formErrors = validateForm();
//     setErrors(formErrors);

//     if (Object.keys(formErrors).length === 0) {
//       dispatch(requestOtp(formData.contact));
//       setShowOtpPopup(true);
//     }
//   };

//   const handleOtpSubmit = () => {
//     if (otp.length === 6) {
//       dispatch(verifyOtp({ ...formData, otp }));
//       setShowOtpPopup(false);
//       setOtp("");
//     } else {
//       setErrors((prevErrors) => ({ ...prevErrors, otp: "OTP must be 6 digits" }));
//     }
//   };

//   return (
//     <>
//       <Navbar />
//       <div className="form-container">
//         <form className="form" onSubmit={handleSubmit}>
//           <p className="form-note">Fields marked with * are compulsory</p>
//           <div className="form-row">
//   <div className="full-width">
//     <label>Name*</label>
//     <input
//       type="text"
//       placeholder="Name"
//       value={formData.name}
//       onChange={(e) => setFormData({ ...formData, name: e.target.value })}
//       disabled={isFormDisabled}
//       required
//     />
//     {errors.name && <span className="error">{errors.name}</span>}
//   </div>
// </div>

// <div className="form-row">
//   <div className="full-width">
//     <label>Contact*</label>
//     <input
//       type="text"
//       placeholder="Contact"
//       value={formData.contact}
//       onChange={(e) => setFormData({ ...formData, contact: e.target.value })}
//       disabled={isFormDisabled}
//       required
//     />
//     {errors.contact && <span className="error">{errors.contact}</span>}
//   </div>
// </div>
// <div className="form-row">
//   <label>Email*</label>
//   <input
//     className="email"
//     type="text"
//     placeholder="Email"
//     value={formData.email}
//     onChange={(e) => setFormData({ ...formData, email: e.target.value })}
//     disabled={isFormDisabled}
//     required
//   />
//   {errors.email && <span className="error">{errors.email}</span>}
// </div>

// <div className="form-row">
//   <div className="half-width">
//     <label>Class*</label>
//     <select
//       value={formData.class_field}
//       onChange={(e) => setFormData({ ...formData, class_field: e.target.value })}
//       disabled={isFormDisabled}
//       required
//     >
//       <option value="">Select Class</option>
//       <option value="Class 1">Class 1</option>
//       <option value="Class 2">Class 2</option>
//       <option value="Class 3">Class 3</option>
//       <option value="Class 3">Class 4</option>
//       <option value="Class 3">Class 5</option>
//       <option value="Class 3">Class 6</option>
//       <option value="Class 3">Class 7</option>
//       <option value="Class 3">Class 8</option>
//       <option value="Class 3">Class 9</option>
//       <option value="Class 3">Class 10</option>
//       {/* Add more class options as needed */}
//     </select>
//     {errors.class_field && <span className="error">{errors.class_field}</span>}
//   </div>

//   <div className="half-width">
//     <label>Gender*</label>
//     <select
//       value={formData.gender}
//       onChange={(e) => setFormData({ ...formData, gender: e.target.value })}
//       disabled={isFormDisabled}
//       required
//     >
//       <option value="">Select Gender</option>
//       <option value="Male">Male</option>
//       <option value="Female">Female</option>
//       <option value="Other">Other</option>
//     </select>
//     {errors.gender && <span className="error">{errors.gender}</span>}
//   </div>
// </div>

// <div className="form-row">
//   <div className="full-width">
//     <label>Address*</label>
//     <input
//       type="text"
//       placeholder="Address"
//       value={formData.address}
//       onChange={(e) => setFormData({ ...formData, address: e.target.value })}
//       disabled={isFormDisabled}
//       required
//     />
//     {errors.address && <span className="error">{errors.address}</span>}
//   </div>
// </div>

// <div className="form-row">
//   <div className="half-width">
//     <label>State*</label>
//     <input
//       type="text"
//       placeholder="State"
//       value={formData.state}
//       onChange={(e) => setFormData({ ...formData, state: e.target.value })}
//       disabled={isFormDisabled}
//       required
//     />
//     {errors.state && <span className="error">{errors.state}</span>}
//   </div>

//   <div className="half-width">
//     <label>District*</label>
//     <input
//       type="text"
//       placeholder="District"
//       value={formData.district}
//       onChange={(e) => setFormData({ ...formData, district: e.target.value })}
//       disabled={isFormDisabled}
//       required
//     />
//     {errors.district && <span className="error">{errors.district}</span>}
//   </div>
// </div>

// <div className="form-row">
//   <div className="half-width">
//     <label>ZIP Code*</label>
//     <input
//       type="text"
//       placeholder="ZIP Code"
//       value={formData.zipcode}
//       onChange={(e) => setFormData({ ...formData, zipcode: e.target.value })}
//       disabled={isFormDisabled}
//       required
//     />
//     {errors.zipcode && <span className="error">{errors.zipcode}</span>}
//   </div>
// </div>


//           <button type="submit" className="submit-btn" disabled={isFormDisabled}>
//             Submit
//           </button>
//         </form>
//       </div>

//       {showOtpPopup && (
//         <div className="otp-popup">
//           <div className="otp-popup-content">
//             <h3>Enter OTP</h3>
//             <input
//               type="text"
//               placeholder="Enter OTP"
//               value={otp}
//               onChange={(e) => setOtp(e.target.value)}
//               maxLength="6"
//               required
//             />
//             {errors.otp && <span className="error">{errors.otp}</span>}
//             <div className="otp-popup-buttons">
//               <button onClick={handleOtpSubmit} className="submit-btn">
//                 Submit
//               </button>
//               <button onClick={() => setShowOtpPopup(false)} className="close-btn">
//                 Cancel
//               </button>
//             </div>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default FormPage;



import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { requestOtp, verifyOtp } from "../../features/products/otpActions";
import "./FormPage.css";
import Navbar from "../navbar/Navbar";
import { getEnvironmentDetails } from "../../environment";

const FormPage = () => {
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [showOtpPopup, setShowOtpPopup] = useState(false);
  const [otp, setOtp] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    contact: "",
    email: "",
    school_name: "",
    class_field: "",
    gender: "",
    address: "",
    state: "",
    district: "",
    zipcode: "",
  });
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const { BASE_URL } = getEnvironmentDetails();
  // Fetch participant details on load
  useEffect(() => {
    const fetchParticipantDetails = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }

        const response = await fetch(`${BASE_URL}ispl/details/`, {
          method: "GET",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch participant details");
        }

        const data = await response.json();
        setFormData(data);
        setIsFormDisabled(true);
      } catch (error) {
        console.error("Error fetching participant details:", error.message || error);
      }
    };

    fetchParticipantDetails();
  }, []);

  // Form validation
  const validateForm = () => {
    const formErrors = {};
    if (!formData.name.trim()) formErrors.name = "Name is required";
    if (!formData.contact || !/^\d{10}$/.test(formData.contact)) {
      formErrors.contact = "Contact must be a valid 10-digit number";
    }
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = "Valid email is required";
    }
    if (!formData.school_name.trim()) formErrors.school_name = "School name is required";
    if (!formData.class_field) formErrors.class_field = "Class is required";
    if (!formData.gender) formErrors.gender = "Gender is required";
    if (!formData.address.trim()) formErrors.address = "Address is required";
    if (!formData.state.trim()) formErrors.state = "State is required";
    if (!formData.district.trim()) formErrors.district = "District is required";
    if (!formData.zipcode || !/^\d{6}$/.test(formData.zipcode)) {
      formErrors.zipcode = "ZIP Code must be a valid 6-digit number";
    }
    return formErrors;
  };

  // Handle form submission
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   const formErrors = validateForm();
  //   setErrors(formErrors);

  //   if (Object.keys(formErrors).length === 0) {
  //     dispatch(requestOtp(formData.contact));
  //     setShowOtpPopup(true);
  //   }
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted with data:", formData);
  
    const formErrors = validateForm();
    console.log("Validation errors:", formErrors);
    setErrors(formErrors);
  
    if (Object.keys(formErrors).length === 0) {
      console.log("No validation errors. Requesting OTP...");
      dispatch(requestOtp(formData.contact));
      setShowOtpPopup(true);
    } else {
      console.log("Form has errors. Fix them to proceed.");
    }
  };
  
  // Handle OTP submission
  const handleOtpSubmit = () => {
    if (otp.length === 6) {
      dispatch(verifyOtp({ ...formData, otp }));
      setShowOtpPopup(false);
      setOtp("");
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, otp: "OTP must be 6 digits" }));
    }
  };

  // Handle field changes
  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };

  return (
    <>
      <Navbar />
      <div className="form-container">
        <form className="form" onSubmit={handleSubmit}>
          <p className="form-note">Fields marked with * are compulsory</p>

          {/* Name */}
          <div className="form-row">
            <div className="full-width">
              <label>Name*</label>
              <input
                type="text"
                placeholder="Name"
                value={formData.name}
                onChange={(e) => handleInputChange("name", e.target.value)}
                disabled={isFormDisabled}
              />
              {errors.name && <span className="error">{errors.name}</span>}
            </div>
          </div>

          {/* Contact */}
          <div className="form-row">
            <div className="full-width">
              <label>Contact*</label>
              <input
                type="text"
                placeholder="Contact"
                value={formData.contact}
                onChange={(e) => handleInputChange("contact", e.target.value)}
                disabled={isFormDisabled}
              />
              {errors.contact && <span className="error">{errors.contact}</span>}
            </div>
          </div>

          {/* Email */}
          <div className="form-row">
            <label>Email*</label>
            <input
              className="email"
              type="text"
              placeholder="Email"
              value={formData.email}
              onChange={(e) => handleInputChange("email", e.target.value)}
              disabled={isFormDisabled}
            />
            {errors.email && <span className="error">{errors.email}</span>}
          </div>
          <div className="form-row">
            <div className="full-width">
              <label>School Name*</label>
              <input
                type="text"
                placeholder="School Name"
                value={formData.school_name}
                onChange={(e) => handleInputChange("school_name", e.target.value)}
                disabled={isFormDisabled}
              />
              {errors.school_name && <span className="error">{errors.school_name}</span>}
            </div>
          </div>


          {/* Class and Gender */}
          <div className="form-row">
            <div className="half-width">
              <label>Class*</label>
              <select
                value={formData.class_field}
                onChange={(e) => handleInputChange("class_field", e.target.value)}
                disabled={isFormDisabled}
              >
                <option value="">Select Class</option>
                {[...Array(10)].map((_, i) => (
                  <option key={i} value={`Class ${i + 1}`}>
                    Std {i + 1}
                  </option>
                ))}
              </select>
              {errors.class_field && <span className="error">{errors.class_field}</span>}
            </div>

            <div className="half-width">
              <label>Gender*</label>
              <select
                value={formData.gender}
                onChange={(e) => handleInputChange("gender", e.target.value)}
                disabled={isFormDisabled}
              >
                <option value="">Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>
              {errors.gender && <span className="error">{errors.gender}</span>}
            </div>
          </div>

          {/* Address */}
          <div className="form-row">
            <div className="full-width">
              <label>Address*</label>
              <textarea
                placeholder="Enter your address"
                value={formData.address}
                onChange={(e) => handleInputChange("address", e.target.value)}
                disabled={isFormDisabled}
                rows={4} // Number of visible rows
                style={{ resize: "vertical" }} // Allows vertical resizing only
              ></textarea>
              {errors.address && <span className="error">{errors.address}</span>}
            </div>
          </div>


          {/* State, District, ZIP Code */}
          <div className="form-row">
            <div className="half-width">
              <label>State*</label>
              <input
                type="text"
                placeholder="State"
                value={formData.state}
                onChange={(e) => handleInputChange("state", e.target.value)}
                disabled={isFormDisabled}
              />
              {errors.state && <span className="error">{errors.state}</span>}
            </div>

            <div className="half-width">
              <label>District*</label>
              <input
                type="text"
                placeholder="District"
                value={formData.district}
                onChange={(e) => handleInputChange("district", e.target.value)}
                disabled={isFormDisabled}
              />
              {errors.district && <span className="error">{errors.district}</span>}
            </div>
          </div>

          <div className="form-row">
            <div className="half-width">
              <label>ZIP Code*</label>
              <input
                type="text"
                placeholder="ZIP Code"
                value={formData.zipcode}
                onChange={(e) => handleInputChange("zipcode", e.target.value)}
                disabled={isFormDisabled}
              />
              {errors.zipcode && <span className="error">{errors.zipcode}</span>}
            </div>
          </div>

          <button type="submit" className="submit-btn" disabled={isFormDisabled}>
            Submit
          </button>
        </form>
      </div>

      {/* OTP Popup */}
{showOtpPopup && (
  <div className="otp-popup">
    <div className="otp-popup-content">
      <h3>Enter OTP</h3>
      <input
        type="text"
        placeholder="Enter OTP"
        value={otp}
        onChange={(e) => {
          // Allow only numeric values and limit to 6 digits
          const value = e.target.value.replace(/[^0-9]/g, "").slice(0, 6);
          setOtp(value);
        }}
        maxLength="6"
        required
      />
      {errors.otp && <span className="error">{errors.otp}</span>}
      <div className="otp-popup-buttons">
        <button onClick={handleOtpSubmit} className="submit-btn">
          Submit
        </button>
        <button onClick={() => setShowOtpPopup(false)} className="close-btn">
          Cancel
        </button>
      </div>
    </div>
  </div>
)}

    </>
  );
};

export default FormPage;
