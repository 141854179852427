// import React from 'react';
// import './OrderDetails.css';
// import { useLocation, useNavigate } from "react-router-dom";
// import decryptURI from '../modifier/decryptURI';

// const OrderDetails = () => {
//     const location = useLocation();
//     const navigate = useNavigate();
  
//     // Get the order data passed from OrderPage
//     const order = location.state?.order;
//     console.log(order)
  
//     if (!order) {
//       return (
//         <div className="order-details">
//           <h1>No Order Data</h1>
//           <button onClick={() => navigate("/myorders")}>Go Back</button>
//         </div>
//       );
//     }
//   return (
//     <div className="order-details-container">
//       <h1>Order Details</h1>
//       <p>
//   Order on {new Date(order.crearted).toLocaleDateString("en-GB")} &nbsp;&nbsp; 
//   <strong>OrderID #{order.orderId}</strong>
// </p>

//       <div className="info-container">
//         <div className="info-section">
//           <h3>Shipping Address</h3>
//           {/* <p>{order.address.address}</p>
//           <p>{order.address.dist}</p>
//           <p>{order.address.state}</p>
//           <p>{order.address.pincode}</p> */}
//         </div>

//         <div className="info-section">
//   <h3>Order Summary</h3>
//   <div className="info-row">
//     <p><strong>Item Price:</strong></p>
//     <p>₹{order.level.price}</p>
//   </div>
//   <div className="info-row">
//     <p><strong>Promotion Applied:</strong></p>
//     <p>-₹{order.level.price - order.txnAmount}</p>
//   </div>
//   <div className="info-row">
//     <p><strong>Total:</strong></p>
//     <p>₹{order.txnAmount}</p>
//   </div>
// </div>

//       </div>

//       <div className="product-container">
//         <img
//           src={decryptURI(order.level.photo)}
//           alt="STEM Learning Kit"
//           className="product-image"
//         />
//         <div className="product-details">
//           {/* <h4>{order.level.level_name}</h4> */}
//           <p>{order.level.level_title}</p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default OrderDetails;


// import React from 'react';
// import './OrderDetails.css';
// import { useLocation, useNavigate } from "react-router-dom";
// import decryptURI from '../modifier/decryptURI';

// const OrderDetails = () => {
//     const location = useLocation();
//     const navigate = useNavigate();
  
//     // Get the order data passed from OrderPage
//     const order = location.state?.order;
//     console.log(order)
  
//     if (!order) {
//       return (
//         <div className="order-details">
//           <h1>No Order Data</h1>
//           <button onClick={() => navigate("/myorders")}>Go Back</button>
//         </div>
//       );
//     }
//   return (
//     <div className="order-details-container">
//       <h1>Order Details</h1>
//       <p>
//         Order on {new Date(order.crearted).toLocaleDateString("en-GB")} &nbsp;&nbsp; 
//         <strong>OrderID #{order.orderId}</strong>
//       </p>
//       <div className="invoice-section">
//         <button onClick={() => navigate(`/invoice/${order.orderId}`)}>View Invoice</button>
//       </div>

//       <div className="info-container">
//         <div className="info-section">
//           <h3>Shipping Address</h3>
//           {/* Show address only if it exists */}
//           {order.address ? (
//             <>
//               <p>{order.address.address}</p>
//               <p>{order.address.dist}</p>
//               <p>{order.address.state}</p>
//               <p>{order.address.pincode}</p>
//             </>
//           ) : (
//             <p>No shipping address available</p>
//           )}
//         </div>

//         <div className="info-section">
//           <h3>Order Summary</h3>
//           <div className="info-row">
//             <p><strong>Item Price:</strong></p>
//             <p>₹{order.level.price}</p>
//           </div>
//           <div className="info-row">
//             <p><strong>Promotion Applied:</strong></p>
//             <p>-₹{order.level.price - order.txnAmount}</p>
//           </div>
//           <div className="info-row">
//             <p><strong>Total:</strong></p>
//             <p>₹{order.txnAmount}</p>
//           </div>
//         </div>

//       </div>

//       <div className="product-container">
//         <img
//           src={decryptURI(order.level.photo)}
//           alt="STEM Learning Kit"
//           className="product-image"
//         />
//         <div className="product-details">
//           {/* <h4>{order.level.level_name}</h4> */}
//           <p>{order.level.level_title}</p>
//         </div>
//       </div>
      
//     </div>
//   );
// };

// export default OrderDetails;



// import React from 'react';
// import './OrderDetails.css';
// import { useLocation, useNavigate } from "react-router-dom";
// import decryptURI from '../modifier/decryptURI';

// const OrderDetails = () => {
//   const location = useLocation();
//   const navigate = useNavigate();

//   // Get the order data passed from OrderPage
//   const order = location.state?.order;
//   console.log(order);

//   if (!order) {
//     return (
//       <div className="order-details">
//         <h1>No Order Data</h1>
//         <button onClick={() => navigate("/myorders")}>Go Back</button>
//       </div>
//     );
//   }

//   const handleViewInvoice = () => {
//     navigate(`/invoice/${order.orderId}`, { state: { order } });
//   };

//   return (
//     <div className="order-details-container">
//       <h1>Order Details</h1>
//       <p>
//         Order on {new Date(order.crearted).toLocaleDateString("en-GB")} &nbsp;&nbsp; 
//         <strong>OrderID #{order.orderId}</strong>
//       </p>
//       <div className="invoice-section">
//         <button onClick={handleViewInvoice}>View Invoice</button>
//       </div>

//       <div className="info-container">
//         <div className="info-section">
//           <h3>Shipping Address</h3>
//           {order.address ? (
//             <>
//               <p>{order.address.address}</p>
//               <p>{order.address.dist}</p>
//               <p>{order.address.state}</p>
//               <p>{order.address.pincode}</p>
//             </>
//           ) : (
//             <p>No shipping address available</p>
//           )}
//         </div>

//         <div className="info-section">
//           <h3>Order Summary</h3>
//           <div className="info-row">
//             <p><strong>Item Price:</strong></p>
//             <p>₹{order.level.price}</p>
//           </div>
//           <div className="info-row">
//             <p><strong>Promotion Applied:</strong></p>
//             <p>-₹{order.level.price - order.txnAmount}</p>
//           </div>
//           <div className="info-row">
//             <p><strong>Total:</strong></p>
//             <p>₹{order.txnAmount}</p>
//           </div>
//         </div>
//       </div>

//       <div className="product-container">
//         <img
//           src={decryptURI(order.level.photo)}
//           alt="STEM Learning Kit"
//           className="product-image"
//         />
//         <div className="product-details">
//           <p>{order.level.level_title}</p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default OrderDetails;



import React from 'react';
import './OrderDetails.css';
import { useLocation, useNavigate } from "react-router-dom";
import decryptURI from '../modifier/decryptURI';

const OrderDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Get the order data passed from OrderPage
  const order = location.state?.order;
  console.log(order);

  if (!order) {
    return (
      <div className="order-details">
        <h1>No Order Data</h1>
        <button onClick={() => navigate("/myorders")}>Go Back</button>
      </div>
    );
  }

  const handleViewInvoice = () => {
    navigate(`/invoice/${order.orderId}`, { state: { order } });
  };

  // Status tracker
  const statuses = ["PACKED", "DISPATCHED", "OUT_FOR_DELIVERY", "DELIVERED"];
  const currentStatusIndex = statuses.indexOf(order.delivery_status);
  // console.log("sts",order.delivery_status)
  return (
    <div className="order-details-container">
      <h1>Order Details</h1>
      <p>
        Order on {new Date(order.crearted).toLocaleDateString("en-GB")} &nbsp;&nbsp; 
        <strong>OrderID #{order.orderId}</strong>
      </p>

      {/* Delivery Status Tracker */}
      <div className="status-tracker">
  {statuses.map((status, index) => {
    // console.log(`Status: ${status}, Active: ${index <= currentStatusIndex}`);
    return (
      <div
        key={index}
        className={`status-item ${index <= currentStatusIndex ? "active" : ""}`}
      >
        <div className="status-circle"></div>
        <p>{status}</p>
      </div>
    );
  })}
</div>


      <div className="invoice-section">
        <button onClick={handleViewInvoice}>View Invoice</button>
      </div>

      <div className="info-container">
        <div className="info-section">
          <h3>Shipping Address</h3>
          {order.address ? (
            <>
              <p>{order.address.address}</p>
              <p>{order.address.dist}</p>
              <p>{order.address.state}</p>
              <p>{order.address.pincode}</p>
            </>
          ) : (
            <p>No shipping address available</p>
          )}
        </div>

        <div className="info-section">
          <h3>Order Summary</h3>
          <div className="info-row">
            <p><strong>Item Price:</strong></p>
            <p>₹{order.level.price}</p>
          </div>
          <div className="info-row">
            <p><strong>Promotion Applied:</strong></p>
            <p>-₹{order.level.price - order.txnAmount}</p>
          </div>
          <div className="info-row">
            <p><strong>Total:</strong></p>
            <p>₹{order.txnAmount}</p>
          </div>
        </div>
      </div>

      <div className="product-container">
        <img
          src={decryptURI(order.level.photo)}
          alt="STEM Learning Kit"
          className="product-image"
        />
        <div className="product-details">
          <p>{order.level.level_title}</p>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
