// import React, { useState } from "react";
// import { Box, TextField, Typography, Button, Paper, Card, CardContent, CardMedia, Grid } from "@mui/material";
// import { useDropzone } from "react-dropzone";
// import Navbar from "../navbar/Navbar";

// const MediaUploadPage = () => {
//   const [mediaTitle, setMediaTitle] = useState("");
//   const [mediaDescription, setMediaDescription] = useState("");
//   const [file, setFile] = useState(null);
//   const [error, setError] = useState("");
//   const [uploadSuccess, setUploadSuccess] = useState(null);
//   const [videos, setVideos] = useState([]); // State to store uploaded videos

//   const validVideoFormats = ["video/mp4", "video/x-matroska", "video/x-msvideo", "video/quicktime"]; // mp4, mkv, avi, mov

//   const onDrop = (acceptedFiles) => {
//     const videoFile = acceptedFiles[0];
//     if (!videoFile || !validVideoFormats.includes(videoFile.type)) {
//       setError("Only video files in mp4, mkv, avi, or mov formats are allowed.");
//       setFile(null);
//       return;
//     }
//     setError("");
//     setFile(videoFile);
//   };

//   const { getRootProps, getInputProps } = useDropzone({
//     onDrop,
//     accept: validVideoFormats.join(","),
//     maxSize: 200 * 1024 * 1024, // 200MB
//   });

//   const uploadVideo = async () => {
//     if (!file || !mediaTitle) {
//       setError("Please provide all required fields.");
//       return;
//     }

//     const formData = new FormData();
//     formData.append("video", file);
//     formData.append("video_title", mediaTitle);
//     formData.append("video_description", mediaDescription);

//     try {
//       const token = localStorage.getItem("token"); // Assuming the JWT token is stored in localStorage
//       const response = await fetch(`${BASE_URL}ispl/upload-video/", {
//         method: "POST",
//         headers: {
//           Authorization: token,
//         },
//         body: formData,
//       });

//       if (response.ok) {
//         const result = await response.json();
//         setUploadSuccess("Video uploaded successfully!");
//         setVideos((prev) => [...prev, result.data]); // Add the new video to the list
//         setFile(null);
//         setMediaTitle("");
//         setMediaDescription("");
//       } else {
//         const errorData = await response.json();
//         setError(errorData.error || "Failed to upload the video.");
//       }
//     } catch (error) {
//       setError("An unexpected error occurred. Please try again.");
//     }
//   };

//   return (
//     <>
//       <Navbar />
//       <Box sx={{ p: 4, maxWidth: 1200, mx: "auto" }}>
//         <Typography variant="h5" gutterBottom>
//           Upload Video
//         </Typography>

//         {/* Media Title */}
//         <TextField
//           label="Media Title"
//           fullWidth
//           variant="outlined"
//           value={mediaTitle}
//           onChange={(e) => setMediaTitle(e.target.value)}
//           sx={{ mb: 2 }}
//         />

//         {/* Media Description */}
//         <TextField
//           label="Media Description"
//           fullWidth
//           multiline
//           rows={4}
//           variant="outlined"
//           value={mediaDescription}
//           onChange={(e) => setMediaDescription(e.target.value)}
//           sx={{ mb: 2 }}
//         />

//         {/* File Uploader */}
//         <Paper
//           variant="outlined"
//           {...getRootProps()}
//           sx={{
//             p: 3,
//             textAlign: "center",
//             backgroundColor: "#f9f9f9",
//             borderRadius: 1,
//             cursor: "pointer",
//           }}
//         >
//           <input {...getInputProps()} />
//           <Typography variant="body1">
//             {file ? `Selected file: ${file.name}` : "Drag and drop a video file here or click to browse"}
//           </Typography>
//           <Typography variant="caption" color="textSecondary">
//             Limit 200MB per file • Only mp4, mkv, avi, mov formats allowed
//           </Typography>
//         </Paper>

//         {error && (
//           <Typography color="error" variant="body2" sx={{ mt: 1 }}>
//             {error}
//           </Typography>
//         )}

//         {uploadSuccess && (
//           <Typography color="primary" variant="body2" sx={{ mt: 1 }}>
//             {uploadSuccess}
//           </Typography>
//         )}

//         {/* Upload Button */}
//         <Button
//           variant="contained"
//           color="primary"
//           fullWidth
//           sx={{ mt: 3 }}
//           disabled={!file || !mediaTitle}
//           onClick={uploadVideo}
//         >
//           Upload
//         </Button>

//         {/* Uploaded Videos Section */}
//         <Box sx={{ mt: 4 }}>
//           <Typography variant="h6" gutterBottom>
//             Uploaded Videos
//           </Typography>

//           {/* Grid to show videos like YouTube */}
//           <Grid container spacing={3}>
//             {videos.map((video, index) => (
//               <Grid item xs={12} sm={6} md={4} key={index}>
//                 <Card sx={{ height: "100%" }}>
//                   <CardMedia
//                     component="video"
//                     controls
//                     src={video.s3_url}
//                     sx={{
//                       height: 200,
//                       objectFit: "cover",
//                     }}
//                   />
//                   <CardContent>
//                     <Typography variant="h6" sx={{ fontWeight: "bold", fontSize: "1rem" }}>
//                       {video.video_title}
//                     </Typography>
//                     <Typography variant="body2" color="textSecondary">
//                       {video.video_description}
//                     </Typography>
//                   </CardContent>
//                 </Card>
//               </Grid>
//             ))}
//           </Grid>
//         </Box>
//       </Box>
//     </>
//   );
// };

// export default MediaUploadPage;



import React, { useState, useEffect } from "react";
import { Box, TextField, Typography, Button, Paper, Card, CardContent, CardMedia, Grid } from "@mui/material";
import { useDropzone } from "react-dropzone";
import Navbar from "../navbar/Navbar";
import { getEnvironmentDetails } from "../../environment";

const MediaUploadPage = () => {
  const [mediaTitle, setMediaTitle] = useState("");
  const [mediaDescription, setMediaDescription] = useState("");
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  const [uploadSuccess, setUploadSuccess] = useState(null);
  const [videos, setVideos] = useState([]); // State to store uploaded videos
  const [loadingVideos, setLoadingVideos] = useState(true); // State to track loading
  const { BASE_URL } = getEnvironmentDetails(); // Destructure BASE_URL
  const validVideoFormats = ["video/mp4", "video/x-matroska", "video/x-msvideo", "video/quicktime"]; // mp4, mkv, avi, mov

  const onDrop = (acceptedFiles) => {
    const videoFile = acceptedFiles[0];
    if (!videoFile || !validVideoFormats.includes(videoFile.type)) {
      setError("Only video files in mp4, mkv, avi, or mov formats are allowed.");
      setFile(null);
      return;
    }
    setError("");
    setFile(videoFile);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: validVideoFormats.join(","),
    maxSize: 200 * 1024 * 1024, // 200MB
  });

  const uploadVideo = async () => {
    if (!file || !mediaTitle) {
      setError("Please provide all required fields.");
      return;
    }

    const formData = new FormData();
    formData.append("video", file);
    formData.append("video_title", mediaTitle);
    formData.append("video_description", mediaDescription);

    try {
      const token = localStorage.getItem("token"); // Retrieve the token from localStorage
    
      if (!token) {
        alert("Please log in to upload a video."); // Alert the user to log in
        setError("You need to log in to upload a video.");
        return; // Exit the function early
      }
    
      const response = await fetch(`${BASE_URL}ispl/upload-video/`, {
        method: "POST",
        headers: {
          Authorization: token,
        },
        body: formData,
      });
    
      if (response.ok) {
        const result = await response.json();
        setUploadSuccess("Video uploaded successfully!");
        setVideos((prev) => [...prev, result.data]); // Add the new video to the list
        setFile(null);
        setMediaTitle("");
        setMediaDescription("");
      } else {
        const errorData = await response.json();
        setError(errorData.error || "Failed to upload the video.");
      }
    } catch (error) {
      setError("An unexpected error occurred. Please try again.");
    }
  };
    

  const fetchVideos = async () => {
    setLoadingVideos(true);
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        setError("Please log in to view uploaded videos.");
        setLoadingVideos(false);
        return;
      }

      const response = await fetch(`${BASE_URL}ispl/videos/`, {
        method: "GET",
        headers: {
          Authorization: token,
        },
      });

      if (response.ok) {
        const result = await response.json();
        setVideos(result.data.videos || []);
      } else {
        const errorData = await response.json();
        setError(errorData.message || "Failed to fetch videos.");
      }
    } catch (error) {
      setError("An unexpected error occurred while fetching videos.");
    } finally {
      setLoadingVideos(false);
    }
  };

  useEffect(() => {
    fetchVideos();
  }, []);

  return (
    <>
      <Navbar />
      
        <div className="header">
          <h1 className="title">Elimination Round</h1>
          <p className="description">
            The Final Round will test your skills in various aspects. Prepare well
            to succeed and demonstrate your excellence. Stay tuned for more
            updates regarding the schedule and venue.
          </p>
        </div>
      <Box sx={{ p: 4, maxWidth: 1200, mx: "auto" }}>
        <Typography variant="h5" gutterBottom>
          Upload Video
        </Typography>

        {/* Media Title */}
        <TextField
          label="Media Title"
          fullWidth
          variant="outlined"
          value={mediaTitle}
          onChange={(e) => setMediaTitle(e.target.value)}
          sx={{ mb: 2 }}
        />

        {/* Media Description */}
        <TextField
          label="Media Description"
          fullWidth
          multiline
          rows={4}
          variant="outlined"
          value={mediaDescription}
          onChange={(e) => setMediaDescription(e.target.value)}
          sx={{ mb: 2 }}
        />

        {/* File Uploader */}
        <Paper
          variant="outlined"
          {...getRootProps()}
          sx={{
            p: 3,
            textAlign: "center",
            backgroundColor: "#f9f9f9",
            borderRadius: 1,
            cursor: "pointer",
          }}
        >
          <input {...getInputProps()} />
          <Typography variant="body1">
            {file ? `Selected file: ${file.name}` : "Drag and drop a video file here or click to browse"}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            Limit 200MB per file • Only mp4, mkv, avi, mov formats allowed
          </Typography>
        </Paper>

        {error && (
          <Typography color="error" variant="body2" sx={{ mt: 1 }}>
            {error}
          </Typography>
        )}

        {uploadSuccess && (
          <Typography color="primary" variant="body2" sx={{ mt: 1 }}>
            {uploadSuccess}
          </Typography>
        )}

        {/* Upload Button */}
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 3 }}
          disabled={!file || !mediaTitle}
          onClick={uploadVideo}
        >
          Upload
        </Button>

        {/* Uploaded Videos Section */}
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" gutterBottom>
            Uploaded Videos
          </Typography>

          {loadingVideos ? (
            <Typography variant="body2">Loading videos...</Typography>
          ) : videos.length === 0 ? (
            <Typography variant="body2">No videos uploaded yet.</Typography>
          ) : (
            <Grid container spacing={3}>
              {videos.map((video, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card sx={{ height: "100%" }}>
                    <CardMedia
                      component="video"
                      controls
                      src={video.video_url}
                      sx={{
                        height: 200,
                        objectFit: "cover",
                      }}
                    />
                    <CardContent>
                      <Typography variant="h6" sx={{ fontWeight: "bold", fontSize: "1rem" }}>
                        {video.video_title}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {video.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      </Box>
    </>
  );
};

export default MediaUploadPage;
