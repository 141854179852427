// import React from "react";
// import "./About.css";


// const About = () => {
//   const handleAppButtonClick = () => {
//     // Open the provided link in a new tab
//     window.open(
//       "https://play.google.com/store/apps/details?id=com.iLearnPlace.stemlearning&pcampaignid=web_share",
//       "_blank"
//     );
//   };
//   return (
//     <section className="about" id="about">
//       {/* Heading Section */}

//       {/* About Content Section */}
//       <div className="about-content">
//       <h2 className="about-heading">
//         About <span className="highlight">iLearnPlace</span>
//       </h2>
//         {/* Left Section: Text */}
//         <div className="about-text">
//           <p>
//           iLearnPlace is a leading STEM learning and robotics company dedicated to making practical and hands-on education fun and accessible for every child. We focus on fostering creativity, critical thinking, and problem-solving by providing engaging learning experiences through innovative kits and interactive STEM sessions. Our mission is to inspire curiosity and empower the leaders of tomorrow through practical, real-world applications.
//           </p>
//         </div>

        
//         <div className="about-video">
//           <iframe
//             className="video-player"
//             width="100%"
//             height="315"
//             src="https://www.youtube.com/embed/MXcqxfVoYMY" // Embed link for YouTube video
//             title="YouTube video player"
//             frameBorder="0"
//             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//             allowFullScreen
//           ></iframe>
//         </div>
//       </div>

      

      
//     </section>
//   );
// };

// export default About;



import React from "react";
import "./About.css";

const About = () => {
  return (
    <div className="about" id="about">
      {/* Heading Section */}
      <h2 className="about-heading">
        About <span className="highlight">ISPL</span>
      </h2>

      {/* About Content Section */}
      <div className="about-content">
        {/* Left Section: Text */}
        <div className="about-text">
          <p>
            iLearnPlace is a leading STEM learning and robotics company
            dedicated to making practical and hands-on education fun and
            accessible for every child. We focus on fostering creativity,
            critical thinking, and problem-solving by providing engaging learning
            experiences through innovative kits and interactive STEM sessions. Our
            mission is to inspire curiosity and empower the leaders of tomorrow
            through practical, real-world applications.
          </p>
        </div>

        {/* Right Section: Video */}
        <div className="about-video">
          <iframe
            className="video-player"
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/MXcqxfVoYMY" // Embed link for YouTube video
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default About;
