// import React from "react";
// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";
// import "./Invoice.css";
// import logo from "../../assets/images/logo.png"

// import { useLocation } from "react-router-dom";

// const Invoice = () => {
//   const location = useLocation();
//   const order = location.state?.order;
//   console.log(order)
//   if (!order) {
//     return <div>No invoice order available</div>;
//   }


//   // Calculate subtotal, tax, and total
//   const subtotal = order.txnAmount;
//   const tax = subtotal * 0.1; // Assuming 10% tax
//   const total = subtotal + tax;

//   // Handle PDF download
//   const handleDownload = async () => {
//     const invoiceElement = document.getElementById("invoice");

//     // Capture HTML content as a canvas
//     const canvas = await html2canvas(invoiceElement);
//     const imgData = canvas.toDataURL("image/png");

//     // Generate PDF using jsPDF
//     const pdf = new jsPDF("p", "mm", "a4");
//     const pdfWidth = pdf.internal.pageSize.getWidth();
//     const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

//     pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
//     pdf.save(`Invoice_${order.invoiceNumber}.pdf`);
//   };

//   return (
//     <div>
//       {/* Invoice template */}
//       <div id="invoice" className="invoice-container">
//         <header className="invoice-header">
//           <div className="company-info">
//             <img src={logo} alt="Company Logo" className="logo" />
//             <div>
//               <h1>Ilearnplace</h1>
//               <p>www.ilearnplace.com</p>
//               <p>info@ilearnplace.com</p>
//               <p>+91 8094414990</p>
//             </div>
//           </div>
//           <div className="company-address">
//             <p>Business address</p>
//             <p>City, State, IN - 000 000</p>
//             <p>GSTN :08AASCA2161R1ZZ</p>
//           </div>
//         </header>

//         <section className="invoice-details">
//           <div>
//             <h2>Invoice</h2>
//             <p>Invoice Number: {order.invoiceNumber}</p>
//             <p>Invoice Date: {order.invoiceDate}</p>
//             <p>Due Date: {order.dueDate}</p>
//           </div>
//           <div>
//             <h3>Bill To:</h3>
//             <p>{order.customerName}</p>
//             <p>{order.customerAddress}</p>
//           </div>
//         </section>

//         <section className="invoice-items">
//           <table>
//             <thead>
//               <tr>
//                 <th>Item Detail</th>
//                 <th>Qty</th>
//                 <th>Rate (₹)</th>
//                 <th>Amount (₹)</th>
//               </tr>
//             </thead>
//             <tbody>
//               {order.items.map((item, index) => (
//                 <tr key={index}>
//                   <td>{item.name}</td>
//                   <td>{item.qty}</td>
//                   <td>₹{item.rate}</td>
//                   <td>₹{item.amount}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </section>

//         <section className="invoice-summary">
//           <div>
//             <p>Subtotal: ₹{subtotal.toFixed(2)}</p>
//             <p>Tax (10%): ₹{tax.toFixed(2)}</p>
//             <h3>Total: ₹{total.toFixed(2)}</h3>
//           </div>
//         </section>

//         <footer className="invoice-footer">
//           <p>Thanks for the business.</p>
//           <p>Terms & Conditions: Please pay within 15 days of receiving this invoice.</p>
//         </footer>
//       </div>

//       {/* Download button */}
//       <button className="download-button" onClick={handleDownload}>
//         Download Invoice
//       </button>
//     </div>
//   );
// };

// export default Invoice;


import React from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "./Invoice.css";
import logo from "../../assets/images/logo.png";

import { useLocation } from "react-router-dom";

const Invoice = () => {
  const location = useLocation();
  const order = location.state?.order;

  if (!order) {
    return <div>No invoice order available</div>;
  }

  // Extracting necessary data with fallback defaults
  const { 
    level = {}, 
    student = {}, 
    txnAmount = 0, 
    payment_status = "N/A", 
    orderId = "N/A", 
    crearted = new Date() 
  } = order;

  const total = parseFloat(txnAmount) || 0; // Total is directly the transaction amount
  const tax = total * 0.18; // Total tax (18% of the total)
  const cgst = tax / 2; // CGST (9%)
  const sgst = tax / 2; // SGST (9%)
  const subtotal = total - tax; // Subtotal is total minus the tax

  // Handle PDF download
  const handleDownload = async () => {
    const invoiceElement = document.getElementById("invoice");

    // Capture HTML content as a canvas
    const canvas = await html2canvas(invoiceElement);
    const imgData = canvas.toDataURL("image/png");

    // Generate PDF using jsPDF
    const pdf = new jsPDF("p", "mm", "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save(`Invoice_${orderId}.pdf`);
  };

  return (
    <div>
      {/* Invoice template */}
      <div id="invoice" className="invoice-container">
        <header className="invoice-header">
          <div className="company-info">
            <img src={logo} alt="Company Logo" className="logo" />
            <div>
              <h1>Ilearnplace</h1>
              <p>www.ilearnplace.com</p>
              <p>info@ilearnplace.com</p>
              <p>+91 8094414990</p>
            </div>
          </div>
          <div className="company-address">
            <p>Pl no 91 Tirupati vihar c, </p>
            <p>  NewLoha mandi Road</p>
            <p>Jaipur, Rajstan, IN - 302013 </p>
            <p>GSTN :08AASCA2161R1ZZ</p>
          </div>
        </header>

        <section className="invoice-details">
          <div>
            <h2>Invoice</h2>
            <p>Invoice Number: {orderId}</p>
            <p>Invoice Date: {new Date(crearted).toLocaleDateString("en-GB")}</p>
          </div>
          <div>
            <h3>Bill To:</h3>
            <p>{student.name || " "}</p>
            <p>{student.contact || " "}</p>
            <p>{student.email || " "}</p>
          </div>
        </section>

        <section className="invoice-items">
          <table>
            <thead>
              <tr>
                <th>Item Detail</th>
                <th>Qty</th>
                <th>Rate (₹)</th>
                <th>Discount (₹)</th>
                <th>Amount (₹)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{level.level_title || " "}</td>
                <td>1</td>
                <td>{level.price || 0}</td>
                <td>{(txnAmount - (level.price || 0)).toFixed(2)}</td>
                <td>{txnAmount}</td>
              </tr>
            </tbody>
          </table>
        </section>

        <section className="invoice-summary">
          <div>
            <h4>Subtotal: ₹{subtotal.toFixed(2)}</h4>
            <h4>SGST (9%): ₹{sgst.toFixed(2)}</h4>
            <h4>CGST (9%): ₹{cgst.toFixed(2)}</h4>
            <h3>Total: ₹{total.toFixed(2)}</h3>
          </div>
        </section>

        <footer className="invoice-footer">
          <p>Thanks for the business.</p>
        </footer>
      </div>

      {/* Download button */}
      <button className="download-button" onClick={handleDownload}>
        Download Invoice
      </button>
    </div>
  );
};

export default Invoice;
