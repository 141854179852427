// ChatButton.js
import React from 'react';
import { Box, Fab, Typography } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp'; // Use WhatsApp icon

const ChatButton = () => {
  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 16, // Adjust this value to move the button vertically
        right: 16,  // Adjust this value to move the button horizontally
        zIndex: 1000, // To ensure it's above other elements
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#25D366', // WhatsApp green
        color: 'white',
        padding: '10px',
        borderRadius: '25px',
        boxShadow: '0px 2px 10px rgba(0,0,0,0.2)',
        cursor: 'pointer',
        '&:hover': {
          boxShadow: '0px 4px 20px rgba(0,0,0,0.3)',
        },
      }}
      onClick={() => window.open('https://wa.me/message/W4Y4643JQFYWB1', '_blank')} // Replace with your WhatsApp link
    >
      <WhatsAppIcon />
      <Typography variant="body2" sx={{ ml: 2 }}>
        Click to Chat
      </Typography>
    </Box>
  );
};

export default ChatButton;