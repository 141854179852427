// Function to determine the environment and return appropriate URL and GA ID
export const getEnvironmentDetails = () => {
    let BASE_URL;
    let GA_ID;
  
    // Check the origin to determine the environment
    if (window.location.origin === "https://ilearnplace.com") {
      // Production environment
      BASE_URL = "https://ilearnpoint.com/ilpapi/"; // Production backend URL
      GA_ID = "G-L7KX41PHPX"; // Replace with your production GA ID
    } else {
      // Staging or Development environment
      BASE_URL = "https://almondai.in/ilpapi/"; // Staging backend URL
      GA_ID = "G-CJFK63W88N"; // Replace with your staging GA ID
    }
  
    return { BASE_URL, GA_ID };
  };
  