// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import "./OrderPage.css";
// import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
// import { getEnvironmentDetails } from "../../environment";

// const OrderPage = () => {
//   const navigate = useNavigate();
//   const [orders, setOrders] = useState([]);
//   const token = localStorage.getItem("token");
//   const { BASE_URL } = getEnvironmentDetails(); // Backend URL

//   useEffect(() => {
//     const fetchOrders = async () => {
//       try {
//         const response = await fetch(`${BASE_URL}orders`, {
//           method: "GET",
//           headers: {
//             Authorization: token,
//           },
//         });

//         const data = await response.json();
//         if (!response.ok) throw new Error(data.message || "Failed to fetch orders");

//         setOrders(data.orders);
//       } catch (error) {
//         console.error(error.message || "An error occurred.");
//       }
//     };

//     fetchOrders();
//   }, [token]);

//   if (!orders.length) return <div>Loading...</div>;

//   return (
//     <div className="order-page">
//       {/* Full-Width Banner */}
//       <div className="order-banner">
//         <div className="banner-icon" onClick={() => navigate("/profile")}>
//           <ShoppingCartIcon style={{ fontSize: "40px" }} />
//           <span>Your Orders</span>
//         </div>
//       </div>

//       {/* Orders List */}
//       <div className="order-list">
//         {orders.map((order) => (
//           <div
//             key={order.orderId}
//             className="order-box"
//             onClick={() => navigate(`/order-details/${order.orderId}`)}
//           >
//             <div className="order-item">
//               <img src={order.itemImage} alt={order.itemName} />
//               <p>{order.itemName}</p>
//             </div>
//             <div className="order-id">Order ID: {order.orderId}</div>
//             <div className="order-total">Total: ${order.total}</div>
//             <div className="payment-status">Payment: {order.paymentStatus}</div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default OrderPage;




// import React from "react";
// import { useNavigate } from "react-router-dom";
// import "./OrderPage.css";
// import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

// // Hardcoded dummy orders data
// const orders = [
//   {
//     orderId: "12345",
//     itemName: "Laptop",
//     itemImage: "https://via.placeholder.com/50",
//     total: "899.99",
//     paymentStatus: "Paid",
//   },
//   {
//     orderId: "67890",
//     itemName: "Headphones with Extra Long Name Example",
//     itemImage: "https://via.placeholder.com/50",
//     total: "59.99",
//     paymentStatus: "Pending",
//   },
//   {
//     orderId: "11223",
//     itemName: "Smartphone",
//     itemImage: "https://via.placeholder.com/50",
//     total: "499.99",
//     paymentStatus: "Paid",
//   },
//   {
//     orderId: "44556",
//     itemName: "Tablet",
//     itemImage: "https://via.placeholder.com/50",
//     total: "299.99",
//     paymentStatus: "Pending",
//   },
// ];

// const OrderPage = () => {
//   const navigate = useNavigate();

//   return (
//     <div className="order-page">
//       {/* Full-Width Banner */}
//       <div className="order-banner">
//         <div className="banner-icon" onClick={() => navigate("/profile")}>
//           <AccountCircleIcon style={{ fontSize: "40px" }} />
//           <span>Profile</span>
//         </div>
//         <div className="banner-icon" onClick={() => navigate("/myorders")}>
//           <ShoppingCartIcon style={{ fontSize: "40px" }} />
//           <span>Order Summary</span>
//         </div>
//       </div>

//       {/* Orders List */}
//       <div className="order-list">
//         {orders.map((order) => (
//           <div
//             key={order.orderId}
//             className="order-box"
//             onClick={() => navigate(`/order-details/${order.orderId}`)}
//           >
//             <div className="order-item">
//               <img src={order.itemImage} alt={order.itemName} />
//               <p>{order.itemName}</p>
//             </div>
//             <div className="order-detail">
//               <span>Order ID:</span> {order.orderId}
//             </div>
//             <div className="order-detail order-total">
//               <span>Total:</span> ${order.total}
//             </div>
//             <div className="order-detail payment-status">
//               <span>Payment:</span> {order.paymentStatus}
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default OrderPage;


// import React from "react";
// import { useNavigate } from "react-router-dom";
// import "./OrderPage.css";
// import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

// // Hardcoded dummy orders data
// const orders = []; // Empty list for testing

// const OrderPage = () => {
//   const navigate = useNavigate();

//   return (
//     <div className="order-page">
//       {/* Full-Width Banner */}
//       <div className="order-banner">
//         <div className="banner-icon" onClick={() => navigate("/profile")}>
//           <AccountCircleIcon style={{ fontSize: "40px" }} />
//           <span>Profile</span>
//         </div>
//         <div className="banner-icon" onClick={() => navigate("/myorders")}>
//           <ShoppingCartIcon style={{ fontSize: "40px" }} />
//           <span>Order Summary</span>
//         </div>
//       </div>

//       {/* Orders List */}
//       <div className="order-list">
//         {orders.length > 0 ? (
//           orders.map((order) => (
//             <div
//               key={order.orderId}
//               className="order-box"
//               onClick={() => navigate(`/order-details/${order.orderId}`)}
//             >
//               <div className="order-item">
//                 <img src={order.itemImage} alt={order.itemName} />
//                 <p>{order.itemName}</p>
//               </div>
//               <div className="order-detail">
//                 <span>Order ID:</span> {order.orderId}
//               </div>
//               <div className="order-detail order-total">
//                 <span>Total:</span> ${order.total}
//               </div>
//               <div className="order-detail payment-status">
//                 <span>Payment:</span> {order.paymentStatus}
//               </div>
//             </div>
//           ))
//         ) : (
//           <div className="no-data">No data available</div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default OrderPage;



import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./OrderPage.css";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import decryptURI from "../modifier/decryptURI";

import { getEnvironmentDetails } from "../../environment";

const OrderPage = () => {
  const [orders, setOrders] = useState([]); // State to store fetched orders
  const [loading, setLoading] = useState(true); // State to manage loading
  const [error, setError] = useState(null); // State to manage errors
  const navigate = useNavigate();
  const token = localStorage.getItem("token")
  const { BASE_URL } = getEnvironmentDetails(); // Backend URL
  useEffect(() => {
    // Function to fetch transaction data using fetch API
    const fetchOrders = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${BASE_URL}transaction-details/`, {
          method: "GET",
          headers: {
            Authorization: token, // Pass token from localStorage
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error(
            `Error: ${response.status} - ${response.statusText || "Unknown"}`
          );
        }

        const data = await response.json(); // Parse JSON response
        setOrders(data); // Set fetched orders
      } catch (err) {
        setError(err.message || "Failed to fetch orders.");
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  return (
    <div className="order-page">
      {/* Full-Width Banner */}
      <div className="order-banner">
        <div className="banner-icon" onClick={() => navigate("/profile")}>
          <AccountCircleIcon style={{ fontSize: "40px" }} />
          <span>Profile</span>
        </div>
        <div className="banner-icon" onClick={() => navigate("/myorders")}>
          <ShoppingCartIcon style={{ fontSize: "40px" }} />
          <span>Order Summary</span>
        </div>
      </div>

      {/* Orders List */}
      <div className="order-list">
        {loading ? (
          <div className="loading">Loading...</div>
        ) : error ? (
          <div className="error">{error}</div>
        ) : orders.length > 0 ? (
          orders.map((order) => (
            
            
            <div
              key={order.orderId}
              className="order-box"
              onClick={() => navigate(`/order-details/${order.orderId}`, { state: { order } })}
            >
              <div className="order-item">
                <img
                  src={decryptURI(order.level.photo) }// Placeholder image
                  alt={order.level.level_name}
                />
                <p>{order.level.level_name}</p>
              </div>
              <div className="order-detail">
                <span></span> {order.orderId}
              </div>
              <div className="order-detail order-total">
                <span></span> ₹{order.txnAmount}
              </div>
              <div className="order-detail payment-status">
                <span></span> {order.payment_status}
              </div>
            </div>
          ))
        ) : (
          <div className="no-data">No data available</div>
        )}
      </div>
    </div>
  );
};

export default OrderPage;

// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import "./OrderPage.css";
// import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

// import decryptURI from "../modifier/decryptURI";

// import { getEnvironmentDetails } from "../../environment";

// const OrderPage = () => {
//   const [orders, setOrders] = useState([]); // State to store fetched orders
//   const [loading, setLoading] = useState(true); // State to manage loading
//   const [error, setError] = useState(null); // State to manage errors
//   const navigate = useNavigate();
//   const token = localStorage.getItem("token")
//   const { BASE_URL } = getEnvironmentDetails(); // Backend URL

//   useEffect(() => {
//     // Function to fetch transaction data using fetch API
//     const fetchOrders = async () => {
//       try {
//         setLoading(true);
//         const response = await fetch(`${BASE_URL}transaction-details/`, {
//           method: "GET",
//           headers: {
//             Authorization: token, // Pass token from localStorage
//             "Content-Type": "application/json",
//           },
//         });

//         if (!response.ok) {
//           throw new Error(
//             `Error: ${response.status} - ${response.statusText || "Unknown"}`
//           );
//         }

//         const data = await response.json(); // Parse JSON response
//         setOrders(data); // Set fetched orders
//       } catch (err) {
//         setError(err.message || "Failed to fetch orders.");
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchOrders();
//   }, []);

//   return (
//     <div className="order-page">
//       {/* Full-Width Banner */}
//       <div className="order-banner">
//         <div className="banner-icon" onClick={() => navigate("/profile")}>
//           <AccountCircleIcon style={{ fontSize: "40px" }} />
//           <span>Profile</span>
//         </div>
//         <div className="banner-icon" onClick={() => navigate("/myorders")}>
//           <ShoppingCartIcon style={{ fontSize: "40px" }} />
//           <span>Order Summary</span>
//         </div>
//       </div>

//       {/* Orders List */}
//       <div className="order-list">
//         {loading ? (
//           <div className="loading">Loading...</div>
//         ) : error ? (
//           <div className="error">{error}</div>
//         ) : orders.length > 0 ? (
//           orders.map((order) => {
//             console.log(order); // Log the order to the console

//             return (
//               <div
//                 key={order.orderId}
//                 className="order-box"
//                 onClick={() => navigate(`/order-details/${order.orderId}`, { state: { order } })}
//               >
//                 <div className="order-item">
//                   <img
//                     // src={decryptURI(order.level.photo)} // Placeholder image
//                     alt={order.level.level_name}
//                   />
//                   {/* <p>{order.level.level_name}</p> */}
//                 </div>
//                 <div className="order-detail">
//                   <span>Order ID:</span> {order.orderId}
//                 </div>
//                 <div className="order-detail order-total">
//                   <span>Total:</span> ₹{order.txnAmount}
//                 </div>
//                 <div className="order-detail payment-status">
//                   <span>Payment:</span> {order.payment_status}
//                 </div>
//               </div>
//             );
//           })
//         ) : (
//           <div className="no-data">No data available</div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default OrderPage;
