import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getEnvironmentDetails } from "../../environment";

// Async action to fetch tasks by level code
const { BASE_URL } = getEnvironmentDetails(); // Destructure BASE_URL
export const fetchTasksByLevelCode = createAsyncThunk(
  "tasks/fetchTasksByLevelCode",
  async (levelCode) => {
    const response = await fetch(`${BASE_URL}tasks/${levelCode}/`); // Fetch API instead of Axios
   
    if (!response.ok) {
      throw new Error("Failed to fetch tasks"); // Handle errors
    }
    const data = await response.json(); // Parse the JSON response
    return data;
  }
);

const taskSlice = createSlice({
  name: "tasks",
  initialState: {
    tasks: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTasksByLevelCode.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTasksByLevelCode.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.tasks = action.payload;
      })
      .addCase(fetchTasksByLevelCode.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default taskSlice.reducer;
