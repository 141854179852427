import { createSlice } from "@reduxjs/toolkit";
import { getEnvironmentDetails } from "../../environment";

export const contactusSlice = createSlice({
  name: "contactus",
  initialState: {
    loading: false,
    success: false,
    error: null,
  },
  reducers: {
    contactUsRequest: (state) => {
      state.loading = true;
    },
    contactUsSuccess: (state) => {
      state.loading = false;
      state.success = true;
    },
    contactUsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});
const { BASE_URL } = getEnvironmentDetails(); // Destructure BASE_URL
export const { contactUsRequest, contactUsSuccess, contactUsFailure } =
  contactusSlice.actions;

// Redux thunk action to send the contact us data using fetch
export const sendContactUsData = (contactData) => async (dispatch) => {
  try {
    dispatch(contactUsRequest());
    
    // Use the native fetch API to make the POST request
    const response = await fetch(`${BASE_URL}contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(contactData), // Send the contact data as JSON
    });
    
    if (!response.ok) {
      throw new Error("Failed to send message");
    }

    dispatch(contactUsSuccess());
  } catch (error) {
    dispatch(contactUsFailure(error.message));
  }
};

export default contactusSlice.reducer;
