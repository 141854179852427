import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getEnvironmentDetails } from "../../environment";

// Async thunk to fetch products by gameCode
const { BASE_URL } = getEnvironmentDetails(); // Destructure BASE_URL
export const fetchProductsByGameCode = createAsyncThunk(
  "products/fetchProductsByGameCode",
  async (gameCode, { rejectWithValue }) => {
    try {
      const response = await fetch(`${BASE_URL}levels/${gameCode}/`);
      if (!response.ok) throw new Error("Failed to fetch products");
      const data = await response.json();
      return data; // Return fetched product data
    } catch (error) {
      return rejectWithValue(error.message || "Something went wrong");
    }
  }
);
const productsSlice = createSlice({
  name: "products",
  initialState: {
    products: [],
    status: "idle", // idle | loading | succeeded | failed
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductsByGameCode.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProductsByGameCode.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.products = action.payload;
      })
      .addCase(fetchProductsByGameCode.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default productsSlice.reducer;
