// import React from "react";
// import "./FinalRound.css";
// import Navbar from "../navbar/Navbar";

// const FinalRound = () => {
//   return (
//         <>
//         <Navbar/>
//       <div className="contact-page">
//       {/* Description Section */}
//       <div className="description">
//         <p>
//           TOTC is a platform that allows educators to create online classes
//           whereby they can store the course materials online; manage
//           assignments, quizzes and exams; monitor due dates; grade results and
//           provide students with feedback all in one place.
//         </p>
//         <p>
//           TOTC is a platform that allows educators to create online classes
//           whereby they can store the course materials online; manage
//           assignments, quizzes and exams; monitor due dates; grade results and
//           provide students with feedback all in one place.
//         </p>
//         <p>
//           TOTC is a platform that allows educators to create online classes
//           whereby they can store the course materials online; manage
//           assignments, quizzes and exams; monitor due dates; grade results and
//           provide students with feedback all in one place.
//         </p>
//       </div>

//       {/* Content Section */}
//       <div className="content">
//         {/* Contact Card */}
//         <div className="contact-card">
//           <h2>Contact Us</h2>
//           <p>📧 info@learntplace.com</p>
//           <p>📞 +1 888 552 854</p>
//           <p>📍 John Smith 123 Main Street, Amsterdam, NH 1000 Netherlands</p>
//           <h3>Social</h3>
//           <p>🐦 @exampleAccount</p>
//           <p>📘 @exampleAccount</p>
//           <p>📹 @exampleAccount</p>
//           <p>📷 @exampleAccount</p>
//           <h3>Hours</h3>
//           <p>08:00 – 12:00 IST</p>
//           <p>13:00 – 17:00 IST</p>
//         </div>

//         {/* Map Section */}
//         <div className="map-container">
//           <iframe
//             title="Google Maps"
//             src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.8354345092947!2d144.95373531531846!3d-37.81720997975133!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0xf577f6b8c5c36a2!2sEnvato!5e0!3m2!1sen!2sus!4v1612309023106!5m2!1sen!2sus"
//             width="100%"
//             height="100%"
//             allowFullScreen=""
//             loading="lazy"
//             ></iframe>
//         </div>
//       </div>
//     </div>
//             </>
//   );
// };

// export default FinalRound;





import React from "react";
import Navbar from "../navbar/Navbar";
import './FinalRound.css'; // Import the CSS file

const FinalRoundPage = () => {
  return (
    <>
      <Navbar />
      <div className="container">
        {/* Header Section */}
        <div className="header">
          <h1 className="title">Final Round</h1>
          <p className="desc">
            The Final Round will test your skills in various aspects. Prepare well
            to succeed and demonstrate your excellence. Stay tuned for more
            updates regarding the schedule and venue.
          </p>
        </div>

        {/* Contact and Map Section */}
        <div className="contact-section">
          {/* Contact Us Box */}
          <div className="contact-box">
            <h2 className="contact-title">Contact Us</h2>
            <div className="contact-details">
              {/* Email */}
              <p className="contact-item">
                <i className="fas fa-envelope contact-icon"></i>
                <a href="mailto:info@ilearnplace.com" className="contact-link">
                  info@ilearnplace.com
                </a>
              </p>
              {/* Phone */}
              <p className="contact-item">
                <i className="fas fa-phone-alt contact-icon"></i>
                <a href="tel:+918094414990" className="contact-link">
                  +91 8094414990
                </a>
              </p>
              {/* Location */}
              <p className="contact-item">
                <i className="fas fa-map-marker-alt contact-icon"></i>
                <a
                  href="https://www.google.com/maps?q=John+Smith,+123+Main+Street,+Amsterdam,+NH+1000+Netherlands"
                  className="contact-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  John Smith, 123 Main Street, Amsterdam, NH 1000 Netherlands
                </a>
              </p>
            </div>

            <h3 className="social-title">Social</h3>
            <ul className="social-list">
              <li className="social-item">
                <i className="fab fa-twitter contact-icon"></i>
                <a
                  href="https://twitter.com/exampleAccount"
                  className="contact-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  @exampleAccount
                </a>
              </li>
              <li className="social-item">
                <i className="fab fa-facebook contact-icon"></i>
                <a
                  href="https://facebook.com/exampleAccount"
                  className="contact-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  @exampleAccount
                </a>
              </li>
              <li className="social-item">
                <i className="fab fa-youtube contact-icon"></i>
                <a
                  href="https://youtube.com/exampleAccount"
                  className="contact-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  @exampleAccount
                </a>
              </li>
              <li className="social-item">
                <i className="fab fa-instagram contact-icon"></i>
                <a
                  href="https://instagram.com/exampleAccount"
                  className="contact-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  @exampleAccount
                </a>
              </li>
              <li className="social-item">
                <i className="fab fa-tiktok contact-icon"></i>
                <a
                  href="https://tiktok.com/exampleAccount"
                  className="contact-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  @exampleAccount
                </a>
              </li>
            </ul>

            <h3 className="hours-title">Hours</h3>
            <p className="hours">
              08:00 - 12:00 IST<br />
              13:00 - 17:00 IST
            </p>
          </div>

          {/* Map Box */}
          <div className="map-box">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3891.494980583094!2d77.64066775000402!3d12.978120490814413!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae17bc0db0ff1d%3A0x8066f1e92d9e2d2b!2sK.R.Puram%20Bus%20Stop!5e0!3m2!1sen!2sin!4v1611290298376!5m2!1sen!2sin"
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              title="Map"
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
};

export default FinalRoundPage;
