// import React, { useState } from 'react';
// import { Box, Button, Typography, Grid, useMediaQuery } from '@mui/material';

// const VideoTabs = () => {
//   // State to manage selected tab
//   const [selectedTab, setSelectedTab] = useState('video');

//   // Media query for responsiveness
//   const isMobile = useMediaQuery('(max-width:600px)');

//   // Content for each section
//   const content = {
//     video: (
//       <Box sx={{ textAlign: 'center' }}>
//         <Typography variant="h6">Video Content</Typography>
//         <iframe
//           width="100%"
//           height={isMobile ? "200" : "400"}
//           src="https://www.youtube.com/embed/hRYZv1WRV80"
//           title="Video"
//           frameBorder="0"
//           allowFullScreen
//         ></iframe>
//       </Box>
//     ),
//     task: (
//       <Box sx={{ textAlign: 'center' }}>
//         <Typography variant="h6">Task Content</Typography>
//         <Typography variant="body1">This is the Task content description.</Typography>
//       </Box>
//     ),
//     concept: (
//       <Box sx={{ textAlign: 'center' }}>
//         <Typography variant="h6">Concept Content</Typography>
//         <Typography variant="body1">This is the Concept content description.</Typography>
//       </Box>
//     ),
//     submission: (
//       <Box sx={{ textAlign: 'center' }}>
//         <Typography variant="h6">Submission Content</Typography>
//         <Typography variant="body1">This is the Submission content description.</Typography>
//       </Box>
//     ),
//   };

//   return (
//     <Box sx={{ padding: '20px' }}>
//       {/* Tabs (Buttons for navigation) */}
//       <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
//         <Button
//           variant={selectedTab === 'video' ? 'contained' : 'outlined'}
//           onClick={() => setSelectedTab('video')}
//           sx={{ width: isMobile ? '100%' : 'auto', marginBottom: isMobile ? '10px' : '0' }}
//         >
//           Video
//         </Button>
//         <Button
//           variant={selectedTab === 'task' ? 'contained' : 'outlined'}
//           onClick={() => setSelectedTab('task')}
//           sx={{ width: isMobile ? '100%' : 'auto', marginBottom: isMobile ? '10px' : '0' }}
//         >
//           Task
//         </Button>
//         <Button
//           variant={selectedTab === 'concept' ? 'contained' : 'outlined'}
//           onClick={() => setSelectedTab('concept')}
//           sx={{ width: isMobile ? '100%' : 'auto', marginBottom: isMobile ? '10px' : '0' }}
//         >
//           Concept
//         </Button>
//         <Button
//           variant={selectedTab === 'submission' ? 'contained' : 'outlined'}
//           onClick={() => setSelectedTab('submission')}
//           sx={{ width: isMobile ? '100%' : 'auto', marginBottom: isMobile ? '10px' : '0' }}
//         >
//           Submission
//         </Button>
//       </Box>

//       {/* Content Display */}
//       <Grid container spacing={2}>
//         <Grid item xs={12}>
//           {/* Dynamically Render Content Based on Selected Tab */}
//           {content[selectedTab]}
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

// export default VideoTabs;


// import React, { useEffect } from "react";
// import { useParams } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { fetchTaskDetails } from "../../features/products/taskdetailsSlice";
// import { Box, Typography } from "@mui/material";

// const VideoTabs = () => {
//   const { taskCode } = useParams(); // Retrieve taskCode from URL
//   const dispatch = useDispatch();

//   const { task, loading, error } = useSelector((state) => state.taskDetails);
  

//   useEffect(() => {
//     if (taskCode) {
//       dispatch(fetchTaskDetails(taskCode)); // Dispatch action to fetch task details
//     }
//   }, [dispatch, taskCode]);
//   console.log(task)

//   if (loading) return <Typography>Loading...</Typography>;
//   if (error) return <Typography>Error: {error}</Typography>;

//   return (
//     <Box sx={{ padding: "20px" }}>
//       <Typography variant="h4">{task?.name}</Typography>
//       <Typography variant="body1">{task?.description}</Typography>
//       {/* Render task media */}
//       {task?.media?.map((media, index) => (
//         <Box key={index}>
//           <iframe
//             width="100%"
//             height="400"
//             src={media?.media_url}
//             title={media?.media_name}
//             frameBorder="0"
//             allowFullScreen
//           ></iframe>
//         </Box>
//       ))}
//     </Box>
//   );
// };

// export default VideoTabs;



// import React, { useEffect } from "react";
// import { useParams, useNavigate } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { fetchTaskDetails } from "../../features/products/taskdetailsSlice";
// import { Box, Typography } from "@mui/material";

// const VideoTabs = () => {
//   const { taskCode } = useParams(); // Retrieve taskCode from URL
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   const { task, loading, error } = useSelector((state) => state.taskDetails);

//   // Log the taskCode and task for debugging
//   useEffect(() => {
//     console.log("taskCode from URL:", taskCode); // Print taskCode
//     console.log("task details:", task); // Print task
//     if (taskCode) {
//       dispatch(fetchTaskDetails(taskCode)); // Dispatch action to fetch task details
//     } else {
//       // Redirect to the same page if taskCode is missing on refresh
//       navigate(`/task-details/${taskCode}`);
//     }
//   }, [dispatch, taskCode, navigate]);

//   useEffect(() => {
//     if (!task && !loading && taskCode) {
//       // Redirect to ensure the page with taskCode reloads correctly if task details are missing
//       console.log("Redirecting to /task-details/:taskCode because task is missing");
//       navigate(`/task-details/${taskCode}`);
//     }
//   }, [task, loading, taskCode, navigate]);

//   if (loading) return <Typography>Loading...</Typography>;
//   if (error) return <Typography>Error: {error}</Typography>;

//   return (
//     <Box sx={{ padding: "20px" }}>
//       <Typography variant="h4">{task?.name}</Typography>
//       <Typography variant="body1">{task?.description}</Typography>
//       {/* Render task media */}
//       {task?.media?.map((media, index) => (
//         <Box key={index}>
//           <iframe
//             width="100%"
//             height="400"
//             src={media?.media_url}
//             title={media?.media_name}
//             frameBorder="0"
//             allowFullScreen
//           ></iframe>
//         </Box>
//       ))}
//     </Box>
//   );
// };

// export default VideoTabs;



// import React, { useEffect } from "react";
// import { useParams, useNavigate } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { fetchTaskDetails } from "../../features/products/taskdetailsSlice";
// import { Box, Typography } from "@mui/material";
// import decryptURI from "../modifier/decryptURI";

// const VideoTabs = () => {
//   const { taskCode } = useParams(); // Retrieve taskCode from URL
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   console.log("taskCode from URL:", taskCode);
  

//   // Destructure from the Redux store, with default values
//   const { task = {}, loading, error } = useSelector((state) => state.taskDetails);
//   console.log("task details:", task);
//   // Log taskCode and task for debugging
//   useEffect(() => {
    
//     if (taskCode) {
//       dispatch(fetchTaskDetails(taskCode)); // Dispatch action to fetch task details
//     } else {
//       // Redirect to the same page if taskCode is missing on refresh
//       navigate(`/task-details/${taskCode}`);
//     }
//   }, [dispatch, taskCode, navigate]);

//   useEffect(() => {
//     if (!task && !loading && taskCode) {
//       // Redirect to ensure the page with taskCode reloads correctly if task details are missing
//       // console.log("Redirecting to /task-details/:taskCode because task is missing");
//       navigate(`/task-details/${taskCode}`);
//     }
//   }, [task, loading, taskCode, navigate]);
//   console.log("task_details",task.tasks[0].media[0].media_path)
//   if (loading) return <Typography>Loading...</Typography>;
//   if (error) return <Typography>Error: {error}</Typography>;

//   return (
//     <Box sx={{ padding: "20px" }}>
//       <Typography variant="h4">{task.tasks[0].task[0].task_code}</Typography>
//       <Typography variant="body1">{task?.description}</Typography>
//       {/* Render task media */}
//       {task.tasks[0].task[0].media?.map((media, index) => (
//         <Box key={index}>
//           <iframe
//             width="100%"
//             height="400"
//             src={decryptURI(media.media_path)}
//             title={media.media_type}
//             frameBorder="0"
//             allowFullScreen
//           ></iframe>
//         </Box>
//       ))}
//     </Box>
//   );
// };

// export default VideoTabs;



// import React, { useEffect, useState } from 'react';
// import { Box, Typography, Grid, useMediaQuery } from '@mui/material';
// import { useDispatch, useSelector } from 'react-redux';
// import { useParams, useNavigate } from 'react-router-dom';
// import { fetchTaskDetails } from '../../features/products/taskdetailsSlice'; // Import Redux action
// import decryptURI from "../modifier/decryptURI";
// import PDFViewer from "./pdfreader";
// import CardFlip from "./CardFlip";
// import VideoPage from "./VideoPage";
// import MediaUploadPage from "./videoupload";

// const VideoTabs = () => {
//   const { taskCode } = useParams(); // Retrieve taskCode from URL
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   const { task = {}, loading, error } = useSelector((state) => state.taskDetails);

//   const isMobile = useMediaQuery('(max-width:600px)');
//   const [selectedTab, setSelectedTab] = useState('video');

//   const content = {
//     video: (
//       <Box sx={{ textAlign: 'center' }}>
//         <VideoPage />
//       </Box>
//     ),
//     task: (
//       <Box sx={{ textAlign: 'center' }}>
//         <Typography variant="h6">Task Content</Typography>
//         <PDFViewer data={task} />
//       </Box>
//     ),
//     concept: (
//       <Box sx={{ textAlign: 'center' }}>
//         <Typography variant="h6">Concept Content</Typography>
//         <CardFlip data={task} />
//       </Box>
//     ),
//     submission: (
//       <Box sx={{ textAlign: 'center' }}>
//         {/* <Typography variant="h6">Submission Content</Typography> */}
//         <MediaUploadPage data={task} />
//       </Box>
//     ),
//   };

//   useEffect(() => {
//     if (taskCode) {
//       dispatch(fetchTaskDetails(taskCode));
//     } else {
//       navigate('/login');
//     }
//   }, [dispatch, taskCode, navigate]);

//   if (error) return <Typography>Error: {error}</Typography>;

//   return (
//     <Box sx={{ padding: '20px' }}>
//       {/* Tabs Section */}
//       <Box
//         sx={{
//           display: 'flex',
//           justifyContent: 'center', // Center horizontally
//           alignItems: 'center', // Center vertically (for alignment within the container)
//           gap: '40px', // Increase space between tabs
//           marginBottom: '20px',
//         }}
//       >
//         {['video', 'task', 'concept', 'submission'].map((tab) => (
//           <Typography
//             key={tab}
//             variant="h6" // Increase variant for larger text
//             onClick={() => setSelectedTab(tab)}
//             sx={{
//               cursor: 'pointer',
//               position: 'relative',
//               fontWeight: selectedTab === tab ? 'bold' : 'normal',
//               fontSize: '20px', // Increased font size
//               '&:after': {
//                 content: '""',
//                 position: 'absolute',
//                 bottom: -4, // Adjust spacing below the underline
//                 left: 0,
//                 right: 0,
//                 height: '3px', // Thicker underline
//                 backgroundColor: selectedTab === tab ? 'primary.main' : 'transparent',
//                 transition: 'background-color 0.3s',
//               },
//             }}
//           >
//             {tab.charAt(0).toUpperCase() + tab.slice(1)}
//           </Typography>
//         ))}
//       </Box>

//       {/* Content Section */}
//       <Grid container spacing={2}>
//         <Grid item xs={12}>
//           {content[selectedTab]}
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

// export default VideoTabs;


// import React, { useEffect, useState } from 'react';
// import { Box, Typography, Grid, useMediaQuery } from '@mui/material';
// import { useDispatch, useSelector } from 'react-redux';
// import { useParams, useNavigate } from 'react-router-dom';
// import { fetchTaskDetails } from '../../features/products/taskdetailsSlice'; // Import Redux action
// import decryptURI from "../modifier/decryptURI";
// import PDFViewer from "./pdfreader";
// import CardFlip from "./CardFlip";
// import VideoPage from "./VideoPage";
// import MediaUploadPage from "./videoupload";

// const VideoTabs = () => {
//   const { taskCode } = useParams(); // Retrieve taskCode from URL
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   const { task = {}, loading, error } = useSelector((state) => state.taskDetails);

//   const isMobile = useMediaQuery('(max-width:600px)');
//   const [selectedTab, setSelectedTab] = useState('Tutorial');

//   const content = {
//     Tutorial: (
//       <Box sx={{ textAlign: 'center' }}>
//         <VideoPage />
//       </Box>
//     ),
//     Guide: (
//       <Box sx={{ textAlign: 'center' }}>
//         <Typography variant="h6">Task Content</Typography>
//         <PDFViewer data={task} />
//       </Box>
//     ),
//     STEM: (
//       <Box sx={{ textAlign: 'center' }}>
//         <Typography variant="h6">Concept Content</Typography>
//         <CardFlip data={task} />
//       </Box>
//     ),
//     Submit: (
//       <Box sx={{ textAlign: 'center' }}>
//         <MediaUploadPage data={task} />
//       </Box>
//     ),
//   };

//   useEffect(() => {
//     if (taskCode) {
//       dispatch(fetchTaskDetails(taskCode));
//     } else {
//       navigate('/login');
//     }
//   }, [dispatch, taskCode, navigate]);

//   if (error) return <Typography>Error: {error}</Typography>;

//   return (
//     <Box sx={{ padding: '20px' }}>
//       {/* Tabs Section */}
//       <Box
//         sx={{
//           display: 'flex',
//           flexDirection: isMobile ? 'row' : 'row', // Stack buttons vertically in mobile view
//           justifyContent: 'center', // Center horizontally
//           alignItems: isMobile ? 'flex-start' : 'center', // Align buttons to the left in mobile view
//           gap: isMobile ? '15px' : '40px', // Add smaller gap in mobile view
//           marginBottom: '20px',
//         }}
//       >
//         {['Tutorial', 'Guide', 'STEM', 'Submit'].map((tab) => (
//           <Typography
//             key={tab}
//             variant="h6" // Increase variant for larger text
//             onClick={() => setSelectedTab(tab)}
//             sx={{
//               cursor: 'pointer',
//               position: 'relative',
//               fontWeight: selectedTab === tab ? 'bold' : 'normal',
//               fontSize: '20px', // Increased font size
//               textAlign: isMobile ? 'left' : 'center', // Align text to the left in mobile view
//               '&:after': {
//                 content: '""',
//                 position: 'absolute',
//                 bottom: -4, // Adjust spacing below the underline
//                 left: 0,
//                 right: 0,
//                 height: '3px', // Thicker underline
//                 backgroundColor: selectedTab === tab ? 'primary.main' : 'transparent',
//                 transition: 'background-color 0.3s',
//               },
//             }}
//           >
//             {tab.charAt(0).toUpperCase() + tab.slice(1)}
//           </Typography>
//         ))}
//       </Box>

//       {/* Content Section */}
//       <Grid container spacing={2}>
//         <Grid item xs={12}>
//           {content[selectedTab]}
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

// export default VideoTabs;



import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchTaskDetails } from '../../features/products/taskdetailsSlice'; 
import decryptURI from "../modifier/decryptURI";
import PDFViewer from "./pdfreader";
import CardFlip from "./CardFlip";
import VideoPage from "./VideoPage";
import MediaUploadPage from "./videoupload";

const VideoTabs = () => {
  const { taskCode } = useParams(); // Extract taskCode from URL
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { task = {}, loading, error } = useSelector((state) => state.taskDetails);
  const [selectedTab, setSelectedTab] = useState('Tutorial');
  const isMobile = useMediaQuery('(max-width:600px)');

  const content = {
    Tutorial: (
      <Box sx={{ textAlign: 'center' }}>
        <VideoPage data={task} />
      </Box>
    ),
    Guide: (
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h6">Task Content</Typography>
        <PDFViewer data={task} />
      </Box>
    ),
    STEM: (
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h6">Concept Content</Typography>
        <CardFlip data={task} />
      </Box>
    ),
    Submit: (
      <Box sx={{ textAlign: 'center' }}>
        <MediaUploadPage data={task} />
      </Box>
    ),
  };

  useEffect(() => {
    if (taskCode) {
      console.log("Fetching task details for:", taskCode); // Debugging log
      dispatch(fetchTaskDetails(taskCode));
    } else {
      console.error("No task code found. Redirecting to login.");
      navigate('/login');
    }
  }, [dispatch, taskCode, navigate]);

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography>Error: {error}</Typography>;

  return (
    <Box sx={{ padding: '20px' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'row' : 'row',
          justifyContent: 'center',
          alignItems: isMobile ? 'flex-start' : 'center',
          gap: isMobile ? '15px' : '40px',
          marginBottom: '20px',
        }}
      >
        {['Tutorial', 'Guide', 'STEM', 'Submit'].map((tab) => (
          <Typography
            key={tab}
            variant="h6"
            onClick={() => setSelectedTab(tab)}
            sx={{
              cursor: 'pointer',
              position: 'relative',
              fontWeight: selectedTab === tab ? 'bold' : 'normal',
              fontSize: '20px',
              textAlign: isMobile ? 'left' : 'center',
              '&:after': {
                content: '""',
                position: 'absolute',
                bottom: -4,
                left: 0,
                right: 0,
                height: '3px',
                backgroundColor: selectedTab === tab ? 'primary.main' : 'transparent',
                transition: 'background-color 0.3s',
              },
            }}
          >
            {tab}
          </Typography>
        ))}
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          {content[selectedTab]}
        </Grid>
      </Grid>
    </Box>
  );
};

export default VideoTabs;
