// import React, { useState, useEffect, useRef } from "react";
// import { Link } from "react-scroll";
// import { useLocation, useNavigate } from "react-router-dom";
// import logo from "../../assets/images/ilearnplace_logo.png";
// // import FunZoneButton from "../Buttons/FunZoneButton";
// // import LoginButton from "../Buttons/LoginButton";
// import "./Navbar.css";

// const Navbar = () => {
//   const [isMenuOpen, setIsMenuOpen] = useState(false);
//   const navMenuRef = useRef(null); // Ref for nav menu
//   const location = useLocation(); // Get the current route
//   const navigate = useNavigate(); // React Router's navigation hook

//   useEffect(() => {
//     const handleScroll = () => {
//       const navbar = document.querySelector(".navbar");
//       if (window.scrollY > 50) {
//         navbar.classList.add("scrolled");
//       } else {
//         navbar.classList.remove("scrolled");
//       }
//     };

//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (navMenuRef.current && !navMenuRef.current.contains(event.target)) {
//         setIsMenuOpen(false); // Close the menu if clicked outside
//       }
//     };

//     if (isMenuOpen) {
//       document.addEventListener("mousedown", handleClickOutside);
//     } else {
//       document.removeEventListener("mousedown", handleClickOutside);
//     }

//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [isMenuOpen]);

//   const handleLogoClick = () => {
//     navigate("/"); // Redirect to the home page
//   };

//   const toggleMenu = () => {
//     setIsMenuOpen(!isMenuOpen);
//   };

//   const closeMenu = () => {
//     setIsMenuOpen(false); // Ensure the menu closes
//   };

//   const isHomePage = location.pathname === "/"; // Check if the current route is home page

//   return (
//     <nav className="navbar">
//       <div className="navbar-left">
//         <img src={logo} alt="Logo" className="logo" onClick={handleLogoClick} />
//       </div>

//       <button className="menu-toggle" onClick={toggleMenu}>
//         ☰
//       </button>

//       <div
//         ref={navMenuRef}
//         className={`nav-menu ${isMenuOpen ? "show" : ""}`}
//       >
//         {isHomePage && ( // Conditionally render the navigation links
//           <ul className="nav-links">
//             <li>
//               <Link to="home" smooth={true} duration={500} onClick={closeMenu}>
//                 Home
//               </Link>
//             </li>
//             <li>
//               <Link to="games" smooth={true} duration={500} onClick={closeMenu}>
//                 Games
//               </Link>
//             </li>
//             <li>
//               <Link to="about" smooth={true} duration={500} onClick={closeMenu}>
//                 About Us
//               </Link>
//             </li>
//             <li>
//               <Link to="contact" smooth={true} duration={500} onClick={closeMenu}>
//                 Contact Us
//               </Link>
//             </li>
//           </ul>
//         )}

//         {/* Use Button Components */}
//         {/* <div className="navbar-buttons">
//           <FunZoneButton onClick={closeMenu} /> 
//           <LoginButton onClick={closeMenu} />
//         </div> */}
//       </div>
//     </nav>
//   );
// };

// export default Navbar;



import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/ilearnplace_logo.png";
import "./Navbar.css";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navMenuRef = useRef(null); // Ref for nav menu
  const location = useLocation(); // Get the current route
  const navigate = useNavigate(); // React Router's navigation hook

  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.querySelector(".navbar");
      if (window.scrollY > 50) {
        navbar.classList.add("scrolled");
      } else {
        navbar.classList.remove("scrolled");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navMenuRef.current && !navMenuRef.current.contains(event.target)) {
        setIsMenuOpen(false); // Close the menu if clicked outside
      }
    };

    if (isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);

  const handleLogoClick = () => {
    navigate("/"); // Redirect to the home page
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false); // Ensure the menu closes
  };

  return (
    <nav className="navbar">
      <div className="navbar-left">
        <img src={logo} alt="Logo" className="logo" onClick={handleLogoClick} />
      </div>

      <button className="menu-toggle" onClick={toggleMenu}>
        ☰
      </button>

      <div
        ref={navMenuRef}
        className={`nav-menu ${isMenuOpen ? "show" : ""}`}
      >
        <ul className="nav-links">
          <li>
            <Link to="/ispl" onClick={closeMenu}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/ispl/register" onClick={closeMenu}>
              Register
            </Link>
          </li>
          <li>
            <Link to="/ispl/round1" onClick={closeMenu}>
              Round 1
            </Link>
          </li>
          <li>
            <Link to="/ispl/round2" onClick={closeMenu}>
              Round 2
            </Link>
          </li>
          <li>
            <Link to="/ispl/gamerule" onClick={closeMenu}>
              Game Rule
            </Link>
          </li>
          <li>
            <Link to="/ispl/sponsor" onClick={closeMenu}>
              Sponsor
            </Link>
          </li>
          <li>
            <Link to="/ispl/contat" onClick={closeMenu}>
              Contact Us
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
