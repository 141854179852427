// import React, { useEffect, useState } from "react";
// import { Box, Grid, Typography, useMediaQuery } from "@mui/material";

// // Default videos to show if no data is fetched
// const defaultVideos = [
//   { id: 1, title: "Intro to Science", url: "https://www.youtube.com/embed/hRYZv1WRV80" },
//   { id: 2, title: "Physics 101", url: "https://www.youtube.com/embed/LXb3EKWsInQ" },
//   { id: 3, title: "Chemistry Basics", url: "https://www.youtube.com/embed/dQw4w9WgXcQ" },
// ];

// const VideoPage = ({ data }) => {
//   const [videos, setVideos] = useState([]);
//   const isMobile = useMediaQuery("(max-width:600px)");

//   useEffect(() => {
//     // If data is passed, use it; otherwise, use default videos
//     if (data && data.length > 0) {
//       setVideos(data);
//     } else {
//       setVideos(defaultVideos);
//     }
//   }, [data]);

//   return (
//     <Box sx={{ padding: "20px" }}>
//       <Typography variant="h4" sx={{ marginBottom: "20px" }}>
//         Featured Videos
//       </Typography>

//       <Grid container spacing={3}>
//         {videos.map((video) => (
//           <Grid item xs={12} sm={6} md={4} key={video.id}>
//             <Box sx={{ textAlign: "center" }}>
//               <iframe
//                 width="100%"
//                 height={isMobile ? "200" : "400"}
//                 src={video.url}
//                 title={video.title}
//                 frameBorder="0"
//                 allowFullScreen
//               ></iframe>
//               <Typography variant="h6" sx={{ marginTop: "10px" }}>
//                 {video.title}
//               </Typography>
//             </Box>
//           </Grid>
//         ))}
//       </Grid>
//     </Box>
//   );
// };

// export default VideoPage;






// import React, { useEffect, useState } from "react";
// import { Box, Grid, Typography, useMediaQuery } from "@mui/material";

// // Default videos to show if no data is fetched
// const defaultVideos = [
//   { id: 1, title: "Intro to Science", url: "https://www.youtube.com/embed/hRYZv1WRV80" },
//   { id: 2, title: "Physics 101", url: "https://www.youtube.com/embed/LXb3EKWsInQ" },
//   { id: 3, title: "Chemistry Basics", url: "https://www.youtube.com/embed/dQw4w9WgXcQ" },
// ];

// const VideoPage = ({ data }) => {
//   const [videos, setVideos] = useState([]);
//   const [mainVideo, setMainVideo] = useState(null); // Track the current main video
//   const isMobile = useMediaQuery("(max-width:600px)");

//   useEffect(() => {
//     // If data is passed, use it; otherwise, use default videos
//     setVideos(data && data.length > 0 ? data : defaultVideos);
//   }, [data]);

//   // Set the first video as the default main video
//   useEffect(() => {
//     if (!mainVideo && videos.length > 0) {
//       setMainVideo(videos[0]);
//     }
//   }, [videos, mainVideo]);

//   const handleVideoClick = (video) => {
//     setMainVideo(video); // Update the main video on click
//   };

//   const getThumbnailUrl = (videoUrl) => {
//     // Extract video ID from the YouTube URL
//     const videoId = videoUrl.split("/embed/")[1];
//     return `https://img.youtube.com/vi/${videoId}/0.jpg`; // Get YouTube thumbnail image
//   };

//   return (
//     <Box sx={{ padding: "20px" }}>
//       <Typography variant="h4" sx={{ marginBottom: "20px", textAlign: "center" }}>
//         Featured Videos
//       </Typography>

//       <Grid container spacing={3}>
//         {/* Main Video on the left side */}
//         <Grid item xs={12} md={8}>
//           <Box sx={{ textAlign: "center", marginBottom: "20px" }}>
//             {mainVideo && (
//               <iframe
//                 width="100%"
//                 height={isMobile ? "300" : "450"}
//                 src={mainVideo.url}
//                 title={mainVideo.title}
//                 frameBorder="0"
//                 allowFullScreen
//               ></iframe>
//             )}
//             <Typography variant="h6" sx={{ marginTop: "10px" }}>
//               {mainVideo?.title}
//             </Typography>
//           </Box>
//         </Grid>

//         {/* Thumbnail Videos on the right side */}
//         <Grid item xs={12} md={4}>
//           <Box sx={{ maxHeight: "600px", overflowY: "auto" }}>
//             {videos.map((video) => (
//               <Box
//                 key={video.id}
//                 sx={{
//                   display: "flex",
//                   alignItems: "center",
//                   gap: "10px",
//                   marginBottom: "15px",
//                   cursor: "pointer",
//                 }}
//                 onClick={() => handleVideoClick(video)}
//               >
//                 <Box
//                   component="img"
//                   src={getThumbnailUrl(video.url)}
//                   alt={video.title}
//                   sx={{
//                     width: isMobile ? "150px" : "120px",
//                     height: "auto",
//                     borderRadius: "4px",
//                   }}
//                 />
//                 <Typography
//                   variant="body2"
//                   sx={{
//                     maxWidth: "400px",
//                     textAlign: "left",
//                     lineHeight: 1.5,
//                   }}
//                 >
//                   {video.title}
//                 </Typography>
//               </Box>
//             ))}
//           </Box>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

// export default VideoPage;



// import React, { useEffect, useState, useRef } from "react";
// import { Box, Grid, Typography, useMediaQuery } from "@mui/material";

// // Default videos to show if no data is fetched
// const defaultVideos = [
//   { id: 1, title: "Intro to Science", url: "https://www.youtube.com/embed/hRYZv1WRV80" },
//   { id: 2, title: "Physics 101", url: "https://www.youtube.com/embed/LXb3EKWsInQ" },
//   { id: 3, title: "Chemistry Basics", url: "https://www.youtube.com/embed/dQw4w9WgXcQ" },
// ];

// const VideoPage = ({ data }) => {
//   const [videos, setVideos] = useState([]);
//   const [mainVideo, setMainVideo] = useState(null); // Track the current main video
//   const isMobile = useMediaQuery("(max-width:600px)");
//   const videoPlayerRef = useRef(null); // Ref to track the video player

//   useEffect(() => {
//     // If data is passed, use it; otherwise, use default videos
//     setVideos(data && data.length > 0 ? data : defaultVideos);
//   }, [data]);

//   // Set the first video as the default main video
//   useEffect(() => {
//     if (!mainVideo && videos.length > 0) {
//       setMainVideo(videos[0]);
//     }
//   }, [videos, mainVideo]);

//   const handleVideoClick = (video) => {
//     setMainVideo(video); // Update the main video on click

//     // Scroll to the video player in mobile view
//     if (isMobile && videoPlayerRef.current) {
//       videoPlayerRef.current.scrollIntoView({ behavior: "smooth", block:"start" });
//     }
//   };

//   const getThumbnailUrl = (videoUrl) => {
//     // Extract video ID from the YouTube URL
//     const videoId = videoUrl.split("/embed/")[1];
//     return `https://img.youtube.com/vi/${videoId}/0.jpg`; // Get YouTube thumbnail image
//   };

//   return (
//     <Box sx={{ padding: "20px" }}>
//       <Typography variant="h4" sx={{ marginBottom: "20px", textAlign: "center" }}>
//         Featured Videos
//       </Typography>

//       <Grid container spacing={3}>
//         {/* Main Video on the left side */}
//         <Grid item xs={12} md={8}>
//           <Box ref={videoPlayerRef} sx={{ textAlign: "center", marginBottom: "20px" }}>
//             {mainVideo && (
//               <iframe
//                 width="100%"
//                 height={isMobile ? "300" : "450"}
//                 src={mainVideo.url}
//                 title={mainVideo.title}
//                 frameBorder="0"
//                 allowFullScreen
//               ></iframe>
//             )}
//             <Typography variant="h6" sx={{ marginTop: "10px" }}>
//               {mainVideo?.title}
//             </Typography>
//           </Box>
//         </Grid>

//         {/* Thumbnail Videos on the right side */}
//         <Grid item xs={12} md={4}>
//           <Box sx={{ maxHeight: "600px", overflowY: "auto" }}>
//             {videos.map((video) => (
//               <Box
//                 key={video.id}
//                 sx={{
//                   display: "flex",
//                   alignItems: "center",
//                   gap: "10px",
//                   marginBottom: "15px",
//                   cursor: "pointer",
//                 }}
//                 onClick={() => handleVideoClick(video)}
//               >
//                 <Box
//                   component="img"
//                   src={getThumbnailUrl(video.url)}
//                   alt={video.title}
//                   sx={{
//                     width: isMobile ? "150px" : "120px",
//                     height: "auto",
//                     borderRadius: "4px",
//                   }}
//                 />
//                 <Typography
//                   variant="body2"
//                   sx={{
//                     maxWidth: "400px",
//                     textAlign: "left",
//                     lineHeight: 1.5,
//                   }}
//                 >
//                   {video.title}
//                 </Typography>
//               </Box>
//             ))}
//           </Box>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

// export default VideoPage;



// import React, { useEffect, useState, useRef } from "react";
// import { Box, Grid, Typography, useMediaQuery } from "@mui/material";

// // Default videos to show if no data is fetched
// const defaultVideos = [
//   { id: 1, title: "Unbox & Build the Ultimate ILP STEM Military Jeep – Begin Your Gamified Adventure!", url: "https://www.youtube.com/embed/FbQDlI0P5_U?si=pq--SwBo7L2fpCMP" },
//   { id: 2, title: "Takshak Battle Field Level-1 Kit Assembly | Robo Car Kit Assembly", url: "https://www.youtube.com/embed/FOrE6sohnDo?si=nZIvFcjcxpWJeLdr" },
//   { id: 3, title: "Shining house assembling", url: "https://www.youtube.com/embed/KHi6X_Srplc?si=UKBDyGCx4xwgYsPR" },
//   { id: 3, title: "Totoka Smart City Level-2 | Task-1 | Water Tank Tower Assembly", url: "https://www.youtube.com/embed/RCvy4CYqJ3I?si=XeHBP3XTFTl5BOS2" },
//   { id: 3, title: "Smart Railway Crossing | Advance Smart City Classical Train Set Review", url: "https://www.youtube.com/embed/sOOysD0sz9E?si=5z6J05uKFlMQcsgU" },
//   { id: 3, title: "STEM Learning With The Advance Military Jeep For Kids || STEM Robot Kit", url: "https://www.youtube.com/embed/FJJQt-V0cD8?si=sfUI0pWm1CF_50Rj" },
// ];

// const VideoPage = ({ data }) => {
//   //console.log("chikun",{data})
//   const [videos, setVideos] = useState([]);
//   const [mainVideo, setMainVideo] = useState(null); // Track the current main video
//   const isMobile = useMediaQuery("(max-width:600px)");
//   const videoPlayerRef = useRef(null); // Ref to track the video player
//   const navbarHeight = 70; // Set the approximate height of your navbar

//   useEffect(() => {
//     // If data is passed, use it; otherwise, use default videos
//     setVideos(data && data.length > 0 ? data : defaultVideos);
//   }, [data]);

//   // Set the first video as the default main video
//   useEffect(() => {
//     if (!mainVideo && videos.length > 0) {
//       setMainVideo(videos[0]);
//     }
//   }, [videos, mainVideo]);

//   const handleVideoClick = (video) => {
//     setMainVideo(video); // Update the main video on click

//     // Scroll to the video player with offset for the navbar
//     if (videoPlayerRef.current) {
//       const elementPosition = videoPlayerRef.current.getBoundingClientRect().top + window.scrollY;
//       const offsetPosition = elementPosition - navbarHeight; // Adjust by navbar height
//       window.scrollTo({
//         top: offsetPosition,
//         behavior: "smooth",
//       });
//     }
//   };

//   const getThumbnailUrl = (videoUrl) => {
//     // Extract video ID from the YouTube URL, ensuring to handle query strings or additional parameters
//     const videoIdMatch = videoUrl.match(/\/embed\/([^?]+)/);
//     const videoId = videoIdMatch ? videoIdMatch[1] : null;
  
//     if (videoId) {
//       return `https://img.youtube.com/vi/${videoId}/0.jpg`; // Construct YouTube thumbnail URL
//     } else {
//       console.error("Invalid YouTube URL provided.");
//       return null; // Return null if video ID extraction fails
//     }
//   };
  
//   return (
//     <Box sx={{ padding: "20px" }}>
//       <Typography variant="h4" sx={{ marginBottom: "20px", textAlign: "center" }}>
//       Interactive Video Series
//       </Typography>

//       <Grid container spacing={3}>
//         {/* Main Video on the left side */}
//         <Grid item xs={12} md={8}>
//           <Box ref={videoPlayerRef} sx={{ textAlign: "center", marginBottom: "20px" }}>
//             {mainVideo && (
//               <iframe
//                 width="100%"
//                 height={isMobile ? "300" : "450"}
//                 src={mainVideo.url}
//                 title={mainVideo.title}
//                 frameBorder="0"
//                 allowFullScreen
//               ></iframe>
//             )}
//             <Typography variant="h6" sx={{ marginTop: "10px" }}>
//               {mainVideo?.title}
//             </Typography>
//           </Box>
//         </Grid>

//         {/* Thumbnail Videos on the right side */}
//         <Grid item xs={12} md={4}>
//           <Box sx={{ maxHeight: "600px", overflowY: "auto" }}>
//             {videos.map((video) => (
//               <Box
//                 key={video.id}
//                 sx={{
//                   display: "flex",
//                   alignItems: "center",
//                   gap: "10px",
//                   marginBottom: "15px",
//                   cursor: "pointer",
//                 }}
//                 onClick={() => handleVideoClick(video)}
//               >
//                 <Box
//                   component="img"
//                   src={getThumbnailUrl(video.url)}
//                   alt={video.title}
//                   sx={{
//                     width: isMobile ? "150px" : "120px",
//                     height: "auto",
//                     borderRadius: "4px",
//                   }}
//                 />
//                 <Typography
//                   variant="body2"
//                   sx={{
//                     maxWidth: "400px",
//                     textAlign: "left",
//                     lineHeight: 1.5,
//                   }}
//                 >
//                   {video.title}
//                 </Typography>
//               </Box>
//             ))}
//           </Box>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

// export default VideoPage;





// import React, { useEffect, useState, useRef } from "react";
// import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
// import decryptURI from "../modifier/decryptURI";

// // Default videos to show if no data is fetched or valid
// const defaultVideos = [
//   { id: 1, title: "Unbox & Build the Ultimate ILP STEM Military Jeep – Begin Your Gamified Adventure!", url: "https://www.youtube.com/embed/FbQDlI0P5_U?si=pq--SwBo7L2fpCMP" },
//   { id: 2, title: "Takshak Battle Field Level-1 Kit Assembly | Robo Car Kit Assembly", url: "https://www.youtube.com/embed/FOrE6sohnDo?si=nZIvFcjcxpWJeLdr" },
//   { id: 3, title: "Shining house assembling", url: "https://www.youtube.com/embed/KHi6X_Srplc?si=UKBDyGCx4xwgYsPR" },
//   { id: 4, title: "Totoka Smart City Level-2 | Task-1 | Water Tank Tower Assembly", url: "https://www.youtube.com/embed/RCvy4CYqJ3I?si=XeHBP3XTFTl5BOS2" },
//   { id: 5, title: "Smart Railway Crossing | Advance Smart City Classical Train Set Review", url: "https://www.youtube.com/embed/sOOysD0sz9E?si=5z6J05uKFlMQcsgU" },
//   { id: 6, title: "STEM Learning With The Advance Military Jeep For Kids || STEM Robot Kit", url: "https://www.youtube.com/embed/FJJQt-V0cD8?si=sfUI0pWm1CF_50Rj" },
// ];

// const VideoPage = ({ data }) => {
//   const [videos, setVideos] = useState([]);
//   //console.log("ddd",videos)
//   const [mainVideo, setMainVideo] = useState(null); // Track the current main video
//   const isMobile = useMediaQuery("(max-width:600px)");
//   const videoPlayerRef = useRef(null); // Ref to track the video player
//   const navbarHeight = 70; // Set the approximate height of your navbar

//   useEffect(() => {
//     //console.log("Step 1: useEffect triggered due to 'data' change");
  
//     if (data && data.tasks && data.tasks.length > 0) {
//       //console.log("Step 2: 'data' is valid and contains tasks");
  
//       const videoData = data.tasks[0].media;
//       //console.log("Step 3: Media extracted from the first task", videoData);
  
//       // Filter and process only valid YouTube embed links
//       const filteredVideos = videoData
//         .filter((mediaItem) => {
//           const isValidVideo = mediaItem.media_type === "video" && decryptURI(mediaItem.media_path).includes("youtube.com/embed/");
//           //console.log(`Checking media item: ${mediaItem.media_title} - Valid: ${isValidVideo}`);
//           return isValidVideo;
//         })
//         .map((mediaItem) => ({
//           id: mediaItem.id,
//           title: mediaItem.media_title,
//           url: decryptURI(mediaItem.media_path), // Directly use embed link
//         }));
  
//       //console.log("Step 4: Filtered videos after validation", filteredVideos);
  
//       if (filteredVideos.length > 0) {
//         //console.log("Step 5: Setting valid videos to state");
//         setVideos(filteredVideos);
//       } else {
//         console.warn("Step 6: No valid videos found, falling back to default videos");
//         setVideos(defaultVideos);
//       }
//     } else {
//       console.warn("Step 7: 'data' is invalid or has no tasks, falling back to default videos");
//       setVideos(defaultVideos);
//     }
//   }, [data]);
  
  
  

//   // Set the first video as the default main video
//   useEffect(() => {
//     if (!mainVideo && videos.length > 0) {
//       setMainVideo(videos[0]);
//     }
//   }, [videos, mainVideo]);

//   const handleVideoClick = (video) => {
//     setMainVideo(video); // Update the main video on click

//     // Scroll to the video player with offset for the navbar
//     if (videoPlayerRef.current) {
//       const elementPosition = videoPlayerRef.current.getBoundingClientRect().top + window.scrollY;
//       const offsetPosition = elementPosition - navbarHeight; // Adjust by navbar height
//       window.scrollTo({
//         top: offsetPosition,
//         behavior: "smooth",
//       });
//     }
//   };

//   const getThumbnailUrl = (videoUrl) => {
//     // Extract video ID from the YouTube URL, ensuring to handle query strings or additional parameters
//     const videoIdMatch = videoUrl.match(/\/embed\/([^?]+)/);
//     const videoId = videoIdMatch ? videoIdMatch[1] : null;

//     if (videoId) {
//       return `https://img.youtube.com/vi/${videoId}/0.jpg`; // Construct YouTube thumbnail URL
//     } else {
//       console.error("Invalid YouTube URL provided.");
//       return null; // Return null if video ID extraction fails
//     }
//   };

//   const isValidUrl = (url) => {
//     try {
//       new URL(url); // Check if the URL is valid
//       return true;
//     } catch {
//       return false;
//     }
//   };

//   return (
//     <Box sx={{ padding: "20px" }}>
//       <Typography variant="h4" sx={{ marginBottom: "20px", textAlign: "center" }}>
//         Interactive Video Series
//       </Typography>

//       <Grid container spacing={3}>
//         {/* Main Video on the left side */}
//         <Grid item xs={12} md={8}>
//           <Box ref={videoPlayerRef} sx={{ textAlign: "center", marginBottom: "20px" }}>
//             {mainVideo && (
//               <iframe
//                 width="100%"
//                 height={isMobile ? "300" : "450"}
//                 src={mainVideo.url}
//                 title={mainVideo.title}
//                 frameBorder="0"
//                 allowFullScreen
//               ></iframe>
//             )}
//             <Typography variant="h6" sx={{ marginTop: "10px" }}>
//               {mainVideo?.title}
//             </Typography>
//           </Box>
//         </Grid>

//         {/* Thumbnail Videos on the right side */}
//         <Grid item xs={12} md={4}>
//           <Box sx={{ maxHeight: "600px", overflowY: "auto" }}>
//             {videos.map((video) => (
//               <Box
//                 key={video.id}
//                 sx={{
//                   display: "flex",
//                   alignItems: "center",
//                   gap: "10px",
//                   marginBottom: "15px",
//                   cursor: "pointer",
//                 }}
//                 onClick={() => handleVideoClick(video)}
//               >
//                 <Box
//                   component="img"
//                   src={getThumbnailUrl(video.url)}
//                   alt={video.title}
//                   sx={{
//                     width: isMobile ? "150px" : "120px",
//                     height: "auto",
//                     borderRadius: "4px",
//                   }}
//                 />
//                 <Typography variant="body2" sx={{ maxWidth: "400px", textAlign: "left", lineHeight: 1.5 }}>
//                   {video.title}
//                 </Typography>
//               </Box>
//             ))}
//           </Box>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

// export default VideoPage;







// import React, { useEffect, useState, useRef } from "react";
// import { Box, Grid, Typography, useMediaQuery, ToggleButtonGroup, ToggleButton } from "@mui/material";
// import decryptURI from "../modifier/decryptURI";

// // Default videos to show if no data is fetched or valid
// const defaultVideos = [
//   { id: 1, title: "Unbox & Build the Ultimate ILP STEM Military Jeep – Begin Your Gamified Adventure!", url: "https://www.youtube.com/embed/FbQDlI0P5_U?si=pq--SwBo7L2fpCMP" },
//   { id: 2, title: "Takshak Battle Field Level-1 Kit Assembly | Robo Car Kit Assembly", url: "https://www.youtube.com/embed/FOrE6sohnDo?si=nZIvFcjcxpWJeLdr" },
//   { id: 3, title: "Shining house assembling", url: "https://www.youtube.com/embed/KHi6X_Srplc?si=UKBDyGCx4xwgYsPR" },
//   { id: 4, title: "Totoka Smart City Level-2 | Task-1 | Water Tank Tower Assembly", url: "https://www.youtube.com/embed/RCvy4CYqJ3I?si=XeHBP3XTFTl5BOS2" },
//   { id: 5, title: "Smart Railway Crossing | Advance Smart City Classical Train Set Review", url: "https://www.youtube.com/embed/sOOysD0sz9E?si=5z6J05uKFlMQcsgU" },
//   { id: 6, title: "STEM Learning With The Advance Military Jeep For Kids || STEM Robot Kit", url: "https://www.youtube.com/embed/FJJQt-V0cD8?si=sfUI0pWm1CF_50Rj" },
// ];

// const VideoPage = ({ data }) => {
//   const [videos, setVideos] = useState([]);
//   const [mainVideo, setMainVideo] = useState(null); // Track the current main video
//   const [language, setLanguage] = useState("english"); // Track the selected language
//   const isMobile = useMediaQuery("(max-width:600px)");
//   const videoPlayerRef = useRef(null); // Ref to track the video player
//   const navbarHeight = 70; // Set the approximate height of your navbar

//   useEffect(() => {
//     if (data && data.tasks && data.tasks.length > 0) {
//       const videoData = data.tasks[0].media;
//       const filteredVideos = videoData
//         .filter((mediaItem) => {
//           const isValidVideo = mediaItem.media_type === "video" && decryptURI(mediaItem.media_path).includes("youtube.com/embed/");
//           return isValidVideo;
//         })
//         .map((mediaItem) => ({
//           id: mediaItem.id,
//           title: mediaItem.media_title,
//           url: decryptURI(mediaItem.media_path), // Directly use embed link
//         }));

//       if (filteredVideos.length > 0) {
//         setVideos(filteredVideos);
//       } else {
//         setVideos(defaultVideos);
//       }
//     } else {
//       setVideos(defaultVideos);
//     }
//   }, [data]);

//   useEffect(() => {
//     if (!mainVideo && videos.length > 0) {
//       setMainVideo(videos[0]);
//     }
//   }, [videos, mainVideo]);

//   const handleVideoClick = (video) => {
//     setMainVideo(video);

//     if (videoPlayerRef.current) {
//       const elementPosition = videoPlayerRef.current.getBoundingClientRect().top + window.scrollY;
//       const offsetPosition = elementPosition - navbarHeight;
//       window.scrollTo({
//         top: offsetPosition,
//         behavior: "smooth",
//       });
//     }
//   };

//   const getThumbnailUrl = (videoUrl) => {
//     const videoIdMatch = videoUrl.match(/\/embed\/([^?]+)/);
//     const videoId = videoIdMatch ? videoIdMatch[1] : null;

//     if (videoId) {
//       return `https://img.youtube.com/vi/${videoId}/0.jpg`;
//     } else {
//       return null;
//     }
//   };

//   const handleLanguageChange = (event, newLanguage) => {
//     if (newLanguage !== null) {
//       setLanguage(newLanguage);
//     }
//   };

//   return (
//     <Box sx={{ padding: "20px" }}>
//       <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
//         <Typography variant="h4">Interactive Video Series</Typography>
//         <ToggleButtonGroup
//           value={language}
//           exclusive
//           onChange={handleLanguageChange}
//           sx={{ backgroundColor: "#f5f5f5", borderRadius: "8px" }}
//         >
//           <ToggleButton value="hindi" sx={{ textTransform: "none" }}>
//             Hindi
//           </ToggleButton>
//           <ToggleButton value="english" sx={{ textTransform: "none" }}>
//             English
//           </ToggleButton>
//         </ToggleButtonGroup>
//       </Box>

//       <Grid container spacing={3}>
//         <Grid item xs={12} md={8}>
//           <Box ref={videoPlayerRef} sx={{ textAlign: "center", marginBottom: "20px" }}>
//             {mainVideo && (
//               <iframe
//                 width="100%"
//                 height={isMobile ? "300" : "450"}
//                 src={mainVideo.url}
//                 title={mainVideo.title}
//                 frameBorder="0"
//                 allowFullScreen
//               ></iframe>
//             )}
//             <Typography variant="h6" sx={{ marginTop: "10px" }}>
//               {mainVideo?.title}
//             </Typography>
//           </Box>
//         </Grid>

//         <Grid item xs={12} md={4}>
//           <Box sx={{ maxHeight: "600px", overflowY: "auto" }}>
//             {videos.map((video) => (
//               <Box
//                 key={video.id}
//                 sx={{
//                   display: "flex",
//                   alignItems: "center",
//                   gap: "10px",
//                   marginBottom: "15px",
//                   cursor: "pointer",
//                 }}
//                 onClick={() => handleVideoClick(video)}
//               >
//                 <Box
//                   component="img"
//                   src={getThumbnailUrl(video.url)}
//                   alt={video.title}
//                   sx={{
//                     width: isMobile ? "150px" : "120px",
//                     height: "auto",
//                     borderRadius: "4px",
//                   }}
//                 />
//                 <Typography variant="body2" sx={{ maxWidth: "400px", textAlign: "left", lineHeight: 1.5 }}>
//                   {video.title}
//                 </Typography>
//               </Box>
//             ))}
//           </Box>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

// export default VideoPage;







import React, { useEffect, useState, useRef } from "react";
import { Box, Grid, Typography, useMediaQuery, ToggleButtonGroup, ToggleButton } from "@mui/material";
import decryptURI from "../modifier/decryptURI";

// Default videos to show if no data is fetched or valid
const defaultVideos = [
  { id: 1, title: "Unbox & Build the Ultimate ILP STEM Military Jeep – Begin Your Gamified Adventure!", url: "https://www.youtube.com/embed/FbQDlI0P5_U?si=pq--SwBo7L2fpCMP", media_language: "all" },
  { id: 2, title: "Takshak Battle Field Level-1 Kit Assembly | Robo Car Kit Assembly", url: "https://www.youtube.com/embed/FOrE6sohnDo?si=nZIvFcjcxpWJeLdr", media_language: "hindi" },
  { id: 3, title: "Shining house assembling", url: "https://www.youtube.com/embed/KHi6X_Srplc?si=UKBDyGCx4xwgYsPR", media_language: "english" },
  { id: 4, title: "Totoka Smart City Level-2 | Task-1 | Water Tank Tower Assembly", url: "https://www.youtube.com/embed/RCvy4CYqJ3I?si=XeHBP3XTFTl5BOS2", media_language: "all" },
  { id: 5, title: "Smart Railway Crossing | Advance Smart City Classical Train Set Review", url: "https://www.youtube.com/embed/sOOysD0sz9E?si=5z6J05uKFlMQcsgU", media_language: "english" },
  { id: 6, title: "STEM Learning With The Advance Military Jeep For Kids || STEM Robot Kit", url: "https://www.youtube.com/embed/FJJQt-V0cD8?si=sfUI0pWm1CF_50Rj", media_language: "hindi" },
];

const VideoPage = ({ data }) => {
  const [videos, setVideos] = useState([]);
  const [filteredVideos, setFilteredVideos] = useState([]); // Filtered videos based on language
  const [mainVideo, setMainVideo] = useState(null); // Track the current main video
  const [language, setLanguage] = useState("hindi"); // Default language is "Hindi"
  const isMobile = useMediaQuery("(max-width:600px)");
  const videoPlayerRef = useRef(null); // Ref to track the video player
  const navbarHeight = 70; // Set the approximate height of your navbar

  useEffect(() => {
    if (data && data.tasks && data.tasks.length > 0) {
      const videoData = data.tasks[0].media;
      const filteredVideos = videoData
        .filter((mediaItem) => {
          const isValidVideo = mediaItem.media_type === "video" && decryptURI(mediaItem.media_path).includes("youtube.com/embed/");
          return isValidVideo;
        })
        .map((mediaItem) => ({
          id: mediaItem.id,
          title: mediaItem.media_title,
          url: decryptURI(mediaItem.media_path),
          media_language: mediaItem.media_language || "all", // Default to "all" if no language is set
        }));

      if (filteredVideos.length > 0) {
        setVideos(filteredVideos);
      } else {
        setVideos(defaultVideos);
      }
    } else {
      setVideos(defaultVideos);
    }
  }, [data]);

  useEffect(() => {
    // Filter videos based on selected language
    const filtered = videos.filter((video) => video.media_language === language || video.media_language === "all");
    console.log(filtered)
    setFilteredVideos(filtered);

    // Set the first video in the filtered list as the main video
    if (filtered.length > 0) {
      setMainVideo(filtered[0]);
    }
  }, [videos, language]);

  const handleVideoClick = (video) => {
    setMainVideo(video);

    if (videoPlayerRef.current) {
      const elementPosition = videoPlayerRef.current.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - navbarHeight;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  const getThumbnailUrl = (videoUrl) => {
    const videoIdMatch = videoUrl.match(/\/embed\/([^?]+)/);
    const videoId = videoIdMatch ? videoIdMatch[1] : null;

    if (videoId) {
      return `https://img.youtube.com/vi/${videoId}/0.jpg`;
    } else {
      return null;
    }
  };

  const handleLanguageChange = (event, newLanguage) => {
    if (newLanguage !== null) {
      setLanguage(newLanguage);
    }
  };

  return (
    <Box sx={{ padding: "20px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
        <Typography variant="h4">Interactive Video Series</Typography>
        <ToggleButtonGroup
          value={language}
          exclusive
          onChange={handleLanguageChange}
          sx={{ backgroundColor: "#f5f5f5", borderRadius: "8px" }}
        >
          <ToggleButton value="hindi" sx={{ textTransform: "none" }}>
            Hindi
          </ToggleButton>
          <ToggleButton value="english" sx={{ textTransform: "none" }}>
            English
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Box ref={videoPlayerRef} sx={{ textAlign: "center", marginBottom: "20px" }}>
            {mainVideo && (
              <iframe
                width="100%"
                height={isMobile ? "300" : "450"}
                src={mainVideo.url}
                title={mainVideo.title}
                frameBorder="0"
                allowFullScreen
              ></iframe>
            )}
            <Typography variant="h6" sx={{ marginTop: "10px" }}>
              {mainVideo?.title}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box sx={{ maxHeight: "600px", overflowY: "auto" }}>
            {filteredVideos.map((video) => (
              <Box
                key={video.id}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  marginBottom: "15px",
                  cursor: "pointer",
                }}
                onClick={() => handleVideoClick(video)}
              >
                <Box
                  component="img"
                  src={getThumbnailUrl(video.url)}
                  alt={video.title}
                  sx={{
                    width: isMobile ? "150px" : "120px",
                    height: "auto",
                    borderRadius: "4px",
                  }}
                />
                <Typography variant="body2" sx={{ maxWidth: "400px", textAlign: "left", lineHeight: 1.5 }}>
                  {video.title}
                </Typography>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default VideoPage;
