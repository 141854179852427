// import React from "react";
// import "./Home.css";
// import illustration from "../../assets/images/stem.png"; // Replace with your image path
// import illu from "../../assets/images/illu.png"
// import Success from "./Success"; // Import the Success component
// import desktopVideoSrc from "../../assets/images/ban1.png"; // Replace with your desktop video path
// import mobileVideoSrc from "../../assets/videos/battlefield.webm"; // Replace with your mobile video path

// const Home = () => {
//   const isMobile = window.innerWidth <= 768;
//   const videoSrc = isMobile ? desktopVideoSrc : desktopVideoSrc;
//   return (
//     <section className="home" id="home">
//       {/* Hero Section */}
//       <div className="home-container">
//       <img
//           className="background-image"
//           src={videoSrc}
//           alt="Background" // Add a descriptive alt text
//         /> 
//       </div>

//       {/* Success Section */}
//       {/* <Success /> */}
//     </section>
//   );
// };

// export default Home;




// import React from "react";
// import "./Home.css";
// import desktopImageSrc from "../../assets/images/ban3.webp"; // Replace with your desktop image path
// import mobileImageSrc from "../../assets/images/ban3.webp"; // Replace with your mobile image path

// const Home = () => {
//   const isMobile = window.innerWidth <= 768; // Detect mobile screen
//   const imageSrc = isMobile ? mobileImageSrc : desktopImageSrc; // Select appropriate image

//   const handleRedirect = () => {
//     // Redirect to the dashboard
//     window.location.href = "/dashboard";
//   };

//   return (
//     <section className="home" id="home">
//       <div className="home-container">
//         <img
//           className="background-image"
//           src={imageSrc}
//           alt="Background" // Add alt text for accessibility
//           onClick={handleRedirect} // Add onClick handler
//           style={{ cursor: "pointer" }} // Add pointer cursor for better UX
//         />
//       </div>
//     </section>
//   );
// };

// export default Home;

import React from "react";
import { Link } from "react-scroll";
import "./Home.css";
import desktopImageSrc from "../../assets/images/website banner.gif"; // Replace with your desktop image path
import mobileImageSrc from "../../assets/images/website banner.gif"; // Replace with your mobile image path

const Home = () => {
  const isMobile = window.innerWidth <= 768; // Detect mobile screen
  const imageSrc = isMobile ? mobileImageSrc : desktopImageSrc; // Select appropriate image

  return (
    <section className="home" id="home">
      <div className="home-container">
        <Link to="games" smooth={true} duration={500}> {/* Smooth scroll to the games section */}
          <img
            className="background-image"
            src={imageSrc}
            alt="Background" // Add alt text for accessibility
            style={{ cursor: "pointer" }} // Add pointer cursor for better UX
          />
        </Link>
      </div>
    </section>
  );
};

export default Home;














































// import React from "react";
// import "./Home.css";
// import Success from "./Success"; // Import the Success component
// import desktopVideoSrc from "../../assets/videos/battlefield.webm"; // Replace with your desktop video path
// import mobileVideoSrc from "../../assets/videos/battlefield.webm"; // Replace with your mobile video path

// const Home = () => {
//   // Detect screen width to select the appropriate video
//   const isMobile = window.innerWidth <= 768;
//   const videoSrc = isMobile ? mobileVideoSrc : desktopVideoSrc;

//   return (
//     <section className="home" id="home">
//       {/* Full-Screen Video Section */}
//       <div className="video-container">
//         <video
//           className="background-video"
//           src={videoSrc}
//           autoPlay
//           loop
//           muted
//           playsInline
//         />
//       </div>

//       {/* Success Section */}
//       <div className="success-container">
//         <Success />
//       </div>
//     </section>
//   );
// };

// export default Home;
