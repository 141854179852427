// import React from "react";

// const CardFlip = (datas) => {
//   // Data to be displayed on the cards
//   const cardData = [
//     { title: "Science", desc: datas.data.tasks[0].task[0].engineering, bgColor: "linear-gradient(135deg, #ff9a9e, #fad0c4)" },
//     { title: "Technology", desc: datas.data.tasks[0].task[0].technology, bgColor: "linear-gradient(135deg, #fbc2eb, #a6c1ee)" },
//     { title: "Engineering", desc: datas.data.tasks[0].task[0].engineering, bgColor: "linear-gradient(135deg, #ffecd2, #fcb69f)" },
//     { title: "Math", desc: datas.data.tasks[0].task[0].maths, bgColor: "linear-gradient(135deg, #a18cd1, #fbc2eb)" },
//   ];

//   return (
//     <div style={styles.cardContainer}>
//       {cardData.map((data, index) => (
//         <div key={index} style={{ ...styles.card, background: data.bgColor }}>
//           <h3 style={styles.cardTitle}>{data.title}</h3>
//           <p style={styles.cardDescription}>{data.desc}</p>
//         </div>
//       ))}
//     </div>
//   );
// };

// // Inline CSS styles
// const styles = {
//   cardContainer: {
//     display: "flex",
//     flexWrap: "wrap", // Allows wrapping of cards
//     justifyContent: "center", // Center-align cards horizontally
//     gap: "20px", // Space between cards
//     marginTop: "50px",
//     padding: "0 20px",
//     width: "100%",
//   },
//   card: {
//     flex: "1 1 calc(50% - 20px)", // Two cards per row
//     maxWidth: "calc(50% - 20px)", // Prevent cards from exceeding the width
//     height: "180px",
//     borderRadius: "16px",
//     boxShadow: "0 10px 20px rgba(0, 0, 0, 0.2)",
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "center",
//     alignItems: "center",
//     textAlign: "center",
//     padding: "20px",
//     color: "white", // White text for contrast with colorful backgrounds
//     transition: "transform 0.3s ease, box-shadow 0.3s ease", // Smooth hover effects
//     position: "relative",
//     overflow: "hidden",
//   },
//   cardTitle: {
//     margin: "0 0 10px 0",
//     fontSize: "20px",
//     fontWeight: "bold",
//     textShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
//   },
//   cardDescription: {
//     margin: 0,
//     fontSize: "14px",
//     lineHeight: "1.5",
//     textShadow: "0 1px 3px rgba(0, 0, 0, 0.2)",
//   },
//   // Add a hover effect to cards
//   cardHover: {
//     "&:hover": {
//       transform: "scale(1.05)", // Slightly enlarge the card
//       boxShadow: "0 15px 30px rgba(0, 0, 0, 0.3)", // Intensify the shadow
//     },
//   },
//   // Media Queries for Responsiveness
//   "@media (max-width: 768px)": {
//     card: {
//       flex: "1 1 100%", // Take up full width on smaller screens
//       maxWidth: "100%", // Ensure full width
//     },
//   },
// };

// export default CardFlip;




// import React from "react";

// const CardFlip = (datas) => {
//   // Sample card data to be displayed on the cards
//   const cardData = [
//     { 
//       title: "Science", 
//       desc: JSON.parse(datas.data.tasks[0].task[0].engineering), 
//       bgColor: "linear-gradient(135deg, #ff9a9e, #fad0c4)" 
//     },
//     { 
//       title: "Technology", 
//       desc: JSON.parse(datas.data.tasks[0].task[0].technology), 
//       bgColor: "linear-gradient(135deg, #fbc2eb, #a6c1ee)" 
//     },
//     { 
//       title: "Engineering", 
//       desc: JSON.parse(datas.data.tasks[0].task[0].engineering), 
//       bgColor: "linear-gradient(135deg, #ffecd2, #fcb69f)" 
//     },
//     { 
//       title: "Math", 
//       desc: JSON.parse(datas.data.tasks[0].task[0].maths), 
//       bgColor: "linear-gradient(135deg, #a18cd1, #fbc2eb)" 
//     },
//   ];
  
//   return (
//     <div style={styles.cardContainer}>
//       {cardData.map((data, index) => (
//         <div key={index} style={{ ...styles.card, background: data.bgColor }}>
//           <h3 style={styles.cardTitle}>{data.title}</h3>
//           <div style={styles.cardDescription}>
//             {/* Directly print data.desc */}
//             {typeof data.desc === "string" && <div>{data.desc}</div>}
  
//             {typeof data.desc === "object" && data.desc !== null && (
//               <div>
//                 {Object.entries(data.desc).map(([key, value], idx) => (
//                   <div key={idx} style={{ marginBottom: "15px" }}>
//                     {/* Render key as heading */}
//                     <h4 style={styles.cardHeading}>{key}</h4>
//                     {/* Render value as bullet points */}
//                     {Array.isArray(value) ? (
//                       <ul style={styles.list}>
//                         {value.map((item, i) => (
//                           <li key={i} style={styles.listItem}>{item}</li>
//                         ))}
//                       </ul>
//                     ) : (
//                       <ul style={styles.list}>
//                         <li>{value}</li> {/* For single values */}
//                       </ul>
//                     )}
//                   </div>
//                 ))}
//               </div>
//             )}
  
//             {/* Handle other cases */}
//             {data.desc === undefined && <div>No Data Available</div>}
//           </div>
//         </div>
//       ))}
//     </div>
//   );
  
  
// };

// // Inline CSS styles
// const styles = {
//   cardContainer: {
//     display: "flex",
//     flexWrap: "wrap",
//     justifyContent: "center",
//     gap: "20px",
//     marginTop: "50px",
//     padding: "0 20px",
//     width: "100%",
//   },
//   card: {
//     flex: "1 1 calc(50% - 20px)",
//     maxWidth: "calc(50% - 20px)",
//     height: "auto", // Adjust height to accommodate content
//     borderRadius: "16px",
//     boxShadow: "0 10px 20px rgba(0, 0, 0, 0.2)",
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "flex-start",
//     alignItems: "center",
//     textAlign: "center",
//     padding: "20px",
//     color: "white",
//     transition: "transform 0.3s ease, box-shadow 0.3s ease",
//     position: "relative",
//     overflow: "hidden",
//   },
//   cardTitle: {
//     margin: "0 0 10px 0",
//     fontSize: "20px",
//     fontWeight: "bold",
//     textShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
//   },
//   cardDescription: {
//     marginTop: "10px",
//     fontSize: "14px",
//     lineHeight: "1.5",
//     textShadow: "0 1px 3px rgba(0, 0, 0, 0.2)",
//     textAlign: "left", // Align text to the left for better readability
//   },
//   cardItem: {
//     marginBottom: "10px",
//     textAlign: "left",
//   },
//   list: {
//     paddingLeft: "20px", // Indentation for list items
//   },
//   // Add a hover effect to cards
//   cardHover: {
//     "&:hover": {
//       transform: "scale(1.05)",
//       boxShadow: "0 15px 30px rgba(0, 0, 0, 0.3)",
//     },
//   },
//   // Media Queries for Responsiveness
//   "@media (max-width: 768px)": {
//     card: {
//       flex: "1 1 100%",
//       maxWidth: "100%",
//     },
//   },
// };

// export default CardFlip;





// import React, { useState } from "react";

// const CardFlip = (datas) => {
//   const [selectedCard, setSelectedCard] = useState(null); // State to manage expanded card
//   console.log("chikun",datas)
//   const handleSeeMore = (index) => {
//     setSelectedCard(index); // Set the selected card for expansion
//   };

//   const handleClosePopup = () => {
//     setSelectedCard(null); // Close the expanded view
//   };

//   // Sample card data to be displayed on the cards
//   const cardData = [
//     { 
//       title: "SCIENCE (UNDERSTANDING HOW THINGS WORK)", 
//       desc: JSON.parse(datas.data.tasks[0].task[0].science), 
//       bgColor: "linear-gradient(135deg, #ff9a9e, #fad0c4)" 
//     },
//     { 
//       title: " TECHNOLOGY (COOL GADGETS AND HOW THEY WORK)", 
//       desc: JSON.parse(datas.data.tasks[0].task[0].technology), 
//       bgColor: "linear-gradient(135deg, #fbc2eb, #a6c1ee)" 
//     },
//     { 
//       title: "ENGINEERING (BUILDING AND DESIGNING THINGS)", 
//       desc: JSON.parse(datas.data.tasks[0].task[0].engineering), 
//       bgColor: "linear-gradient(135deg, #ffecd2, #fcb69f)" 
//     },
//     { 
//       title: " MATHEMATICS (SIMPLE CALCULATIONS FOR BIG IDEAS)", 
//       desc: JSON.parse(datas.data.tasks[0].task[0].maths), 
//       bgColor: "linear-gradient(135deg, #a18cd1, #fbc2eb)" 
//     },
//   ];

//   return (
//     <div style={styles.cardContainer}>
//       {cardData.map((data, index) => (
//         <div key={index} style={{ ...styles.card, background: data.bgColor }}>
//           <h3 style={styles.cardTitle}>{data.title}</h3>
//           <div style={styles.cardDescription}>
//             {/* Display only one heading and one bullet point */}
//             {typeof data.desc === "object" && data.desc !== null && (
//               <>
//                 {Object.entries(data.desc).map(([key, value], idx) => (
//                   idx === 0 && (
//                     <div key={idx} style={{ marginBottom: "10px" }}>
//                       <h4 style={styles.cardHeading}>{key}</h4>
//                       <ul style={styles.list}>
//                         {Array.isArray(value) && value.length > 0 ? (
//                           <li style={styles.listItem}>{value[0]}</li>
//                         ) : (
//                           <li>{value}</li>
//                         )}
//                       </ul>
//                     </div>
//                   )
//                 ))}
//               </>
//             )}
//           </div>
//           {/* See More Button */}
//           <button style={styles.seeMoreButton} onClick={() => handleSeeMore(index)}>
//             See More
//           </button>
//         </div>
//       ))}

//       {/* Pop-up Modal for Expanded View */}
//       {selectedCard !== null && (
//         <div style={styles.popupOverlay}>
//           <div style={styles.popupContent}>
//             <button style={styles.closeButton} onClick={handleClosePopup}>
//               &times;
//             </button>
//             <h2 style={{ marginBottom: "20px" }}>{cardData[selectedCard].title}</h2>
//             <div style={{ textAlign: "left" }}>
//               {Object.entries(cardData[selectedCard].desc).map(([key, value], idx) => (
//                 <div key={idx} style={{ marginBottom: "15px" }}>
//                   <h4 style={styles.cardHeading}>{key}</h4>
//                   <ul style={styles.list}>
//                     {Array.isArray(value) ? (
//                       value.map((item, i) => <li key={i} style={styles.listItem}>{item}</li>)
//                     ) : (
//                       <li>{value}</li>
//                     )}
//                   </ul>
//                 </div>
//               ))}
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// // Inline CSS styles
// const styles = {
//   cardContainer: {
//     display: "flex",
//     flexWrap: "wrap",
//     justifyContent: "center",
//     gap: "20px",
//     marginTop: "50px",
//     padding: "0 20px",
//     // width: "100%",
//   },
//   card: {
//     flex: "1 1 calc(50% - 20px)",
//     // maxWidth: "calc(50% - 20px)",
//     borderRadius: "16px",
//     boxShadow: "0 10px 20px rgba(0, 0, 0, 0.2)",
//     padding: "20px",
//     color: "white",
//     textAlign: "center",
//     position: "relative",
//   },
//   cardTitle: {
//     fontSize: "20px",
//     fontWeight: "bold",
//   },
//   cardHeading: {
//     margin: "10px 0",
//     fontSize: "16px",
//   },
//   list: {
//     paddingLeft: "20px",
//   },
//   listItem: {
//     fontSize: "14px",
//     marginBottom: "5px",
//   },
//   seeMoreButton: {
//     marginTop: "10px",
//     padding: "5px 10px",
//     backgroundColor: "#fff",
//     color: "#333",
//     border: "none",
//     borderRadius: "5px",
//     cursor: "pointer",
//     fontSize: "14px",
//   },
//   popupOverlay: {
//     position: "fixed",
//     top: 0,
//     left: 0,
//     width: "100%",
//     height: "100%",
//     backgroundColor: "rgba(0, 0, 0, 0.7)",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     zIndex: 1000,
//   },
//   popupContent: {
//     background: "#fff",
//     borderRadius: "8px",
//     padding: "30px",
//     width: "80%",
//     maxHeight: "90%",
//     overflowY: "auto",
//     boxShadow: "0 10px 30px rgba(0, 0, 0, 0.3)",
//     color: "#333",
//     position: "relative",
//   },
//   closeButton: {
//     position: "absolute",
//     top: "10px",
//     right: "10px",
//     fontSize: "24px",
//     background: "transparent",
//     border: "none",
//     color: "#333",
//     cursor: "pointer",
//   },
//   "@media (max-width: 768px)": {
//     card: {
//       flex: "1 1 100%",
//       maxWidth: "100%",
//     },
//   },
// };

// export default CardFlip;



// import React, { useState } from "react";

// const CardFlip = (datas) => {
//   const [selectedCard, setSelectedCard] = useState(null); // State to manage expanded card
//   const handleSeeMore = (index) => {
//     setSelectedCard(index); // Set the selected card for expansion
//   };

//   const handleClosePopup = () => {
//     setSelectedCard(null); // Close the expanded view
//   };

//   // Sample card data to be displayed on the cards
//   const cardData = [
//     { 
//       title: "SCIENCE (UNDERSTANDING HOW THINGS WORK)", 
//       desc: safeParse(datas.data.tasks[0]?.task[0]?.science), 
//       bgColor: "linear-gradient(135deg, #ff9a9e, #fad0c4)" 
//     },
//     { 
//       title: "TECHNOLOGY (COOL GADGETS AND HOW THEY WORK)", 
//       desc: safeParse(datas.data.tasks[0]?.task[0]?.technology), 
//       bgColor: "linear-gradient(135deg, #fbc2eb, #a6c1ee)" 
//     },
//     { 
//       title: "ENGINEERING (BUILDING AND DESIGNING THINGS)", 
//       desc: safeParse(datas.data.tasks[0]?.task[0]?.engineering), 
//       bgColor: "linear-gradient(135deg, #ffecd2, #fcb69f)" 
//     },
//     { 
//       title: "MATHEMATICS (SIMPLE CALCULATIONS FOR BIG IDEAS)", 
//       desc: safeParse(datas.data.tasks[0]?.task[0]?.maths), 
//       bgColor: "linear-gradient(135deg, #a18cd1, #fbc2eb)" 
//     },
//   ];

//   return (
//     <div style={styles.cardContainer}>
//       {cardData.map((data, index) => (
//         <div key={index} style={{ ...styles.card, background: data.bgColor }}>
//           <h3 style={styles.cardTitle}>{data.title}</h3>
//           <div style={styles.cardDescription}>
//             {Object.keys(data.desc).length > 0 ? (
//               Object.entries(data.desc).map(([key, value], idx) => (
//                 idx === 0 && (
//                   <div key={idx} style={{ marginBottom: "10px" }}>
//                     <h4 style={styles.cardHeading}>{key}</h4>
//                     <ul style={styles.list}>
//                       {Array.isArray(value) && value.length > 0 ? (
//                         <li style={styles.listItem}>{value[0]}</li>
//                       ) : (
//                         <li>{value}</li>
//                       )}
//                     </ul>
//                   </div>
//                 )
//               ))
//             ) : (
//               <p style={{ color: "gray" }}>No content available</p>
//             )}
//           </div>
//           <button style={styles.seeMoreButton} onClick={() => handleSeeMore(index)}>
//             See More
//           </button>
//         </div>
//       ))}

//       {selectedCard !== null && (
//         <div style={styles.popupOverlay}>
//           <div style={styles.popupContent}>
//             <button style={styles.closeButton} onClick={handleClosePopup}>
//               &times;
//             </button>
//             <h2 style={{ marginBottom: "20px" }}>{cardData[selectedCard].title}</h2>
//             <div style={{ textAlign: "left" }}>
//               {Object.entries(cardData[selectedCard].desc).map(([key, value], idx) => (
//                 <div key={idx} style={{ marginBottom: "15px" }}>
//                   <h4 style={styles.cardHeading}>{key}</h4>
//                   <ul style={styles.list}>
//                     {Array.isArray(value) ? (
//                       value.map((item, i) => <li key={i} style={styles.listItem}>{item}</li>)
//                     ) : (
//                       <li>{value}</li>
//                     )}
//                   </ul>
//                 </div>
//               ))}
//               {Object.keys(cardData[selectedCard].desc).length === 0 && (
//                 <p style={{ color: "gray" }}>No content available</p>
//               )}
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// // Utility function to safely parse JSON strings
// const safeParse = (jsonString) => {
//   try {
//     return JSON.parse(jsonString || "{}");
//   } catch {
//     return {};
//   }
// };

// // Inline CSS styles
// const styles = {
//   cardContainer: {
//     display: "flex",
//     flexWrap: "wrap",
//     justifyContent: "center",
//     gap: "20px",
//     marginTop: "50px",
//     padding: "0 20px",
//   },
//   card: {
//     flex: "1 1 calc(50% - 20px)",
//     // maxWidth: "calc(50% - 20px)",
//     borderRadius: "16px",
//     boxShadow: "0 10px 20px rgba(0, 0, 0, 0.2)",
//     padding: "20px",
//     color: "white",
//     textAlign: "center",
//     position: "relative",
//   },
//   cardTitle: {
//     fontSize: "20px",
//     fontWeight: "bold",
//   },
//   cardHeading: {
//     margin: "10px 0",
//     fontSize: "16px",
//   },
//   list: {
//     paddingLeft: "20px",
//   },
//   listItem: {
//     fontSize: "14px",
//     marginBottom: "5px",
//   },
//   seeMoreButton: {
//     marginTop: "10px",
//     padding: "5px 10px",
//     backgroundColor: "#fff",
//     color: "#333",
//     border: "none",
//     borderRadius: "5px",
//     cursor: "pointer",
//     fontSize: "14px",
//   },
//   popupOverlay: {
//     position: "fixed",
//     top: 0,
//     left: 0,
//     width: "100%",
//     height: "100%",
//     backgroundColor: "rgba(0, 0, 0, 0.7)",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     zIndex: 1000,
//   },
//   popupContent: {
//     background: "#fff",
//     borderRadius: "8px",
//     padding: "30px",
//     width: "80%",
//     maxHeight: "90%",
//     overflowY: "auto",
//     boxShadow: "0 10px 30px rgba(0, 0, 0, 0.3)",
//     color: "#333",
//     position: "relative",
//   },
//   closeButton: {
//     position: "absolute",
//     top: "10px",
//     right: "10px",
//     fontSize: "24px",
//     background: "transparent",
//     border: "none",
//     color: "#333",
//     cursor: "pointer",
//   },
//   "@media (max-width: 768px)": {
//     card: {
//       flex: "1 1 100%",
//       maxWidth: "100%",
//     },
//   },
// };

// export default CardFlip;


// import React, { useState } from "react";
// import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";
// import CardMedia from "@mui/material/CardMedia";
// import Typography from "@mui/material/Typography";
// import CardActionArea from "@mui/material/CardActionArea";
// import Button from "@mui/material/Button";
// import Modal from "@mui/material/Modal";
// import Box from "@mui/material/Box";

// const CardFlip = (datas) => {
//   const [selectedCard, setSelectedCard] = useState(null); // State to manage expanded card

//   const handleSeeMore = (index) => {
//     setSelectedCard(index); // Set the selected card for expansion
//   };

//   const handleClosePopup = () => {
//     setSelectedCard(null); // Close the expanded view
//   };

//   // Sample card data to be displayed on the cards
//   const cardData = [
//     {
//       title: "SCIENCE (UNDERSTANDING HOW THINGS WORK)",
//       desc: safeParse(datas.data.tasks[0]?.task[0]?.science),
//       image: "/static/images/science.jpg",
//     },
//     {
//       title: "TECHNOLOGY (COOL GADGETS AND HOW THEY WORK)",
//       desc: safeParse(datas.data.tasks[0]?.task[0]?.technology),
//       image: "/static/images/technology.jpg",
//     },
//     {
//       title: "ENGINEERING (BUILDING AND DESIGNING THINGS)",
//       desc: safeParse(datas.data.tasks[0]?.task[0]?.engineering),
//       image: "/static/images/engineering.jpg",
//     },
//     {
//       title: "MATHEMATICS (SIMPLE CALCULATIONS FOR BIG IDEAS)",
//       desc: safeParse(datas.data.tasks[0]?.task[0]?.maths),
//       image: "/static/images/mathematics.jpg",
//     },
//   ];

//   return (
//     <div style={{ display: "flex", flexWrap: "wrap", gap: "20px", justifyContent: "center" }}>
//       {cardData.map((data, index) => (
//         <Card key={index} sx={{ maxWidth: 345 }}>
//           <CardActionArea onClick={() => handleSeeMore(index)}>
//             <CardMedia
//               component="img"
//               height="140"
//               image={data.image}
//               alt={data.title}
//             />
//             <CardContent>
//               <Typography gutterBottom variant="h5" component="div">
//                 {data.title}
//               </Typography>
//               <Typography variant="body2" color="text.secondary">
//                 {Object.keys(data.desc).length > 0
//                   ? Object.entries(data.desc)[0][1]
//                   : "No content available"}
//               </Typography>
//             </CardContent>
//           </CardActionArea>
//         </Card>
//       ))}

//       {/* Modal for Expanded View */}
//       {selectedCard !== null && (
//         <Modal open={true} onClose={handleClosePopup}>
//           <Box
//             sx={{
//               position: "absolute",
//               top: "50%",
//               left: "50%",
//               transform: "translate(-50%, -50%)",
//               width: 400,
//               bgcolor: "background.paper",
//               boxShadow: 24,
//               p: 4,
//             }}
//           >
//             <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
//               {cardData[selectedCard].title}
//             </Typography>
//             <div>
//               {Object.entries(cardData[selectedCard].desc).map(([key, value], idx) => (
//                 <div key={idx} style={{ marginBottom: "15px" }}>
//                   <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
//                     {key}
//                   </Typography>
//                   <ul style={{ paddingLeft: "20px", marginTop: "5px" }}>
//                     {Array.isArray(value) ? (
//                       value.map((item, i) => <li key={i}>{item}</li>)
//                     ) : (
//                       <li>{value}</li>
//                     )}
//                   </ul>
//                 </div>
//               ))}
//               {Object.keys(cardData[selectedCard].desc).length === 0 && (
//                 <Typography variant="body2" color="text.secondary">
//                   No content available
//                 </Typography>
//               )}
//             </div>
//             <Button
//               variant="contained"
//               sx={{ mt: 2 }}
//               onClick={handleClosePopup}
//             >
//               Close
//             </Button>
//           </Box>
//         </Modal>
//       )}
//     </div>
//   );
// };

// // Utility function to safely parse JSON strings
// const safeParse = (jsonString) => {
//   try {
//     return JSON.parse(jsonString || "{}");
//   } catch {
//     return {};
//   }
// };

// export default CardFlip;


import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import sc from "../../assets/images/science.jpg"
import te from "../../assets/images/technology.jpg"
import en from "../../assets/images/engineering.jpg"
import ma from "../../assets/images/math.jpg"

const CardFlip = (datas) => {
  const [selectedCard, setSelectedCard] = useState(null);

  const handleOpenModal = (index) => {
    setSelectedCard(index);
  };

  const handleCloseModal = () => {
    setSelectedCard(null);
  };

  const cardData = [
    {
      title: "SCIENCE (UNDERSTANDING HOW THINGS WORK)",
      desc: safeParse(datas.data.tasks[0]?.task[0]?.science),
      image: sc,
    },
    {
      title: "TECHNOLOGY (COOL GADGETS AND HOW THEY WORK)",
      desc: safeParse(datas.data.tasks[0]?.task[0]?.technology),
      image: te,
    },
    {
      title: "ENGINEERING (BUILDING AND DESIGNING THINGS)",
      desc: safeParse(datas.data.tasks[0]?.task[0]?.engineering),
      image: en,
    },
    {
      title: "MATHEMATICS (SIMPLE CALCULATIONS FOR BIG IDEAS)",
      desc: safeParse(datas.data.tasks[0]?.task[0]?.maths),
      image: ma,
    },
  ];

  return (
    <Box sx={{ flexGrow: 1, p: 2 }}>
      <Grid container spacing={2}>
        {cardData.map((data, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <Card sx={{ maxWidth: 345 }} onClick={() => handleOpenModal(index)}>
              <CardMedia
                component="img"
                height="140"
                image={data.image}
                alt={data.title}
              />
              <CardContent>
                {/* Title with fixed height */}
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  sx={{
                    // height: 40, // Fixed height
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 2,
                    textAlign: "justify",
                  }}
                >
                  {data.title}
                </Typography>

                {/* Description with two-line limit */}
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 5,
                    overflow: "hidden",
                    textAlign: "justify",
                    // height: 48, // Fixed height for two lines
                  }}
                >
                  {Object.keys(data.desc).length > 0
                    ? Object.entries(data.desc)
                        .map(([key, value]) => `${key}: ${value}`)
                        .join(", ")
                    : "No content available"}
                </Typography>

                {/* See More Button */}
                <Button
                  size="small"
                  sx={{ mt: 1 }}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent triggering the card click event
                    handleOpenModal(index);
                  }}
                >
                  See More
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Modal for Expanded View */}
      {selectedCard !== null && (
        <Modal open={true} onClose={handleCloseModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "90%",
              maxWidth: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              overflowY: "auto",
              maxHeight: "90%",
              textAlign: "justify",
            }}
          >
            <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
              {cardData[selectedCard].title}
            </Typography>
            <div>
              {Object.entries(cardData[selectedCard].desc).map(([key, value], idx) => (
                <div key={idx} style={{ marginBottom: "15px" }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                    {key}
                  </Typography>
                  <ul style={{ paddingLeft: "20px" }}>
                    {Array.isArray(value) ? (
                      value.map((item, i) => <li key={i}>{item}</li>)
                    ) : (
                      <li>{value}</li>
                    )}
                  </ul>
                </div>
              ))}
              {Object.keys(cardData[selectedCard].desc).length === 0 && (
                <Typography variant="body2" color="text.secondary">
                  No content available
                </Typography>
              )}
            </div>
            <Button
              variant="contained"
              sx={{ mt: 2 }}
              onClick={handleCloseModal}
            >
              Close
            </Button>
          </Box>
        </Modal>
      )}
    </Box>
  );
};

// Utility function to safely parse JSON strings
const safeParse = (jsonString) => {
  try {
    return JSON.parse(jsonString || "{}");
  } catch {
    return {};
  }
};

export default CardFlip;
