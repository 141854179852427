// import React, { useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { sendISPLContactData } from "../../features/ispl/isplContactSlice"; // Updated action import
// import "./Contact.css"; // Updated stylesheet import

// const ISPLContact = () => {
//   const dispatch = useDispatch();
//   const { loading, success, error } = useSelector((state) => state.isplContact); // Updated slice reference

//   // State to handle form inputs and validation errors
//   const [formData, setFormData] = useState({
//     firstName: "",
//     lastName: "",
//     email: "",
//     phone: "",
//     message: "",
//   });

//   const [formErrors, setFormErrors] = useState({
//     firstName: "",
//     lastName: "",
//     email: "",
//     phone: "",
//     message: "",
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({ ...prevData, [name]: value }));

//     // Clear error when user starts typing
//     setFormErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
//   };

//   const validateForm = () => {
//     const errors = {};
//     // Check if fields are empty
//     if (!formData.firstName) errors.firstName = "First Name is required";
//     if (!formData.lastName) errors.lastName = "Last Name is required";
//     if (!formData.email) errors.email = "Email is required";
//     if (!formData.phone) errors.phone = "Phone Number is required";
//     if (!formData.message) errors.message = "Message is required";

//     setFormErrors(errors);
//     return Object.keys(errors).length === 0; // If there are no errors, return true
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     // Validate form fields before submitting
//     if (!validateForm()) {
//       return; // Don't submit if there are validation errors
//     }

//     // Dispatch the action to send the contact form data
//     dispatch(sendISPLContactData(formData)); // Updated dispatch action
//   };

//   return (
//     <section className="ispl-contact" id="ispl-contact">
//       {/* Heading */}
//       <h2 className="contact-heading">
//         Contact <span className="highlight">ISPL</span>
//       </h2>

//       {/* Contact Container */}
//       <div className="contact-card">
//         {/* Left Card: Contact Information */}
//         <div className="contact-info-card">
//           <h3>Contact Information</h3>
//           <p>Say something to start a live chat!</p>
//           <ul>
//             <li>
//               <i className="fas fa-phone-alt contact-icon"></i>
//               <a href="tel:+918094414990" className="contact-link">
//                 +91 8094414990
//               </a>
//             </li>
//             <li>
//               <i className="fas fa-envelope contact-icon"></i>
//               <a href="mailto:info@isplconnect.com" className="contact-link">
//                 info@isplconnect.com
//               </a>
//             </li>
//             <li>
//               <i className="fas fa-map-marker-alt contact-icon"></i>
//               <a
//                 href="https://www.google.com/maps/place/13%C2%B002'02.1%22N+77%C2%B037'56.5%22E"
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 className="contact-link"
//               >
//                 319/A, 21, Maruti Nagar Hennur Village 5th Block Near Good Year English School Bangalore, Karnataka - 560043
//               </a>
//             </li>
//           </ul>
//         </div>

//         {/* Right Card: Contact Form */}
//         <div className="contact-form-card">
//           <form onSubmit={handleSubmit}>
//             <div className="form-row">
//               <input
//                 type="text"
//                 placeholder="First Name"
//                 name="firstName"
//                 value={formData.firstName}
//                 onChange={handleChange}
//               />
//               {formErrors.firstName && <p className="error-message">{formErrors.firstName}</p>}
              
//               <input
//                 type="text"
//                 placeholder="Last Name"
//                 name="lastName"
//                 value={formData.lastName}
//                 onChange={handleChange}
//               />
//               {formErrors.lastName && <p className="error-message">{formErrors.lastName}</p>}
//             </div>
//             <div className="form-row">
//               <input
//                 type="email"
//                 placeholder="Email"
//                 name="email"
//                 value={formData.email}
//                 onChange={handleChange}
//               />
//               {formErrors.email && <p className="error-message">{formErrors.email}</p>}
              
//               <input
//                 type="text"
//                 placeholder="Phone Number"
//                 name="phone"
//                 value={formData.phone}
//                 onChange={handleChange}
//               />
//               {formErrors.phone && <p className="error-message">{formErrors.phone}</p>}
//             </div>
//             <textarea
//               name="message"
//               placeholder="Write your message..."
//               value={formData.message}
//               onChange={handleChange}
//             ></textarea>
//             {formErrors.message && <p className="error-message">{formErrors.message}</p>}
            
//             <button type="submit" className="send-message-button" disabled={loading}>
//               {loading ? "Sending..." : "Send Message"}
//             </button>
//           </form>
//           {success && <p className="success-message">Message sent successfully!</p>}
//           {error && <p className="error-message">Error: {error}</p>}
//         </div>
//       </div>
//     </section>
//   );
// };

// export default ISPLContact;



// import React, { useState } from "react";
// import "./Contact.css"; // Updated stylesheet import
// import Navbar from "../navbar/Navbar";

// const ISPLContact = () => {
//   // State to handle form inputs and validation errors
//   const [formData, setFormData] = useState({
//     name: "",  // Combined name field
//     email: "",
//     phone: "",
//     message: "",
//   });

//   const [formErrors, setFormErrors] = useState({
//     name: "",  // For name field validation
//     email: "",
//     phone: "",
//     message: "",
//   });

//   const [formSubmitted, setFormSubmitted] = useState(false);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({ ...prevData, [name]: value }));

//     // Clear error when user starts typing
//     setFormErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
//   };

//   const validateForm = () => {
//     const errors = {};
//     // Check if fields are empty
//     if (!formData.name) errors.name = "Name is required";  // Name is now required
//     if (!formData.email) errors.email = "Email is required";
//     if (!formData.phone) errors.phone = "Phone Number is required";
//     if (!formData.message) errors.message = "Message is required";

//     setFormErrors(errors);
//     return Object.keys(errors).length === 0; // If there are no errors, return true
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     // Validate form fields before submitting
//     if (!validateForm()) {
//       return; // Don't submit if there are validation errors
//     }

//     // Set the form as submitted
//     setFormSubmitted(true);
//   };

//   return (
//     <>
//       <Navbar />
//       <section className="ispl-contact" id="ispl-contact">
//         {/* Heading */}
//         <h2 className="contact-heading">
//           {/* Contact <span className="highlight">ISPL</span> */}
//         </h2>

//         {/* Contact Container */}
//         <div className="contact-card">
//           {/* Left Card: Contact Information */}
//           <div className="contact-info-card">
//             <h3>Contact Information</h3>
//             <p>Say something to start a live chat!</p>
//             <ul>
//               <li>
//                 <i className="fas fa-phone-alt contact-icon"></i>
//                 <a href="tel:+918094414990" className="contact-link">
//                   +91 8094414990
//                 </a>
//               </li>
//               <li>
//                 <i className="fas fa-envelope contact-icon"></i>
//                 <a href="mailto:info@isplconnect.com" className="contact-link">
//                   info@isplconnect.com
//                 </a>
//               </li>
//               <li>
//                 <i className="fas fa-map-marker-alt contact-icon"></i>
//                 <a
//                   href="https://www.google.com/maps/place/13%C2%B002'02.1%22N+77%C2%B037'56.5%22E"
//                   target="_blank"
//                   rel="noopener noreferrer"
//                   className="contact-link"
//                 >
//                   319/A, 21, Maruti Nagar Hennur Village 5th Block Near Good Year English School Bangalore, Karnataka - 560043
//                 </a>
//               </li>
//             </ul>
//           </div>

//           {/* Right Card: Contact Form */}
//           <div className="contact-form-card">
//             <form onSubmit={handleSubmit}>
//               <div className="form-row">
//                 <input
//                   type="text"
//                   placeholder="Name"
//                   name="name"
//                   value={formData.name}
//                   onChange={handleChange}
//                 />
//                 {formErrors.name && <p className="error-message">{formErrors.name}</p>}
//               </div>
//               <div className="form-row">
//                 <input
//                   type="email"
//                   placeholder="Email"
//                   name="email"
//                   value={formData.email}
//                   onChange={handleChange}
//                 />
//                 {formErrors.email && <p className="error-message">{formErrors.email}</p>}

//                 <input
//                   type="text"
//                   placeholder="Phone Number"
//                   name="phone"
//                   value={formData.phone}
//                   onChange={handleChange}
//                 />
//                 {formErrors.phone && <p className="error-message">{formErrors.phone}</p>}
//               </div>
//               <textarea
//                 name="message"
//                 placeholder="Write your message..."
//                 value={formData.message}
//                 onChange={handleChange}
//               ></textarea>
//               {formErrors.message && <p className="error-message">{formErrors.message}</p>}

//               <button type="submit" className="send-message-button">
//                 Submit
//               </button>
//             </form>
//             {formSubmitted && (
//               <p className="success-message">Your message has been submitted successfully!</p>
//             )}
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default ISPLContact;



import React, { useState } from "react";
import "./Contact.css"; // Updated stylesheet import
import Navbar from "../navbar/Navbar";
import { getEnvironmentDetails } from "../../environment";

const ISPLContact = () => {
  // State to handle form inputs and validation errors
  const [formData, setFormData] = useState({
    name: "",  // Combined name field
    email: "",
    contact: "", // Changed to contact for backend compatibility
    message: "",
  });
  const { BASE_URL } = getEnvironmentDetails(); // Destructure BASE_URL
  const [formErrors, setFormErrors] = useState({
    name: "",  // For name field validation
    email: "",
    contact: "", // Changed to contact
    message: "",
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [submissionError, setSubmissionError] = useState(""); // To handle error during submission

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));

    // Clear error when user starts typing
    setFormErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const validateForm = () => {
    const errors = {};
    // Check if fields are empty
    if (!formData.name) errors.name = "Name is required";  
    if (!formData.email) errors.email = "Email is required";
    if (!formData.contact) errors.contact = "Phone Number is required"; // Check for contact
    if (!formData.message) errors.message = "Message is required";

    setFormErrors(errors);
    return Object.keys(errors).length === 0; // If there are no errors, return true
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form fields before submitting
    if (!validateForm()) {
      return; // Don't submit if there are validation errors
    }

    try {
      const response = await fetch(`${BASE_URL}ispl/contact`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData), // Send full form data
      });

      if (response.ok) {
        // Reset form after successful submission
        setFormData({
          name: "",
          email: "",
          contact: "", // Reset contact field
          message: "",
        });
        setFormErrors({});
        setFormSubmitted(true); // Set success message
        setSubmissionError(""); // Clear any previous error
      } else {
        // Handle backend validation errors (if any)
        const data = await response.json();
        setSubmissionError(data.message || "Something went wrong, please try again.");
        setFormSubmitted(false);
      }
    } catch (error) {
      // Handle network errors
      setSubmissionError("Network error, please try again later.");
      setFormSubmitted(false);
    }
  };

  return (
    <>
      <Navbar />
      <section className="ispl-contact" id="ispl-contact">
        {/* Heading */}
        <h2 className="contact-heading">
          {/* Contact <span className="highlight">ISPL</span> */}
        </h2>

        {/* Contact Container */}
        <div className="contact-card">
          {/* Left Card: Contact Information */}
          <div className="contact-info-card">
            <h3>Contact Information</h3>
            <p>Say something to start a live chat!</p>
            <ul>
              <li>
                <i className="fas fa-phone-alt contact-icon"></i>
                <a href="tel:+918094414990" className="contact-link">
                  +91 8094414990
                </a>
              </li>
              <li>
                <i className="fas fa-envelope contact-icon"></i>
                <a href="mailto:info@isplconnect.com" className="contact-link">
                  info@isplconnect.com
                </a>
              </li>
              <li>
                <i className="fas fa-map-marker-alt contact-icon"></i>
                <a
                  href="https://www.google.com/maps/place/13%C2%B002'02.1%22N+77%C2%B037'56.5%22E"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="contact-link"
                >
                  319/A, 21, Maruti Nagar Hennur Village 5th Block Near Good Year English School Bangalore, Karnataka - 560043
                </a>
              </li>
            </ul>
          </div>

          {/* Right Card: Contact Form */}
          <div className="contact-form-card">
            <form onSubmit={handleSubmit}>
              <div className="form-row">
                <input
                  type="text"
                  placeholder="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
                {formErrors.name && <p className="error-message">{formErrors.name}</p>}
              </div>
              <div className="form-row">
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                {formErrors.email && <p className="error-message">{formErrors.email}</p>}

                <input
                  type="text"
                  placeholder="Phone Number"
                  name="contact"  // Changed to 'contact' for backend compatibility
                  value={formData.contact}
                  onChange={handleChange}
                />
                {formErrors.contact && <p className="error-message">{formErrors.contact}</p>}
              </div>
              <textarea
                name="message"
                placeholder="Write your message..."
                value={formData.message}
                onChange={handleChange}
              ></textarea>
              {formErrors.message && <p className="error-message">{formErrors.message}</p>}

              <button type="submit" className="send-message-button">
                Submit
              </button>
            </form>
            {formSubmitted && (
              <p className="success-message">Your message has been submitted successfully!</p>
            )}
            {submissionError && (
              <p className="error-message">{submissionError}</p>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default ISPLContact;
