import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./FunZoneButton.css";

const IsplButton = ({ onClick }) => {
  const navigate = useNavigate(); // Hook to programmatically navigate
  const location = useLocation(); // Get the current route

  

  const handleNavigate = () => {
    if (onClick) onClick(); // Close the menu if provided
    navigate( "/ispl"); // Redirect based on the current route
  };

  return (
    <button className="fun-zone-button" onClick={handleNavigate}>
      {"ISPL-2025"}
    </button>
  );
};

export default IsplButton;
