const initialState = {
    otpSent: false,
    otpError: null,
    otpValid: false,
  };
  
  export const otpReducer = (state = initialState, action) => {
    switch (action.type) {
      case "OTP_SENT":
        return {
          ...state,
          otpSent: true,
          otpError: null,
        };
      case "OTP_VALID":
        return {
          ...state,
          otpValid: true,
          otpError: null,
        };
      case "OTP_ERROR":
        return {
          ...state,
          otpError: action.payload,
        };
      default:
        return state;
    }
  };
  