import { getEnvironmentDetails } from "../../environment";

const { BASE_URL } = getEnvironmentDetails(); // Destructure BASE_URL
// Action to request OTP
export const requestOtp = (contact) => async (dispatch) => {
    try {
      const response = await fetch(`${BASE_URL}ispl/participants/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "request_otp",
          contact,
        }),
      });
  
      if (response.ok) {
        const data = await response.json();
        dispatch({ type: "OTP_SENT" });
        alert("OTP sent successfully!");
      } else {
        const errorData = await response.json();
        dispatch({ type: "OTP_ERROR", payload: errorData.error || "An error occurred" });
      }
    } catch (error) {
      dispatch({ type: "OTP_ERROR", payload: error.message || "An error occurred" });
    }
  };
  
  // Action to verify OTP and submit participant data
//   export const verifyOtp = (contact, otp) => async (dispatch) => {
//     try {
//       const response = await fetch(`${BASE_URL}ispl/participants/", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           contact,
//           otp,
//           name,
//           address,
//           class_field,
//           district,
//           email,
//           gender,
//           school_name,
//           state,
//           zipcode
//         }),
//       });
  
//       if (response.ok) {
//         const data = await response.json();
//         dispatch({ type: "OTP_VALID" });
//         alert("OTP is valid and participant data submitted.");
//       } else {
//         const errorData = await response.json();
//         dispatch({ type: "OTP_ERROR", payload: errorData.error || "Invalid OTP" });
//       }
//     } catch (error) {
//       dispatch({ type: "OTP_ERROR", payload: error.message || "An error occurred" });
//     }
//   };
  


// Example of updated verifyOtp action
export const verifyOtp = (formData) => async (dispatch) => {
    try {
      const response = await fetch(`${BASE_URL}ispl/participants/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData), // Send full form data
      });
  
      const data = await response.json();
  
      // Check if the response contains the specific error message for duplicate contact
      if (response.ok) {
        dispatch({ type: "VERIFY_OTP_SUCCESS", payload: data });
      } else {
        // Check if the error is related to the contact already existing
        if (data.contact && data.contact.includes("participant with this contact already exists.")) {
          // Show an alert if contact already exists
          alert("Participant with this contact already exists.");
        } else {
          dispatch({ type: "VERIFY_OTP_FAILURE", payload: data.message });
        }
      }
    } catch (error) {
      dispatch({ type: "VERIFY_OTP_FAILURE", payload: error.message });
    }
  };
  