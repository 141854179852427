// import React, { useEffect, useState } from "react";
// import "./home.css";
// import Navbar from "../navbar/Navbar";
// import About from "./About"

// import desktopImageSrc from "../../assets/images/website banner.gif";
// import mobileImageSrc from "../../assets/images/website banner.gif";
// const videoData = [
//     {
//       id: 1,
//       title: "Introduction to Robotics",
//       thumbnail: "https://mdbcdn.b-cdn.net/img/screens/yt/screen-video-1.webp",
//       videoUrl: "https://www.youtube.com/embed/A3PDXmYoF5U",
//     },
//     {
//       id: 2,
//       title: "STEM Learning Explained",
//       thumbnail: "https://mdbcdn.b-cdn.net/img/screens/yt/screen-video-2.webp",
//       videoUrl: "https://www.youtube.com/embed/wTcNtgA6gHs",
//     },
//     {
//       id: 3,
//       title: "Future of Education",
//       thumbnail: "https://mdbcdn.b-cdn.net/img/screens/yt/screen-video-3.webp",
//       videoUrl: "https://www.youtube.com/embed/vlDzYIIOYmM",
//     },
//     {
//         id: 4,
//         title: "Future of Education",
//         thumbnail: "https://mdbcdn.b-cdn.net/img/screens/yt/screen-video-3.webp",
//         videoUrl: "https://www.youtube.com/embed/vlDzYIIOYmM",
//       },
//       {
//         id: 5,
//         title: "Future of Education",
//         thumbnail: "https://mdbcdn.b-cdn.net/img/screens/yt/screen-video-3.webp",
//         videoUrl: "https://www.youtube.com/embed/vlDzYIIOYmM",
//       },
//   ];

// const Home = () => {
//   const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
//   const [modalVideo, setModalVideo] = useState(null);

//   const openModal = (video) => setModalVideo(video);
//   const closeModal = () => setModalVideo(null);
//   useEffect(() => {
//     const handleResize = () => setIsMobile(window.innerWidth <= 768);
//     window.addEventListener("resize", handleResize);
//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   const imageSrc = isMobile ? mobileImageSrc : desktopImageSrc;

//   return (
//     <section className="home" id="home">
//       <Navbar />
//       <div className="home-container">
//         <img
//           className="background-image"
//           src={imageSrc}
//           alt="Background"
//           style={{ cursor: "pointer" }}
//         />
//       </div>

//       <div className="content-section">
        
//         <About/>
//         {/* Winning Prize Section */}
//         <h2 className="section-heading">
//           Winning <span className="highlight">Prize 🏆</span>
//         </h2>
//         <div className="image-section">
//           <img
//             src={desktopImageSrc}
//             alt="Mission"
//             className="responsive-image"
//           />
//         </div>

//         {/* Learn More Section */}
        
//         <section className="home" id="home">
//       <div className="content-section">
//         <h2 className="section-heading">
//           Learn More About <span className="highlight">Us</span>
//         </h2>
//         <div className="video-gallery">
//           {videoData.map((video) => (
//             <div className="video-card" key={video.id}>
//               <h3 className="video-title">{video.title}</h3>
//               <div
//                 className="video-thumbnail"
//                 onClick={() => openModal(video)}
//               >
//                 <img src={video.thumbnail} alt={video.title} />
//               </div>
//             </div>
//           ))}
//         </div>

//         {modalVideo && (
//           <div className="modal-overlay" onClick={closeModal}>
//             <div
//               className="modal-content"
//               onClick={(e) => e.stopPropagation()} // Prevent closing modal when clicking inside
//             >
//               <iframe
//                 className="modal-video-player"
//                 src={modalVideo.videoUrl}
//                 title={modalVideo.title}
//                 frameBorder="0"
//                 allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//                 allowFullScreen
//               ></iframe>
//               <button className="modal-close-btn" onClick={closeModal}>
//                 Close
//               </button>
//             </div>
//           </div>
//         )}
//       </div>
//     </section>
//       </div>
//     </section>
//   );
// };

// export default Home;




// import React, { useEffect, useState } from "react";
// import "./home.css";
// import Navbar from "../navbar/Navbar";
// import About from "./About";

// // import desktopImageSrc from "../../assets/images/website banner.gif";
// // import mobileImageSrc from "../../assets/images/website banner.gif";

// const Home = () => {
//   const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
//   const [modalVideo, setModalVideo] = useState(null);
//   const [videoData, setVideoData] = useState([]); // State for video data
//   const [loading, setLoading] = useState(true); // Loading state
//   const [error, setError] = useState(null); // Error state

//   const openModal = (video) => setModalVideo(video);
//   const closeModal = () => setModalVideo(null);

//   useEffect(() => {
//     const handleResize = () => setIsMobile(window.innerWidth <= 768);
//     window.addEventListener("resize", handleResize);
//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   const fetchMediaData = async () => {
//     try {
//       const response = await fetch(
//         `${BASE_URL}ispl/home/"
//       );

//       if (!response.ok) {
//         throw new Error("Failed to fetch media data");
//       }

//       const data = await response.json();
//       console.log("chikun",data)
//       // Map the API data to match your component's structure
//       let mappedData = [];

//         // Ensure data is defined and an array before processing
//         if (Array.isArray(data)) {
//           mappedData = data
//             .filter((item) => item.media_type === "video" && item.category === "concept")
//             .map((item) => ({
//               id: item.id,
//               title: item.media_title,
//               mediaPath: item.media_path, // Use media_path for the video URL
//             }));

//           if (mappedData.length > 0) {
//             console.log("Mapped data stored:", mappedData);
//           } else {
//             console.log("No matching data found for the condition.");
//           }
//         } else {
//           console.log("Invalid data: not an array or undefined.");
//         }

//       setVideoData(mappedData);
//     } catch (err) {
//       setError(err.message);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchMediaData();
//   }, []);
//   useEffect(() => {
//     // Find the item with category 'banner' for mobile and desktop images
//     const banner = data.find((item) => item.category === "banner" && item.media_type === "img");
  
//     if (banner) {
//       // If a banner exists, assign the media_path for both mobile and desktop images
//       setMobileImageSrc(banner.media_path); // Assuming this path is valid for both mobile and desktop
//       setDesktopImageSrc(banner.media_path); // Assuming this path is valid for both mobile and desktop
//     }
//   }, [data]); // Dependencies can be updated if data changes dynamically

//   const imageSrc = isMobile ? mobileImageSrc : desktopImageSrc;

//   return (
//     <section className="home" id="home">
//       <Navbar />
//       <div className="home-container">
//         <img
//           className="background-image"
//           src={imageSrc}
//           alt="Background"
//           style={{ cursor: "pointer" }}
//         />
//       </div>

//       <div className="content-section">
//         <About />
//         {/* Winning Prize Section */}
//         <h2 className="section-heading">
//           Winning <span className="highlight">Prize 🏆</span>
//         </h2>
//         <div className="image-section">
//           <img
//             src={desktopImageSrc}
//             alt="Mission"
//             className="responsive-image"
//           />
//         </div>

//         {/* Learn More Section */}
//         <h2 className="section-heading">
//           Learn More About <span className="highlight">Us</span>
//         </h2>
//         <div className="video-gallery">
//           {loading && <p>Loading videos...</p>}
//           {error && <p>Error: {error}</p>}
//           {videoData.map((video) => {
//             // Log the video for debugging
//             console.log("Media Path:", video.media_path);

//             // Extract YouTube video ID from the media_path
//             const videoId = video.mediaPath.split("/").pop(); // Get the last part of the URL
//             const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`; // High-quality YouTube thumbnail

//             return (
//               <div className="video-card" key={video.id}>
//                 <h3 className="video-title">{video.title}</h3>
//                 <div
//                   className="video-thumbnail"
//                   onClick={() => openModal(video)}
//                 >
//                   <img src={thumbnailUrl} alt={video.title} />
//                 </div>
//               </div>
//             );
//           })}
//         </div>



//         {modalVideo && (
//           <div className="modal-overlay" onClick={closeModal}>
//             <div
//               className="modal-content"
//               onClick={(e) => e.stopPropagation()} // Prevent closing modal when clicking inside
//             >
//               <iframe
//                 className="modal-video-player"
//                 src={modalVideo.mediaPath}
//                 title={modalVideo.title}
//                 frameBorder="0"
//                 allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//                 allowFullScreen
//               ></iframe>
//               <button className="modal-close-btn" onClick={closeModal}>
//                 Close
//               </button>
//             </div>
//           </div>
//         )}
//       </div>
//     </section>
//   );
// };

// export default Home;




// import React, { useEffect, useState } from "react";
// import "./home.css";
// import Navbar from "../navbar/Navbar";
// import About from "./About";

// const Home = () => {
//   const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
//   const [modalVideo, setModalVideo] = useState(null);
//   const [videoData, setVideoData] = useState([]); // State for video data
//   const [loading, setLoading] = useState(true); // Loading state
//   const [error, setError] = useState(null); // Error state
//   const [mobileImageSrc, setMobileImageSrc] = useState(""); // Mobile image state
//   const [desktopImageSrc, setDesktopImageSrc] = useState(""); // Desktop image state

//   const openModal = (video) => setModalVideo(video);
//   const closeModal = () => setModalVideo(null);

//   useEffect(() => {
//     const handleResize = () => setIsMobile(window.innerWidth <= 768);
//     window.addEventListener("resize", handleResize);
//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   // Fetch media data
//   const fetchMediaData = async () => {
//     try {
//       const response = await fetch(`${BASE_URL}ispl/home/");
//       if (!response.ok) {
//         throw new Error("Failed to fetch media data");
//       }

//       const data = await response.json();
//       console.log("Fetched Data:", data);

//       let mappedData = [];

//       if (Array.isArray(data)) {
//         // Filter and map the video data
//         mappedData = data
//           .filter((item) => item.media_type === "video" && item.category === "concept")
//           .map((item) => ({
//             id: item.id,
//             title: item.media_title,
//             mediaPath: item.media_path, // Use media_path for the video URL
//           }));

//         if (mappedData.length > 0) {
//           console.log("Mapped data stored:", mappedData);
//         } else {
//           console.log("No matching data found for the condition.");
//         }
//       } else {
//         console.log("Invalid data: not an array or undefined.");
//       }

//       setVideoData(mappedData);
//     } catch (err) {
//       setError(err.message);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchMediaData();
//   }, []);

//   // Use effect to get banner images for both mobile and desktop
//   useEffect(() => {
//     const banner = videoData.find(
//       (item) => item.category === "banner" && item.media_type === "img"
//     );

//     if (banner) {
//       setMobileImageSrc(banner.mediaPath);
//       setDesktopImageSrc(banner.mediaPath);
//     }
//     console.log("banner",videoData)
//   }, [videoData]); // Only run when videoData changes

//   const imageSrc = isMobile ? mobileImageSrc : desktopImageSrc;

//   return (
//     <section className="home" id="home">
//       <Navbar />
//       <div className="home-container">
//         <img
//           className="background-image"
//           src={imageSrc}
//           alt="Background"
//           style={{ cursor: "pointer" }}
//         />
//       </div>

//       <div className="content-section">
//         <About />
//         {/* Winning Prize Section */}
//         <h2 className="section-heading">
//           Winning <span className="highlight">Prize 🏆</span>
//         </h2>
//         <div className="image-section">
//           <img
//             src={desktopImageSrc}
//             alt="Mission"
//             className="responsive-image"
//           />
//         </div>

//         {/* Learn More Section */}
//         <h2 className="section-heading">
//           Learn More About <span className="highlight">Us</span>
//         </h2>
//         <div className="video-gallery">
//           {loading && <p>Loading videos...</p>}
//           {error && <p>Error: {error}</p>}
//           {videoData.map((video) => {
//             // Log the media path for debugging
//             console.log("Media Path:", video.mediaPath);

//             // Extract YouTube video ID from the mediaPath
//             const videoId = video.mediaPath.split("/").pop(); // Get the last part of the URL
//             const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`; // High-quality YouTube thumbnail

//             return (
//               <div className="video-card" key={video.id}>
//                 <h3 className="video-title">{video.title}</h3>
//                 <div
//                   className="video-thumbnail"
//                   onClick={() => openModal(video)}
//                 >
//                   <img src={thumbnailUrl} alt={video.title} />
//                 </div>
//               </div>
//             );
//           })}
//         </div>

//         {modalVideo && (
//           <div className="modal-overlay" onClick={closeModal}>
//             <div
//               className="modal-content"
//               onClick={(e) => e.stopPropagation()} // Prevent closing modal when clicking inside
//             >
//               <iframe
//                 className="modal-video-player"
//                 src={modalVideo.mediaPath}
//                 title={modalVideo.title}
//                 frameBorder="0"
//                 allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//                 allowFullScreen
//               ></iframe>
//               <button className="modal-close-btn" onClick={closeModal}>
//                 Close
//               </button>
//             </div>
//           </div>
//         )}
//       </div>
//     </section>
//   );
// };

// export default Home;



import React, { useEffect, useState } from "react";
import "./home.css";
import Navbar from "../navbar/Navbar";
import About from "./About";
import { getEnvironmentDetails } from "../../environment";
import { useNavigate } from "react-router-dom";


const Home = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [modalVideo, setModalVideo] = useState(null);
  const [videoData, setVideoData] = useState([]); // State for video data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [mobileImageSrc, setMobileImageSrc] = useState(""); // Mobile image state
  const [desktopImageSrc, setDesktopImageSrc] = useState(""); // Desktop image state
  const [winprize, setwinprize] = useState(""); // Winning prize image state
  const { BASE_URL } = getEnvironmentDetails(); // Destructure BASE_URL
  const openModal = (video) => setModalVideo(video);
  const closeModal = () => setModalVideo(null);
  const navigate = useNavigate();
  
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Fetch media data
  const fetchMediaData = async () => {
    try {
      const response = await fetch(`${BASE_URL}ispl/home/`); 
      if (!response.ok) {
        throw new Error("Failed to fetch media data");
      }

      const data = await response.json();
      console.log("Fetched Data:", data);

      let mappedData = [];
      let bannerImage = null;
      let winningimage = null;

      if (Array.isArray(data)) {
        // Filter and map the video data
        mappedData = data
          .filter((item) => item.media_type === "video" && item.category === "concept")
          .map((item) => ({
            id: item.id,
            title: item.media_title,
            mediaPath: item.media_path, // Use media_path for the video URL
          }));

        // Find the banner image
        bannerImage = data.find(
          (item) => item.category === "banner" && item.media_type === "img"
        );
        winningimage = data.find(
          (item) => item.category === "prize" && item.media_type === "img"
        );
      } else {
        console.log("Invalid data: not an array or undefined.");
      }

      if (winningimage) {
        setwinprize(winningimage.media_path);
      }console.log(winningimage.media_path)

      // Set the banner images if found
      if (bannerImage) {
        setMobileImageSrc(bannerImage.media_path);
        setDesktopImageSrc(bannerImage.media_path);
      }

      setVideoData(mappedData);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMediaData();
  }, []);

  const imageSrc = isMobile ? mobileImageSrc : desktopImageSrc;

  return (
    <section className="home" id="home">
      <Navbar />
      <div className="home-container">
        <img
          className="background-image"
          src={imageSrc}
          alt="Background"
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/ispl/register")}
        />
      </div>

      <div className="content-section">
        <About />
        {/* Winning Prize Section */}
        <h2 className="section-heading">
          Winning <span className="highlight">Prize 🏆</span>
        </h2>
        <div className="image-section">
          <img
            src={winprize}  // Corrected line: Use the state value here
            alt="Mission"
            className="responsive-image"
          />
        </div>

        {/* Learn More Section */}
        <h2 className="section-heading">
          Learn More About <span className="highlight">Us</span>
        </h2>
        <div className="video-gallery">
          {loading && <p>Loading videos...</p>}
          {error && <p>Error: {error}</p>}
          {videoData.map((video) => {
            // Log the media path for debugging
            console.log("Media Path:", video.mediaPath);

            // Extract YouTube video ID from the mediaPath
            const videoId = video.mediaPath.split("/").pop(); // Get the last part of the URL
            const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`; // High-quality YouTube thumbnail

            return (
              <div className="video-card" key={video.id}>
                <h3 className="video-title">{video.title}</h3>
                <div
                  className="video-thumbnail"
                  onClick={() => openModal(video)}
                >
                  <img src={thumbnailUrl} alt={video.title} />
                </div>
              </div>
            );
          })}
        </div>

        {modalVideo && (
          <div className="modal-overlay" onClick={closeModal}>
            <div
              className="modal-content"
              onClick={(e) => e.stopPropagation()} // Prevent closing modal when clicking inside
            >
              <iframe
                className="modal-video-player"
                src={modalVideo.mediaPath}
                title={modalVideo.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              <button className="modal-close-btn" onClick={closeModal}>
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Home;
