// import React from "react";
// import { Viewer, Worker } from "@react-pdf-viewer/core";
// import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
// import "@react-pdf-viewer/core/lib/styles/index.css";
// import "@react-pdf-viewer/default-layout/lib/styles/index.css";
// import Navbar from "../navbar/Navbar"; // Correctly imported Navbar
// import pdf from "../../assets/images/gamepdf1.pdf"; // Correct path to PDF

// const Sponsor = () => {
//   // Initialize the default layout plugin
//   const defaultLayoutPluginInstance = defaultLayoutPlugin();

//   return (
//     <div style={{ height: "100vh", width: "100vw", display: "flex", flexDirection: "column" }}>
//       {/* Navbar */}
//       <Navbar />

//       {/* PDF Viewer */}
//       <div style={{ flex: 1, overflow: "hidden" }}>
//         <Worker workerUrl={`https://unpkg.com/pdfjs-dist@2.10.377/build/pdf.worker.min.js`}>
//           <Viewer fileUrl={pdf} plugins={[defaultLayoutPluginInstance]} />
//         </Worker>
//       </div>
//     </div>
//   );
// };

// export default Sponsor;




import React, { useEffect, useState } from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import Navbar from "../navbar/Navbar"; // Correctly imported Navbar
import { getEnvironmentDetails } from "../../environment";

const Sponsor = () => {
  const [pdfUrl, setPdfUrl] = useState(null); // State to store the PDF URL
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { BASE_URL } = getEnvironmentDetails(); // Destructure BASE_URL
  
  // Initialize the default layout plugin
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  useEffect(() => {
    const fetchPdfUrl = async () => {
      try {
        // Fetch data from the backend
        const response = await fetch(`${BASE_URL}ispl/home/`);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        // Check for the first item where category is sponsor and media_type is pdf
        const sponsorPdf = data.find(
          (item) => item.category === "sponsor" && item.media_type === "pdf"
        );

        if (sponsorPdf) {
          setPdfUrl(sponsorPdf.media_path); // Set the URL of the matching PDF
          console.log(sponsorPdf.media_path)
        } else {
          throw new Error("No sponsor PDF found in the data");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPdfUrl();
  }, []);

  return (
    <div style={{ height: "100vh", width: "100vw", display: "flex", flexDirection: "column" }}>
      {/* Navbar */}
      <Navbar />

      {/* PDF Viewer */}
      <div style={{ flex: 1, overflow: "hidden" }}>
        {loading && <p>Loading PDF...</p>}
        {error && <p>Error: {error}</p>}
        {pdfUrl && (
          <Worker workerUrl={`https://unpkg.com/pdfjs-dist@2.10.377/build/pdf.worker.min.js`}>
            <Viewer fileUrl={pdfUrl} plugins={[defaultLayoutPluginInstance]} />
          </Worker>
        )}
      </div>
    </div>
  );
};

export default Sponsor;
