// import React from "react";
// import { Box, Card, CardMedia, CardContent, Typography } from "@mui/material";
// import StarIcon from "@mui/icons-material/Star";

// const TaskCard = ({ task }) => {
//   return (
//     <Card sx={{ width: "100%", maxWidth: 300, margin: "auto", boxShadow: 3 }}>
//       <CardMedia
//         component="img"
//         height="140"
//         image={task.photo} // The processed photo URL
//         alt={task.title}
//       />
//       <CardContent>
//         <Typography variant="h6" component="div" sx={{ fontWeight: "bold" }}>
//           {task.title}
//         </Typography>
//         <Typography variant="body2" color="textSecondary">
//           Task: {task.task_number}
//         </Typography>
//         <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
//           <Typography variant="body1" sx={{ mr: 1 }}>
//             {task.rating}
//           </Typography>
//           <StarIcon sx={{ color: "gold" }} />
//         </Box>
//       </CardContent>
//     </Card>
//   );
// };

// export default TaskCard;


// import React from "react";
// import { Box, Card, CardMedia, CardContent, Typography } from "@mui/material";
// import StarIcon from "@mui/icons-material/Star";
// import decryptURI from "../modifier/decryptURI"; // Import the decryptURI function

// const TaskCard = ({ task }) => {
//   // Decrypt the photo URL safely
//   let decryptedPhoto;
//   try {
//     decryptedPhoto = decryptURI(task.photo); // Decrypt the photo URL
//   } catch (error) {
//     console.error("Failed to decrypt photo URL:", error);
//     decryptedPhoto = ""; // Fallback to an empty string or default image
//   }

//   return (
//     <Card
//       sx={{
//         width: "100%",
//         maxWidth: 280,
//         margin: "auto",
//         borderRadius: "16px",
//         boxShadow: 3,
//         position: "relative",
//         padding: "8px",
//         background: "#ffffff",
//         display: "flex",
//         flexDirection: "column", // Ensures proper stacking of content
//         justifyContent: "space-between", // Ensures spacing between content
//       }}
//     >
//       {/* Small Overlay Card for Rating */}
//       <Box
//         sx={{
//           position: "absolute",
//           top: "12px",
//           right: "12px",
//           backgroundColor: "#fff",
//           padding: "4px 8px",
//           borderRadius: "12px",
//           boxShadow: "0px 2px 6px rgba(0,0,0,0.2)",
//           display: "flex",
//           alignItems: "center",
//           zIndex: 1,
//         }}
//       >
//         <Typography variant="body2" sx={{ fontWeight: "bold", fontSize: "14px" }}>
//           {task.rating}
//         </Typography>
//         <StarIcon sx={{ color: "green", fontSize: "16px", marginLeft: "4px" }} />
//       </Box>

//       {/* Image Section */}
//       <Box
//         sx={{
//           borderRadius: "12px",
//           overflow: "hidden",
//         }}
//       >
//         <CardMedia
//           component="img"
//           height="150"
//           image={decryptedPhoto || "default-image.jpg"} // Use decrypted photo or a default
//           alt={task.task_name}
//           sx={{
//             borderRadius: "12px",
//             objectFit: "cover",
//           }}
//         />
//       </Box>

//       {/* Content Section */}
//       <CardContent
//         sx={{
//           padding: "12px 8px",
//           minHeight: "80px", // Ensures consistent height for card content
//           display: "flex",
//           flexDirection: "column",
//           justifyContent: "space-between", // Ensures even spacing
//         }}
//       >
//         <Typography
//           variant="body1"
//           component="div"
//           sx={{
//             fontWeight: "bold",
//             fontSize: "16px",
//             color: "#333",
//             textAlign: "center",
//             wordBreak: "break-word", // Ensures long words wrap
//             overflow: "hidden",
//           }}
//         >
//           {task.task_name}
//         </Typography>
//         <Typography
//           variant="body2"
//           color="textSecondary"
//           sx={{
//             fontSize: "14px",
//             textAlign: "center",
//             marginTop: "4px",
//           }}
//         >
//           Task: {task.task_number}
//         </Typography>
//       </CardContent>
//     </Card>
//   );
// };

// export default TaskCard;


import React from "react";
import { Card, CardMedia, CardContent, Typography, Box } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { fetchTaskDetails } from "../../features/products/taskdetailsSlice"; // Redux action
import decryptURI from "../modifier/decryptURI"; 

const TaskCard = ({ task }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  // console.log("loc",location)
  // Get the token from localStorage
  const token = localStorage.getItem("token");
  // console.log("token",token)

  // Decrypt the photo URL safely
  let decryptedPhoto;
  try {
    decryptedPhoto = decryptURI(task.photo); // Decrypt the photo URL
  } catch (error) {
    console.error("Failed to decrypt photo URL:", error);
    decryptedPhoto = ""; // Fallback to an empty string or default image
  }
  
  // Handle card click
  // const handleCardClick = () => {
    
  
  //   if (!token) {
  //     // If the user is not logged in, redirect to login and save the current location
  //     console.log("data",task)
  //     navigate("/login", {
  //       state: { from: location.pathname ,levelCode:location.state.levelCode}, // Save the current route to be used after login
  //     });
  //     return; // Exit the function to prevent further execution
  //   }
  
  //   // If logged in, fetch task details and navigate to the task page
  //   dispatch(fetchTaskDetails(task.task_code, token)); // Dispatch action to fetch task details
  //   navigate(`/task-details/${task.task_code}`); // Navigate to the VideoTabs page
  // };

  const handleCardClick = () => {
    if (!token) {
      // Extract levelCode by removing the last "_Txx" part of the taskCode
      const levelCode = task.task_code.replace(/_T\d+$/, "");
  
      // Redirect to login and save the current location with levelCode
      navigate("/login", {
        state: {
          from: location.pathname, // Current path to redirect back to
          levelCode, // Pass the derived levelCode
        },
      });
      return; // Exit to prevent further execution
    }
  
    // If logged in, fetch task details and navigate to the task page
    dispatch(fetchTaskDetails(task.task_code, token)); // Fetch task details
    navigate(`/task-details/${task.task_code}`); // Navigate to task details page
  };
  

  return (
    <Card
      sx={{
        width: "100%",
        maxWidth: 280,
        margin: "auto",
        borderRadius: "16px",
        boxShadow: 3,
        position: "relative",
        padding: "8px",
        background: "#ffffff",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        cursor: "pointer", // Make it clickable
      }}
      onClick={handleCardClick} // Click handler
    >
      {/* Small Overlay Card for Rating */}
      <Box
        sx={{
          position: "absolute",
          top: "12px",
          right: "12px",
          backgroundColor: "#fff",
          padding: "4px 8px",
          borderRadius: "12px",
          boxShadow: "0px 2px 6px rgba(0,0,0,0.2)",
          display: "flex",
          alignItems: "center",
          zIndex: 1,
        }}
      >
        <Typography variant="body2" sx={{ fontWeight: "bold", fontSize: "14px" }}>
          {task.rating}
        </Typography>
        <StarIcon sx={{ color: "green", fontSize: "16px", marginLeft: "4px" }} />
      </Box>

      {/* Image Section */}
      <Box
        sx={{
          borderRadius: "12px",
          overflow: "hidden",
        }}
      >
        <CardMedia
          component="img"
          height="150"
          image={decryptedPhoto || "default-image.jpg"} // Use decrypted photo or a default
          alt={task.task_name}
          sx={{
            borderRadius: "12px",
            objectFit: "cover",
          }}
        />
      </Box>

      {/* Content Section */}
      <CardContent
        sx={{
          padding: "12px 8px",
          minHeight: "80px", // Ensures consistent height for card content
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between", // Ensures even spacing
        }}
      >
        <Typography
          variant="body1"
          component="div"
          sx={{
            fontWeight: "bold",
            fontSize: "16px",
            color: "#333",
            textAlign: "center",
            wordBreak: "break-word", // Ensures long words wrap
            overflow: "hidden",
          }}
        >
          {task.task_name}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{
            fontSize: "14px",
            textAlign: "center",
            marginTop: "4px",
          }}
        >
          Task: {task.task_number}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default TaskCard;
