// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import BorderColorIcon from "@mui/icons-material/BorderColor";
// import "./ProfilePage.css";
// import { getEnvironmentDetails } from "../../environment";

// const ProfilePage = () => {
//   const navigate = useNavigate();
//   const { BASE_URL } = getEnvironmentDetails(); // Get the backend URL

//   const [profile, setProfile] = useState(null);
//   const [isEditing, setIsEditing] = useState(false);
//   const [updatedProfile, setUpdatedProfile] = useState({});
//   const [selectedImage, setSelectedImage] = useState(null);
//   const token = localStorage.getItem("token");

//   useEffect(() => {
//     const fetchProfile = async () => {
//       try {
//         const response = await fetch(`${BASE_URL}create/student`, {
//           method: "GET",
//           headers: {
//             Authorization: token,
//           },
//         });

//         const data = await response.json();
//         if (!response.ok) throw new Error(data.message || "Failed to fetch profile");

//         setProfile(data);
//         setUpdatedProfile({ ...data.stu, ...data.add[0] });
//       } catch (error) {
//         console.error(error.message || "An error occurred.");
//       }
//     };

//     fetchProfile();
//   }, [token]);

//   const handleLogout = () => {
//     localStorage.removeItem("token");
//     navigate("/");
//   };

//   const handleEditToggle = () => {
//     setIsEditing((prev) => !prev);
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setUpdatedProfile((prev) => ({ ...prev, [name]: value }));
//   };

//   const handleImageUpload = async (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       setSelectedImage(file);

//       const formData = new FormData();
//       formData.append("photo", file);

//       try {
//         const response = await fetch(`${BASE_URL}create/student`, {
//           method: "PUT",
//           headers: {
//             Authorization: token,
//           },
//           body: formData,
//         });

//         const data = await response.json();
//         if (!response.ok) throw new Error(data.message || "Failed to update profile image");

//         setProfile((prev) => ({
//           ...prev,
//           stu: { ...prev.stu, photo: data.photo }, // Update profile image URL
//         }));
//         alert("Profile image updated successfully!");
//       } catch (error) {
//         console.error(error.message || "Failed to upload image.");
//         alert("Failed to update profile image. Please try again.");
//       }
//     }
//   };

//   if (!profile) return <div>Loading...</div>;

//   return (
//     <div className="profile-page">
//       <div className="profile-header">
//         {/* Profile Image Section */}
//         <div className="profile-image-wrapper">
//           <div className="profile-image">
//             <img
//               src={
//                 selectedImage
//                   ? URL.createObjectURL(selectedImage)
//                   : `https://almondai.in/ilpapi${profile.stu.photo}`
//               }
//               alt="Profile"
//               className="circular-image"
//             />
//             <label htmlFor="upload-image" className="edit-icon">
//               <BorderColorIcon />
//             </label>
//             <input
//               id="upload-image"
//               type="file"
//               accept="image/*"
//               style={{ display: "none" }}
//               onChange={handleImageUpload}
//             />
//           </div>
//         </div>

//         {/* Profile Edit Button */}
//         <div className="edit-profile">
//           <button className="cancel-button" onClick={handleEditToggle}>
//             {isEditing ? "Save Changes" : "Edit Profile"}
//           </button>
//           {isEditing && (
//             <button
//               className="cancel-button"
//               onClick={() => {
//                 setIsEditing(false);
//                 setUpdatedProfile({ ...profile.stu, ...profile.add[0] }); // Reset changes
//               }}
//             >
//               Cancel
//             </button>
//           )}
//         </div>
//       </div>

//       <h1>Profile</h1>

//       {/* Profile Details Section */}
//       <div className="profile-details-grid">
//         <div className="profile-field">
//           <label>Name:</label>
//           <input
//             type="text"
//             name="name"
//             value={updatedProfile.name || ""}
//             onChange={handleChange}
//             readOnly={!isEditing}
//             className={`editable-field ${isEditing ? "editable" : ""}`}
//           />
//         </div>
//         <div className="profile-field">
//           <label>Email:</label>
//           <input
//             type="email"
//             name="email"
//             value={updatedProfile.email || ""}
//             onChange={handleChange}
//             readOnly={!isEditing}
//             className={`editable-field ${isEditing ? "editable" : ""}`}
//           />
//         </div>
//         <div className="profile-field">
//   <label>Contact:</label>
//   <input
//     type="text"
//     name="contact"
//     value={updatedProfile.contact || ""}
//     readOnly
//     className="readonly-field"
//   />
// </div>
//         <div className="profile-field">
//   <label>Gender:</label>
//   {isEditing ? (
//     <select
//       name="gender"
//       value={updatedProfile.gender || ""}
//       onChange={handleChange}
//       className="editable-field"
//     >
//       <option value="">Select Gender</option>
//       <option value="Male">Male</option>
//       <option value="Female">Female</option>
//     </select>
//   ) : (
//     <span className="readonly-field">{updatedProfile.gender || "N/A"}</span>
//   )}
// </div>
//       </div>

//       <h3>Address</h3>
//       <div className="profile-details-grid">
//         <div className="profile-field">
//           <label>Address:</label>
//           <input
//             type="text"
//             name="address"
//             value={updatedProfile.address || ""}
//             onChange={handleChange}
//             readOnly={!isEditing}
//             className={`editable-field ${isEditing ? "editable" : ""}`}
//           />
//         </div>
//         <div className="profile-field">
//           <label>District:</label>
//           <input
//             type="text"
//             name="district"
//             value={updatedProfile.district || ""}
//             onChange={handleChange}
//             readOnly={!isEditing}
//             className={`editable-field ${isEditing ? "editable" : ""}`}
//           />
//         </div>
//         <div className="profile-field">
//           <label>State:</label>
//           <input
//             type="text"
//             name="state"
//             value={updatedProfile.state || ""}
//             onChange={handleChange}
//             readOnly={!isEditing}
//             className={`editable-field ${isEditing ? "editable" : ""}`}
//           />
//         </div>
//         <div className="profile-field">
//           <label>Pincode:</label>
//           <input
//             type="text"
//             name="pincode"
//             value={updatedProfile.pincode || ""}
//             onChange={handleChange}
//             readOnly={!isEditing}
//             className={`editable-field ${isEditing ? "editable" : ""}`}
//           />
//         </div>
//       </div>

//       <button className="logout-button" onClick={handleLogout}>
//         Logout
//       </button>
//     </div>
//   );
// };

// export default ProfilePage;





// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import "./ProfilePage.css";
// import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
// import { getEnvironmentDetails } from "../../environment";

// const ProfilePage = () => {
//   const navigate = useNavigate();
//   const [profile, setProfile] = useState(null);
//   const token = localStorage.getItem("token");
//   const { BASE_URL } = getEnvironmentDetails(); // Get the backend URL

//   useEffect(() => {
//     const fetchProfile = async () => {
//       try {
//         const response = await fetch(`${BASE_URL}create/student`, {
//           method: "GET",
//           headers: {
//             Authorization: token,
//           },
//         });

//         const data = await response.json();
//         if (!response.ok) throw new Error(data.message || "Failed to fetch profile");

//         setProfile(data);
//       } catch (error) {
//         console.error(error.message || "An error occurred.");
//       }
//     };

//     fetchProfile();
//   }, [token]);

//   const handleLogout = () => {
//     localStorage.removeItem("token");
//     navigate("/");
//   };

//   if (!profile) return <div>Loading...</div>;

//   return (
//     <div className="profile-page">
//       {/* Full-Width Banner */}
//       <div className="profile-banner">
//         <div className="banner-icon" onClick={() => navigate("/profile")}>
//           <AccountCircleIcon style={{ fontSize: "40px" }} />
//           <span>Profile</span>
//         </div>
//         <div className="banner-icon" onClick={() => navigate("/myorders")}>
//           <ShoppingCartIcon style={{ fontSize: "40px" }} />
//           <span>Order Summary</span>
//         </div>
//       </div>

//       {/* Spacing between Banner and Box */}
//       <div className="spacer"></div>

//       {/* Main Content in Box */}
//       <div className="profile-box">
//         <h1>Profile Details</h1>
//         <div className="profile-fields">
//           <div className="field">
//             <label>Name:</label>
//             <span>{profile.stu.name || "N/A"}</span>
//           </div>
//           <div className="field">
//             <label>Email:</label>
//             <span>{profile.stu.email || "N/A"}</span>
//           </div>
//           <div className="field">
//             <label>Contact:</label>
//             <span>{profile.stu.contact || "N/A"}</span>
//           </div>
//           <div className="field">
//             <label>Gender:</label>
//             <span>{profile.stu.gender || "N/A"}</span>
//           </div>
//         </div>

//         <h2>Address Details</h2>
//         <div className="address-fields">
//           <div className="field">
//             <label>Address:</label>
//             <span>{profile.add[0]?.address || "N/A"}</span>
//           </div>
//           <div className="field">
//             <label>District:</label>
//             <span>{profile.add[0]?.district || "N/A"}</span>
//           </div>
//           <div className="field">
//             <label>State:</label>
//             <span>{profile.add[0]?.state || "N/A"}</span>
//           </div>
//           <div className="field">
//             <label>Pincode:</label>
//             <span>{profile.add[0]?.pincode || "N/A"}</span>
//           </div>
//         </div>

//         <button className="logout-button" onClick={handleLogout}>
//           Logout
//         </button>
//       </div>
//     </div>
//   );
// };

// export default ProfilePage;



import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./ProfilePage.css";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import { getEnvironmentDetails } from "../../environment";

const ProfilePage = () => {
  const navigate = useNavigate();
  const [profile, setProfile] = useState(null);
  const [isEditing, setIsEditing] = useState(false); // Edit mode state
  const [editableProfile, setEditableProfile] = useState(null); // Store editable fields
  const token = localStorage.getItem("token");
  const { BASE_URL } = getEnvironmentDetails(); // Backend URL

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await fetch(`${BASE_URL}create/student`, {
          method: "GET",
          headers: {
            Authorization: token,
          },
        });

        const data = await response.json();
        if (!response.ok) throw new Error(data.message || "Failed to fetch profile");

        setProfile(data);
        setEditableProfile({ ...data }); // Initialize editable state
      } catch (error) {
        console.error(error.message || "An error occurred.");
      }
    };

    fetchProfile();
  }, [token]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleCancel = () => {
    setEditableProfile({ ...profile }); // Revert changes
    setIsEditing(false);
  };

  const handleInputChange = (e, key, section) => {
    setEditableProfile((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [key]: e.target.value,
      },
    }));
  };
  
  const saveProfile = async () => {
    try {
      const response = await fetch(`${BASE_URL}create/student`, {
        method: "PUT",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(editableProfile),
        
      });
      console.log(JSON.stringify(editableProfile))
      const data = await response.json();
      if (!response.ok) throw new Error(data.message || "Failed to update profile");

      setProfile(editableProfile); // Save changes locally
      setIsEditing(false); // Exit edit mode
    } catch (error) {
      console.error(error.message || "An error occurred.");
    }
  };

  if (!profile) return <div>Loading...</div>;

  return (
    <div className="profile-page">
      {/* Full-Width Banner */}
      <div className="profile-banner">
        <div className="banner-icon" onClick={() => navigate("/profile")}>
          <AccountCircleIcon style={{ fontSize: "40px" }} />
          <span>Profile</span>
        </div>
        <div className="banner-icon" onClick={() => navigate("/myorders")}>
          <ShoppingCartIcon style={{ fontSize: "40px" }} />
          <span>Order Summary</span>
        </div>
      </div>

      {/* Spacing between Banner and Box */}
      <div className="spacer"></div>

      {/* Main Content in Box */}
      <div className="profile-box">
        <div className="profile-header">
          <h1>Profile Details</h1>
          {!isEditing ? (
            <EditIcon className="edit-icon" onClick={toggleEdit} />
          ) : (
            <div className="edit-actions">
              <button className="save-button" onClick={saveProfile}>Save</button>
              <button className="cancel-button" onClick={handleCancel}>Cancel</button>
            </div>
          )}
        </div>

        <div className="profile-fields">
          <div className="field">
            <label>Name:</label>
            {isEditing ? (
              <input
                type="text"
                value={editableProfile.stu.name || ""}
                onChange={(e) => handleInputChange(e, "name", "stu")}
              />
            ) : (
              <span>{profile.stu.name || "N/A"}</span>
            )}
          </div>
          <div className="field">
            <label>Email:</label> 
            {isEditing ? (
              <input
                type="email"
                value={editableProfile.stu.email || ""}
                onChange={(e) => handleInputChange(e, "email", "stu")}
              />
            ) : (
              <span>{profile.stu.email || "N/A"}</span>
            )}
          </div>
          <div className="field">
            <label>Contact:</label>
            {/* Contact is fixed and cannot be edited */}
            <span>{profile.stu.contact || "N/A"}</span>
          </div>
          <div className="field">
            <label>Gender:</label>
            {isEditing ? (
              <input
                type="text"
                value={editableProfile.stu.gender || ""}
                onChange={(e) => handleInputChange(e, "gender", "stu")}
              />
            ) : (
              <span>{profile.stu.gender || "N/A"}</span>
            )}
          </div>
        </div>

        <h2>Address Details</h2>
        <div className="address-fields">
          <div className="field">
            <label>Address:</label>
            {isEditing ? (
              <input
                type="text"
                value={editableProfile.add[0]?.address || ""}
                onChange={(e) => handleInputChange(e, "address", "add[0]")}
              />
            ) : (
              <span>{profile.add[0]?.address || "N/A"}</span>
            )}
          </div>
          <div className="field">
            <label>District:</label>
            {isEditing ? (
              <input
                type="text"
                value={editableProfile.add[0]?.district || ""}
                onChange={(e) => handleInputChange(e, "district", "add[0]")}
              />
            ) : (
              <span>{profile.add[0]?.district || "N/A"}</span>
            )}
          </div>
          <div className="field">
            <label>State:</label>
            {isEditing ? (
              <input
                type="text"
                value={editableProfile.add[0]?.state || ""}
                onChange={(e) => handleInputChange(e, "state", "add[0]")}
              />
            ) : (
              <span>{profile.add[0]?.state || "N/A"}</span>
            )}
          </div>
          <div className="field">
            <label>Pincode:</label>
            {isEditing ? (
              <input
                type="text"
                value={editableProfile.add[0]?.pincode || ""}
                onChange={(e) => handleInputChange(e, "pincode", "add[0]")}
              />
            ) : (
              <span>{profile.add[0]?.pincode || "N/A"}</span>
            )}
          </div>
        </div>

        <button className="logout-button" onClick={handleLogout}>
          Logout
        </button>
      </div>
    </div>
  );
};

export default ProfilePage;
