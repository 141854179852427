// import React, { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import { fetchGames } from "../../features/games/gameSlice";
// import decryptURI from "../../components/modifier/decryptURI";
// import "./GamesPage.css";

// const GamesPage = () => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const { games, status, error } = useSelector((state) => state.games);

//   const allowedGames = ["Takshak", "Totoka"]; // Allowed game types

//   useEffect(() => {
//     if (status === "idle") {
//       dispatch(fetchGames());
//     }
//   }, [status, dispatch]);

//   const handleCardClick = (game) => {
//     if (!allowedGames.includes(game.game_name)) {
//       alert("Coming Soon");
//     } else {
//       navigate(`/levels/${game.game_code}`);
//     }
//   };

//   return (
//     <div className="games-page">
//       <h1>Explore Our Games</h1>
//       <p>
//         Explore our exciting categories of games: educational STEM challenges to
//         enhance learning and fun robotics simulations to spark creativity.
//       </p>

//       {status === "loading" && <p>Loading games...</p>}
//       {status === "failed" && <p>Error: {error}</p>}
//       {status === "succeeded" && (
//         <div className="games-grid">
//           {games.map((game) => {
//             let imageUrl = game.image_url;
//             try {
//               imageUrl = decryptURI(game.image_url);
//             } catch (err) {
//               console.error("Failed to decrypt image URL:", err);
//             }

//             const isLocked = !allowedGames.includes(game.game_name);

//             return (
//               <div
//                 key={game.id}
//                 className="game-card"
//                 onClick={() => !isLocked && handleCardClick(game)}
//               >
//                 <div
//                   className={`game-card-overlay ${isLocked ? "locked" : ""}`}
//                 >
//                   <img
//                     src={imageUrl}
//                     alt={game.game_type}
//                     className="game-card-image"
//                   />
//                   {isLocked ? (
//                     <div className="icon-overlay lock-icon">🔒</div>
//                   ) : (
//                     <div className="icon-overlay play-icon">▶️</div>
//                   )}
//                 </div>
//                 <p className="game-title">{game.game_type}</p>
//               </div>
//             );
//           })}
//         </div>
//       )}
//     </div>
//   );
// };

// export default GamesPage;





// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import { fetchGames } from "../../features/games/gameSlice";
// import decryptURI from "../../components/modifier/decryptURI";
// import "./GamesPage.css";

// const GamesPage = () => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const { games, status, error } = useSelector((state) => state.games);
//   const [userDetails, setUserDetails] = useState(null);
//   const token = localStorage.getItem("token"); // assuming token is stored in localStorage

//   const allowedGames = ["Takshak", "Totoka"]; // Allowed game types

//   useEffect(() => {
//     if (status === "idle") {
//       dispatch(fetchGames());
//     }

//     // Fetch user details if token is available
//     if (token) {
//       // Here you can replace with an actual API call to fetch user details using the token
//       // For now, it's mocked as an example:
//       setUserDetails({
//         name: "John Doe",
//         email: "john@example.com",
//         contact: "+1234567890",
//         schoolName: "Example High School",
//       });
//     }
//   }, [status, dispatch, token]);

//   const handleCardClick = (game) => {
//     if (!allowedGames.includes(game.game_name)) {
//       alert("Coming Soon");
//     } else {
//       navigate(`/levels/${game.game_code}`);
//     }
//   };

//   return (
//     <div className="games-page">
//       <h1>Explore Our Games</h1>
//       <p>
//         Explore our exciting categories of games: educational STEM challenges to
//         enhance learning and fun robotics simulations to spark creativity.
//       </p>

//       {status === "loading" && <p>Loading games...</p>}
//       {status === "failed" && <p>Error: {error}</p>}
//       {status === "succeeded" && (
//         <div className="games-grid">
//           {games.map((game) => {
//             let imageUrl = game.image_url;
//             try {
//               imageUrl = decryptURI(game.image_url);
//             } catch (err) {
//               console.error("Failed to decrypt image URL:", err);
//             }

//             const isLocked = !allowedGames.includes(game.game_name);

//             return (
//               <div
//                 key={game.id}
//                 className="game-card"
//                 onClick={() => !isLocked && handleCardClick(game)}
//               >
//                 <div
//                   className={`game-card-overlay ${isLocked ? "locked" : ""}`}
//                 >
//                   <img
//                     src={imageUrl}
//                     alt={game.game_type}
//                     className="game-card-image"
//                   />
//                   {isLocked ? (
//                     <div className="icon-overlay lock-icon">🔒</div>
//                   ) : (
//                     <div className="icon-overlay play-icon">▶️</div>
//                   )}
//                 </div>
//                 <p className="game-title">{game.game_type}</p>
//               </div>
//             );
//           })}
//         </div>
//       )}

//       {/* ISPL Section */}
//       <div className="ispl-section">
//         <h1>ISPL</h1>

//         {!token ? (
//           // Show registration card if token is not available
//           <div className="ispl-card">
//             <p>Register to ISPL to join the fun!</p>
//             <button
//               onClick={() => navigate("/ispl")}
//               className="register-button"
//             >
//               Register Now
//             </button>
//           </div>
//         ) : (
//           // Show user details card if token is available
//           <div className="ispl-card">
//             <h3> Your ISPL Details</h3>
//             <p><strong>Name:</strong> chikun</p>
//             <p><strong>Email:</strong> 7077618989</p>
//             <p><strong>Contact:</strong> chikun@mail.com</p>
//             <p><strong>School Name:</strong> jphs</p>
//           </div>
//         )}
//       </div>

//       {/* Video Section */}
//       {token && (
//         <div className="video-section">
//           <h2>Our Videos</h2>
//           <div className="video-gallery">
//             <div className="video-card">
//               <iframe
//                 src="https://www.youtube.com/embed/MXcqxfVoYMY"
//                 title="Video 1"
//                 frameBorder="0"
//                 allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//                 allowFullScreen
//               ></iframe>
//             </div>
//             <div className="video-card">
//               <iframe
//                 src="https://www.youtube.com/embed/MXcqxfVoYMY"
//                 title="Video 2"
//                 frameBorder="0"
//                 allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//                 allowFullScreen
//               ></iframe>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default GamesPage;




import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchGames } from "../../features/games/gameSlice";
import decryptURI from "../../components/modifier/decryptURI";
import "./GamesPage.css";
import { get } from "react-scroll/modules/mixins/scroller";
import {getEnvironmentDetails} from "../../environment";

const GamesPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { games, status, error } = useSelector((state) => state.games);
  const [userDetails, setUserDetails] = useState(null);
  const [videos, setVideos] = useState([]); // To store videos
  const [loading, setLoading] = useState(false); // To show loading spinner
  const token = localStorage.getItem("token"); // Assuming token is stored in localStorage
  const { BASE_URL } = getEnvironmentDetails(); // Destructure BASE_URL
  const allowedGames = ["Takshak", "Totoka"]; // Allowed game types

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchGames());
    }

    // Fetch user details if token is available
    if (token) {
      setLoading(true);
      fetch(`${BASE_URL}ispl/participant-details/`, {
        method: "GET",
        headers: {
          Authorization: token, // Pass token in the Authorization header
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setUserDetails(data.participant); // Set participant data
          setVideos(data.videos); // Set video data
        })
        .catch((error) => {
          console.error("Error fetching participant details:", error);
          setUserDetails(null); // Optionally reset user details on error
        })
        .finally(() => {
          setLoading(false); // Set loading to false once the data is fetched
        });
    }
  }, [status, dispatch, token]);

  const handleCardClick = (game) => {
    if (!allowedGames.includes(game.game_name)) {
      alert("Coming Soon");
    } else {
      navigate(`/levels/${game.game_code}`);
    }
  };

  return (
    <div className="games-page">
      <h1>Explore Our Games</h1>
      <p>
        Explore our exciting categories of games: educational STEM challenges to
        enhance learning and fun robotics simulations to spark creativity.
      </p>

      {status === "loading" && <p>Loading games...</p>}
      {status === "failed" && <p>Error: {error}</p>}
      {status === "succeeded" && (
        <div className="games-grid">
          {games.map((game) => {
            let imageUrl = game.image_url;
            try {
              imageUrl = decryptURI(game.image_url);
            } catch (err) {
              console.error("Failed to decrypt image URL:", err);
            }

            const isLocked = !allowedGames.includes(game.game_name);

            return (
              <div
                key={game.id}
                className="game-card"
                onClick={() => !isLocked && handleCardClick(game)}
              >
                <div
                  className={`game-card-overlay ${isLocked ? "locked" : ""}`}
                >
                  <img
                    src={imageUrl}
                    alt={game.game_type}
                    className="game-card-image"
                  />
                  {isLocked ? (
                    <div className="icon-overlay lock-icon">🔒</div>
                  ) : (
                    <div className="icon-overlay play-icon">▶️</div>
                  )}
                </div>
                <p className="game-title">{game.game_type}</p>
              </div>
            );
          })}
        </div>
      )}

      {/* ISPL Section */}
      <div className="ispl-section">
        <h1>Ilearnplace STEM Premier League - 2025</h1>
        <p>
          Explore our exciting categories of games: educational STEM challenges to
          enhance learning and fun robotics simulations to spark creativity.
        </p>
        <div className="ispl-banner">
          <div className="banner-overlay">
            <button
              onClick={() => navigate("/ispl")}
              className="know-more-button"
            >
              Know More
            </button>
          </div>
        </div>
      </div>

      
      
    </div>
  );
};

export default GamesPage;
